import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from "@material-ui/lab/Alert";
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';


import axiosInstance from "../../Api/Api.js";
import { TrendingUpOutlined } from '@material-ui/icons';




function Alert(props) {
    return <MuiAlert elevation={6} vertical="top" color="primary" variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
      backgroundColor: "white",
      padding: '40px',
      '& > *': {
       margin: theme.spacing(1),
      minWidth: '450px',
      fontSize: '20px'
    },
  },
  standardRate: {
    // ...theme.typography.button,
    backgroundColor: theme.palette.background.paper,
    marginBottom: '20px',
    marginRight: '20px'
   
  },
  centerForm: {
      display: "flex",
      alignItems: 'center',
      justifyContent: 'center',
      width: "100%",
      height: "100%"
  },
  exchangeTitle: {
    fontWeight: 'bold',
    fontSize: '24px',
    display: 'flex', 
    justifyContent: 'center',
    marginBottom: '20px',

  },
  setPrice: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '10px'
  },
  systemPrice: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'

  },
  submitButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: '20px'
  },
  inputText: {
    color: 'black',
    fontSize: '24px'
  }

}));

export default function EditCreate(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
      editIcon: true,
      rate: '',
      standardRate: ''
  })
    
  const [submitLoading, setSubmitLoading] = useState(false);

  const [message, setMessage] = useState("");
  const [rateMessage, setRateMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [disableInput, setDisableInput] = useState(true);



  const handleChange = (prop) => (event) => {
    event.preventDefault();
    setValues({ ...values, [prop]: event.target.value });
  };

  
  

  
  const fetchCurrentRate = () => {
    setLoading(true);
    axiosInstance
      .get(`/exchange_rate/get`)
      .then(function (response) {
        setLoading(false);
        if (response.status === 200) {
          console.log(response.data.data)
           
            
          setValues({
              ...values,
           rate: response.data.data.rate,
           standardRate: response.data.data.standard_rate,
           editIcon: true

          });
        
        } else {
          console.log(response);
        }
      })
      .catch(function (error) {
          console.log(error.response)
          if (error.response.status === 404){
            setRateMessage(error.response.data.message);
          }
        
      })
      .finally(() => setLoading(false));
  };
  const handleClickEditIcon = () => {
    setValues({ ...values, editIcon: !values.editIcon });
    setDisableInput(!disableInput);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitLoading(true);
    const { rate } = values;
    axiosInstance.post('/exchange_rate/add', {rate})
      .then(function (response) {
          console.log(response.status)
        if (response.status === 200) {
          console.log(response);
          setValues({...values, rate: response.data.rate, standardRate: response.data.standard_rate})
        setMessage(response.data.message);
        setOpen(true);
         setDisableInput(true);
         setValues({ ...values, editIcon: true });
        fetchCurrentRate();
        } else {
          console.log(response.data);
        }
      })
      .catch(function (error) {
        console.log(error);
        const { message } = error.response.data;
        setMessage(message);
        setOpen(true);
      })
      .finally(() => setSubmitLoading(false));
  };

  useEffect(() => { 
   fetchCurrentRate();
  }, []);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
 
  

  return (
      <div className={classes.centerForm}>
          { values.loading ? <CircularProgress /> :
    <form className={classes.root} noValidate autoComplete="off">
       <div className={classes.exchangeTitle}>{ "Dollar Exchange Rate"}</div>
       <div className={classes.standardRate}>Standard Rate:  ₦ {values.standardRate? values.standardRate: <CircularProgress />} </div>
       <Divider light />
       <div className={classes.systemPrice}><div className={classes.setPrice}>System Rate: </div> 
      <TextField  id="new-rate" type="text" size="medium" className={classes.inputText} disabled={values.editIcon} type="number" InputLabelProps={{
            shrink: true,
          }} label="Set New Dollar Rate"
          InputProps={{
            startAdornment: <InputAdornment position="start"> ₦</InputAdornment>,
            endAdornment:<InputAdornment position="start">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickEditIcon}
                  onMouseDown={handleMouseDownPassword}
                >
                  {(values.editIcon) ? <EditIcon /> : <CloseIcon />}
                </IconButton>
                
                </InputAdornment>
          }}
          value={values.rate}
          onChange={handleChange("rate")}
          helperText="Click the edit icon to set new Exchange Rate"
          />
         </div>

      
    
                  
                 <div className={classes.submitButton}>
              {!disableInput && (<Button 
              variant="contained" 
              onClick={handleSubmit} 
              color="primary"  
              disabled={!(values.rate && !values.editIcon)}
              >
                    
                  {submitLoading ? <CircularProgress color="secondary" /> : "Submit"}
</Button>)}  
    </div>
<Snackbar open={open} autoHideDuration={6000} color="primary" onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          {message}
        </Alert>
      </Snackbar>
    </form>}
    </div>
  );
}
