/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react'
import { DropzoneArea } from 'material-ui-dropzone';
import { Avatar, Box, Button, CircularProgress, Grid, IconButton, LinearProgress, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, makeStyles, MenuItem, Snackbar, TextField, Typography } from '@material-ui/core';
import { CancelOutlined } from '@material-ui/icons';
import Swal from 'sweetalert2';
import { AppConfig } from "../app-config";
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
    select: {
        width: "100%",
        marginBottom: "1.4rem"
    },
    button: {
        marginTop: "1rem"
    },
    inline: {
        marginLeft: "1rem"
    },
    large: {
        width: theme.spacing(12),
        height: theme.spacing(12),
        marginRight: "1rem"
    },
    spinner: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "1rem",
        height: "100%"
    },
    fileTitle: {
        color: "blue",
    },
    download: {
        marginBottom: '20px',
        color: 'blue'
    },
    container: {
        margin: '1rem',
        dispaly: 'flex',
        // display: "flex",
        justifyContent: 'center',
        alignItems: 'center'
    },
    list: {
        maxHeight: '270px',
        overflowY: 'auto'
    },
    caption: {
        color: "red",
        marginBottom: 16,
        // margin: '0 auto',

    }
}))
export default function UploadDocuments({ user, reqBody, process, serviceId, currentStep, setPaymentData, applicationId }) {
    const [files, setFiles] = useState([]);
    const [singleFile, setSingleFile] = useState({})
    const [uploadedFiles, setUploadedFiles] = useState([])
    const [progress, setProgress] = useState(0);
    const [docId, setDocId] = useState('');
    const [docTypes, setDocTypes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [alert, showAlert] = useState(false);
    const [uploading, setUploading] = useState(false)
    const [url, setUrl] = useState('');
    const classes = useStyles();
    const [duplicateAlert, showDuplicateAlert] = useState(false)

    const declarationProcess = ['Free Zone', 'Industrial Park']
    const OPLProcess = ['Zone OPL', 'Enterprise Registration & CTC of Documents', 'Zone OPL Renewal Fees', 'Registry Search', 'Change of Ownership', 'Change of Name of Enterprise']
    const instrumentProcess = ['Consent', 'Debenture', 'Negative Pledge', 'Registration of Lease Agreements'];
    const assetValuation = ['Long Term Fixed Asset'];
    const shareCapital = ['For 1st $1M', 'For every additional $1M', 'Increase in Share Capital'];
    const trueCopies = ['Memorandum and Articles of Association', 'Certificate', 'OPL', 'Change of Ownership', 'Change of Name of Enterprise', 'Others']
    const ReplacementofLostOpl = ['Replacement of Lost OPL']


    const setDocumentId = (id) => {
        // const found = files.find((item) => item?.id == id);
        // console.log(found)
        // if (found) {
        //     return console.log("foundddddddddddddddddddddddd")
        // }
        setDocId(id)
    }
    const getDocTypes = async () => {
        setLoading(true)
        try {
            const response = await fetch(`${AppConfig.URL}/services/get/${serviceId}`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${user.access_token}`
                }
            });
            const { status, data, message } = await response.json();
            if (status === true) {
                setDocTypes(data);
            } else {
                Swal.fire('', message, 'info');
            }
            setLoading(false)
        } catch (error) {
            Swal.fire('Something went wrong', 'Unable to load record', 'error');
            setLoading(false)
        }
    };

    const checkNepzaApplication = () => {
        console.log(docTypes)
        return docTypes.find((item) => item.name == "Application")
    }


    const getDocTitle = (id) => {
        console.log(id, docTypes)
        const doc = docTypes?.find((item) => id == item.id)
        return doc.name;
    }

    const handleRemove = (id) => {
        const filteredFiles = files.filter(file => file.id !== id);
        setFiles(filteredFiles);
    }

    useEffect(() => {
        console.log(singleFile)
        handleSingleSubmit()
    }, [singleFile])


    const handleSingleSubmit = async () => {
        if (singleFile.upload === undefined) {
            return
        }

        const formData = new FormData();
        formData.append(`application_id`, applicationId);
        formData.append(`id`, singleFile.id);
        formData.append(`file`, singleFile.upload);

        try {
            setUploading(true);

            let reqMethod = "POST";
            let url;
            if (process.name === 'Free Zone' || process.name === 'Industrial Park') {
                url = `${AppConfig.URL}/application_zones/upload-document`
            };
            if (process.name === 'Zone OPL' || process.name === 'Enterprise Registration & CTC of Documents' || process.name === 'Zone OPL Renewal Fees') {
                url = `${AppConfig.URL}/application_opl/upload-document`
            };
            if (process.name === 'Registry Search') {
                url = `${AppConfig.URL}/application_opl/registry_search`
            };
            if (process.name === 'Change of Ownership') {
                url = `${AppConfig.URL}/application_opl/change_of_ownership`
            };
            if (process.name === 'Change of Name of Enterprise') {
                url = `${AppConfig.URL}/application_opl/change_of_name_enterprise`
            };
            if (instrumentProcess.includes(process.name)) {
                url = `${AppConfig.URL}/application_instrument/upload-document                `
            };
            if (assetValuation.includes(process.name) || process.name == 'Revaluation - Long Term Fixed Asset') {
                url = `${AppConfig.URL}/application_asset/add`
            };
            if (shareCapital.includes(process.name)) {
                url = `${AppConfig.URL}/application_increase_capital/upload-document`

            };
            if (trueCopies.includes(process.name)) {
                url = `${AppConfig.URL}/application_ctc/upload-document`
            };
            if (process.name === 'Alteration of Memorandum and Articles of Association') {
                url = `${AppConfig.URL}/application_memorandum/upload-document`
            };
            if (process.name === 'Change of Directors') {
                url = `${AppConfig.URL}/application_directors/upload-document`

            };
            if (process.name === 'Change of Objects') {
                url = `${AppConfig.URL}/application_objects/upload-document`
            };
            if (process.name === 'Filing of Annual Returns') {
                url = `${AppConfig.URL}/application_annual_returns/add`
            };
            if (process.name === 'Replacement of Lost OPL') {
                url = `${AppConfig.URL}/application_lost_opl/upload-document`
            };
            var userToken = localStorage.getItem('userDetails') ? JSON.parse(localStorage.getItem('userDetails')).access_token : null
            const { status, data, message } = await fetch(url, {
                method: reqMethod,
                headers: {
                    Authorization: `Bearer ${userToken}`,
                    Accept: "application/json*",
                },
                body: formData
            }).then((res) => res.json());
            if (status) {
                console.log(data)
                setUploadedFiles(uploadedFiles => {
                    const copyFiles = [...uploadedFiles]
                    let i = copyFiles.find(item => item.id === data.id)
                    if (i !== undefined) {
                        copyFiles.splice(i, 1)
                    }
                    setUploading(false)
                    return [...copyFiles, data]
                    // const newFiles = [...uploadedFiles, data]
                    // return newFiles
                })
                console.log(uploadedFiles)
                // window.location.reload()
            } else {
                // Swal.fire("Something went wrong", message, "info");
                // setLoading(false);
                setUploading(false)
                console.log(message)
            }
        } catch (err) {
            console.log(err)
            // Swal.fire("Something went wrong", "Unable to process request", "info");
            setUploading(false);
        }
    };



    const handleSubmit = async () => {
        console.log(files);
        let req = { ...reqBody, uploads: [...files] }
        console.log(process);

        const mutatedFiles = [...files];

        const formData = new FormData();
        if (declarationProcess.includes(process.name)) {
            formData.append('proposed_name', reqBody.proposed_name);
            formData.append('short_name', reqBody.short_name);
            formData.append('address', reqBody.address);
            formData.append('zone_type_id', reqBody.zone_type_id);
            formData.append('state', reqBody.state);
            formData.append('lga', reqBody.lga);
            formData.append('specialty', reqBody.specialty);
            formData.append('company_id', reqBody.company_id);
            formData.append('application_id', reqBody.application_id);
        } else if (process.name === 'Zone OPL' || process.name === 'Enterprise Registration & CTC of Documents') {
            formData.append('application_id', applicationId);
            formData.append('isRenewal', 0);
            formData.append('zone_id', reqBody.zone);
            formData.append('zmc_profile_id', reqBody.zmc);
        } else if (process.name === 'Zone OPL Renewal Fees') {
            formData.append('application_id', applicationId);
            formData.append('isRenewal', 1);
            formData.append('zone_id', reqBody.zone);
            formData.append('zmc_profile_id', reqBody.zmc);
        }
        //  else if (process.name === 'Enterprise Registration & CTC of Documents') {
        //     formData.append('application_id', applicationId);
        //     formData.append('isRenewal', 0);
        // }
        else {
            formData.append('application_id', applicationId);
        }

        // for (let i = 0; i < files.length; i++) {
        //     formData.append(`uploads[${i}][file]`, files[i].upload);
        //     formData.append(`uploads[${i}][id]`, files[i].id);
        //     mutatedFiles[i].file = formData;
        // delete mutatedFiles[i].upload;
        // }
        console.log(formData);
        try {
            setLoading(true);

            let reqMethod = "POST";
            let url;
            if (process.name === 'Free Zone' || process.name === 'Industrial Park') {
                url = `${AppConfig.URL}/application_zones/add`
            };
            if (process.name === 'Zone OPL' || process.name === 'Enterprise Registration & CTC of Documents' || process.name === 'Zone OPL Renewal Fees') {
                url = `${AppConfig.URL}/application_opl/add`
            };
            if (process.name === 'Registry Search') {
                url = `${AppConfig.URL}/application_opl/registry_search`
            };
            if (process.name === 'Change of Ownership') {
                url = `${AppConfig.URL}/application_opl/change_of_ownership`
            };
            if (process.name === 'Change of Name of Enterprise') {
                url = `${AppConfig.URL}/application_opl/change_of_name_enterprise`
            };
            if (instrumentProcess.includes(process.name)) {
                url = `${AppConfig.URL}/application_instrument/add`
            };
            if (assetValuation.includes(process.name) || process.name == 'Revaluation - Long Term Fixed Asset') {
                url = `${AppConfig.URL}/application_asset/add`
            };
            if (shareCapital.includes(process.name)) {
                url = `${AppConfig.URL}/application_increase_capital/add`
            };
            if (trueCopies.includes(process.name)) {
                url = `${AppConfig.URL}/application_ctc/add`
            };
            if (process.name === 'Alteration of Memorandum and Articles of Association') {
                url = `${AppConfig.URL}/application_memorandum/add`
            };
            if (process.name === 'Change of Directors') {
                url = `${AppConfig.URL}/application_directors/add`
            };
            if (process.name === 'Change of Objects') {
                url = `${AppConfig.URL}/application_objects/add`
            };
            if (process.name === 'Filing of Annual Returns') {
                url = `${AppConfig.URL}/application_annual_returns/add`
            };
            if (process.name === 'Replacement of Lost OPL') {
                url = `${AppConfig.URL}/application_lost_opl/add`
            };


            const { status, data, message } = await fetch(url, {
                method: reqMethod,
                headers: {
                    Authorization: "Bearer " + user.access_token,
                    Accept: "application/json*",
                },
                body: formData
            }).then((res) => res.json());
            if (status) {
                console.log(data)
                setPaymentData(data)
                setLoading(false);

                currentStep((prevActiveStep) => prevActiveStep + 1);

                showAlert(true);

            } else {
                console.log(data)
                setDocTypes(data)
                Swal.fire("Something went wrong", message, "info");
                setLoading(false);
            }
        } catch (err) {
            Swal.fire("Something went wrong", "Unable to process request", "info");
            setLoading(false);
        }
    };

    React.useEffect(() => {
        getDocTypes()
    }, []);
    const closeAlert = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        showAlert(false);
    };

    if (loading) {
        return (
            <div className={classes.spinner}>
                <CircularProgress />
            </div>
        )
    }

    return (
        <div className={classes.container}>
            <Typography variant="caption" className={classes.caption}>
                Kindly upload all listed documents.
            </Typography>
            <Snackbar
                open={alert}
                autoHideDuration={2000}
                onClose={closeAlert}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
                <Alert onClose={closeAlert} severity="success">
                    {docTypes.length > 1 ? 'Documents uploaded successfully!' : 'Document uploaded successfully!'}
                    {/* {'Uploaded successfully!'} */}
                </Alert>
            </Snackbar>
            <Snackbar
                open={duplicateAlert}
                autoHideDuration={2000}
                onClose={() => showDuplicateAlert(false)}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
                <Alert onClose={closeAlert} severity="warning">
                    File already uploaded. To re-upload the same file please delete the previous file and re-upload
                </Alert>
            </Snackbar>
            <Grid container direction="row" justify="center" alignItems="center" spacing={6}>
                <Grid item xs={12} sm={6}>
                    {checkNepzaApplication() ? <Typography className={classes.download} variant="h6">
                        <a href="https://res.cloudinary.com/dcfc113t5/image/upload/v1613048440/ApplicationForm-1_1_v2sslf.pdf" target="_blank" download>
                            Click HERE to download the Nepza application form 1 & 2
                            </a>
                    </Typography> : null}
                    <TextField
                        id="outlined-error-helper-text"
                        select
                        variant="outlined"
                        label="Document Type"
                        name="docId"
                        value={docId}
                        className={classes.select}
                        onChange={(e) => setDocumentId(e.target.value)}
                    >
                        {docTypes.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                                {option.name}
                            </MenuItem>
                        ))}
                    </TextField>


                    {docId && <DropzoneArea
                        onDrop={(file) => {
                            const reader = new FileReader();
                            reader.onprogress = function (e) {
                                setProgress(parseInt(Math.round((e.loaded * 100) / e.total)))
                            };
                            files && reader.readAsBinaryString(file[0]);

                            reader.onloadend = () => {
                                // const found = files.find((item) => item?.id == docId);

                                // if (found) {
                                //     return showDuplicateAlert(true)
                                // }

                                file[0] && setSingleFile({
                                    path: file[0].path,
                                    upload: file[0],
                                    id: docId,
                                    docTitle: getDocTitle(docId)
                                });

                                file[0] && setFiles(files => {

                                    // const temp = Object.freeze({ path: file[0].path, upload: file[0], id: docId, docTitle: getDocTitle(docId) });

                                    // return [...files, { ...temp }]

                                    return [...files, { path: file[0].path, upload: file[0], id: docId, docTitle: getDocTitle(docId) }]
                                }
                                );
                            }
                            // files && console.log(reader.readAsArrayBuffer(file[0]));

                        }}
                        maxFileSize={200000000}
                        filesLimit={50}
                        showPreviewsInDropzone={false}
                        showAlerts={['error', 'info']}
                    />}
                </Grid>

                {docId && <Grid item xs={12} sm={6}>
                    <List className={classes.list}>
                        {/* {files.map(file => (
                            <ListItem key={file.id}>
                                <ListItemAvatar>
                                    <Avatar>
                                        <PictureAsPdfIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText>
                                    {file.path}
                                    <Typography className={classes.fileTitle}>{file.docTitle}</Typography>
                                    <Box display="flex" alignItems="center">
                                        <Box width="100%" mr={1}>
                                            <LinearProgress variant="determinate" value={progress} />
                                        </Box>
                                        <Box minWidth={35}>
                                            <Typography variant="body2" color="textSecondary">{`${progress}%`}</Typography>
                                        </Box>
                                    </Box>
                                </ListItemText>
                                <ListItemSecondaryAction>
                                    <IconButton edge="end" aria-label="delete" onClick={() => handleRemove(file.id)}>
                                        <CancelOutlined />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        ))} */}

                        {/* {uploadedFiles && <Typography variant="h5">Uploaded Documents</Typography>} */}
                        {uploadedFiles && uploadedFiles.map((file, index) => (
                            <ListItem key={file.document_id}>
                                <ListItemAvatar>
                                    <Avatar>
                                        <PictureAsPdfIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText>
                                    <Typography className={classes.fileTitle}>{file.document_title}</Typography>
                                    <Box display="flex" alignItems="center">
                                        <Box width="100%" mr={1}>
                                            <LinearProgress variant="determinate" value={progress} />
                                        </Box>
                                        <Box minWidth={35}>
                                            <Typography variant="body2" color="textSecondary">{`${progress}%`}</Typography>
                                        </Box>
                                    </Box>
                                </ListItemText>
                                <ListItemSecondaryAction>
                                    <IconButton edge="end" aria-label="delete">
                                        <CheckCircleOutlineIcon />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        ))}
                    </List>
                </Grid>}
            </Grid>
            {docId &&
                (loading ? <CircularProgress /> : <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleSubmit}
                    className={classes.button}
                    size="large"
                    disabled={loading || uploading}
                >
                    Proceed
            </Button>)}
        </div>
    )
}