import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { useHistory, Link } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import { HistoryTwoTone } from "@material-ui/icons";
import CircularProgress from '@material-ui/core/CircularProgress';
import axiosInstance from "../../../Api/Api.js";
import { useParams } from "react-router-dom";
import { AppConfig } from "app-config.js";
import Swal from "sweetalert2";


const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableFont: {
    // fontWeight: "bold",
    fontSize: "18px",
  },
  tableHeader: {
    paddingLeft: "0",
    fontSize: "18px",
    width: "100%",
    marginLeft: "10px",
  },
  headerContainer: {
    marginBottom: "10px"
  }
});

export default function BasicTable() {
  const classes = useStyles();
  const history = useHistory();
  const [allRequests, setAllRequests] = useState([]);
  const [loading, setLoading] = useState([]);
  const [alert, showAlert] = React.useState(false);
  const params = useParams();
  const userDetails = localStorage.getItem('userDetails');
  const userToken = JSON.parse(userDetails)
  const showDetails = (rowId) => {
    console.log(rowId);
    history.push(`/admin-account/edit-admin/${rowId}`);
  };

  // const DeleteAdmin = (rowId) => {
  //   setLoading(true)
  //   axiosInstance
  //     .get(`/users/delete_admin/${params.rowId}`, { admin_id: rowId })
  //     .then(function (response) {
  //       if (response.status === 200) {
  //         console.log(response.data.data);
  //         alert()
  //         console.log(response.data.data);
  //       } else {
  //         console.log(response);
  //       }
  //     })
  //     .catch(function (error) {
  //       // console.log(error.response.data.message);
  //     })
  //     .finally(() => setLoading(false));
  // };


  const DeleteAdmin = (rowId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "This action cannot be undone!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete"
    }).then(async (result) => {
      if (result.value) {
        setLoading(true);
        try {
          const { status, message } = await fetch(AppConfig.URL + `/users/delete_admin/${rowId}`, {
            method: "DELETE",
            body: {
              admin_id: rowId
            },
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + userToken.access_token
            }
          }).then((res) => res.json());
          if (status) {
            fetchAllPendingRequests();
            showAlert(true);
          } else {
            Swal.fire({
              icon: "error",
              title: "Something went wrong",
              text: "Could not delete Admin, Please try again!"
            });
          }
          setLoading(false);
        } catch (err) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Something went wrong",
            text: "Unable to delete file"
          });
        }
      }
    });
  };

  const fetchAllPendingRequests = () => {
    setLoading(true)
    axiosInstance
      .get("/users/get_admins")
      .then(function (response) {
        console.log(response)
        if (response.status === 200) {
          setAllRequests(response.data.data);
          console.log(response.data, allRequests);
        } else {
          console.log(response);
        }
      })
      .catch(function (error) {
        // console.log(error.response.data.message);
      })
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    fetchAllPendingRequests();
  }, []);

  const formatDate = (date) => {
    const options = {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    if (date) {
      return new Date(date).toLocaleDateString(undefined, options);
    }
    return null;
  };

  return loading ? (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      style={{ height: "100%" }}

    >
      <CircularProgress color="primary" />
    </Grid>
  ) : (!allRequests.length ?
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      style={{ height: "100%" }}
    >
      <Typography variant="h6">No Record found</Typography>
    </Grid> :
    <div>
      <Grid
        container
        direction="row"
        justify="space-between"
        className={classes.headerContainer}
        alignItems="flex-end"
      >
        <div><Typography className={classes.tableHeader}>Users</Typography></div>
        <Link to="/admin-account/create-user">
          <Button variant="contained" color="primary">
            Add new
          </Button>
        </Link>
      </Grid>
      <TableContainer component={Paper} variant="outlined">
        <Table className={classes.table} aria-label="data-table">
          <TableHead>
            <TableRow className={classes.tableFont}>
              <TableCell className={classes.tableFont}> Id</TableCell>
              <TableCell className={classes.tableFont}>
                Name
              </TableCell>
              <TableCell className={classes.tableFont}>Email</TableCell>
              <TableCell className={classes.tableFont}>Role </TableCell>
              <TableCell className={classes.tableFont}>
                Date Created
              </TableCell>
              <TableCell className={classes.tableFont}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allRequests.map((row) => (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row">
                  {row.id}
                </TableCell>
                <TableCell>{row.fullname}</TableCell>
                <TableCell>{row.email}</TableCell>
                <TableCell>{row.role.role_name}</TableCell>
                <TableCell>{new Date(row.created_at).toLocaleDateString()}</TableCell>
                <TableCell>
                  {" "}
                  <Link href="#" onClick={() => showDetails(row.id)} style={{ color: "#48BB78" }} >
                    Edit
                  </Link>
                  &nbsp;&nbsp;&nbsp;
                  <Link href="#" onClick={() => DeleteAdmin(row.id)} style={{ color: "red" }}>
                    Delete
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
