import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import Swal from "sweetalert2";
import { Dialog } from "@material-ui/core";
import { AppConfig } from "app-config";

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: "relative"
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1
    },
    container: {
        display: "flex",
        height: "100%",
        alignItems: "center",
        justifyContent: "center"
    },
    paper: {
        minHeight: "45vh",
        height: "auto",
        width: "50%",
        minWidth: "300px"
        // verticalAlign: "middle",
    },
    form: {
        // width: "100%",
        display: "flex",
        // height: "50px",
        alignItems: "center",
        justifyContent: "center",
        // padding: "8rem 0",
        flexDirection: "column",
        margin: '1rem'
    },
    text: {
        width: "100%",
        marginBottom: "1.4rem"
    },
    button: {
        marginTop: "1rem"
    },
    inline: {
        marginLeft: "1rem"
    },
    large: {
        width: theme.spacing(12),
        height: theme.spacing(12),
        marginRight: "1rem"
    },
    spinner: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: "1rem",
        height: "100%"
    }
}));

const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function CreateUsers({
    handleClose,
    open,
    getUsers,
    data,
    type,
    userData
}) {
    const [user, setUser] = React.useState({
        email: "", fullname: "", password: ""
    });
    const [error, setError] = React.useState({
        email: "", fullname: ""
    });

    const [loading, setLoading] = React.useState(false);

    const [alert, showAlert] = React.useState(false);

    const classes = useStyles();
    const userDetails = localStorage.getItem('userDetails');
    const userToken = JSON.parse(userDetails)

    const handleChange = (event) => {
        setError({ ...error, [event.target.name]: "" });
        setUser({
            ...user,
            [event.target.name]: event.target.value
        });
    };

    const handleSubmit = async () => {
        const { email, fullname, password } = user;

        if (!email && !userData.email) return setError({ ...error, email: "Please provide an Email" });
        if (!fullname && !userData.fullname) return setError({ ...error, fullname: "Please provide a Fullname" });

        const body = {
            email: email || (userData && userData.email), fullname: fullname || (userData && userData.fullname),
            user_id: userData.id,
            password
        };

        if (!user.email) {
            delete body.email;
        }

        if (!user.fullname) {
            delete user.fullname;
        }

        try {
            setLoading(true);

            let reqMethod = "POST";
            let url = AppConfig.URL + "/company/create_user";
            if (type === 'edit') {
                url = AppConfig.URL + "/company/update_user";
                reqMethod = "PUT";
            }

            const { status } = await fetch(url, {
                method: reqMethod,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken.access_token
                },
                body: JSON.stringify(body)
            }).then((res) => res.json());
            if (status) {
                setLoading(false);
                setUser({
                    email: "", fullname: ""
                });
                showAlert(true);
                setTimeout(() => {
                    handleClose();
                    getUsers();
                }, 1000);
            } else {
                Swal.fire("Something went wrong", "Unable to process request", "info");
                setLoading(false);
            }

        } catch (err) {
            Swal.fire("Something went wrong", "Unable to process request", "info");
            setLoading(false);
        }
    };

    const closeModal = () => {
        setUser({
            email: "", fullname: ""
        });
        setError({
            email: "", fullname: ""
        });
        handleClose();
    };

    const closeAlert = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        showAlert(false);
    };

    return (
        <div>
            <Snackbar
                open={alert}
                autoHideDuration={2000}
                onClose={closeAlert}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            >
                <Alert onClose={closeAlert} severity="success">
                    {type === "create" ? "User created successfuly!" : "User edited successfully!"}
                </Alert>
            </Snackbar>
            <Dialog open={open} onClose={closeModal} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <Typography variant="h6" className={classes.title}>
                            {type === "create" ? "Create User" : "Edit User"}
                        </Typography>
                        <IconButton
                            edge="start"
                            color="inherit"
                            disabled={loading}
                            onClick={closeModal}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <div className={classes.container}>
                    <Paper elevation={3} className={classes.paper}>
                        <form className={classes.form} noValidate autoComplete="off">
                            <div className={classes.textDiv}>
                                <TextField
                                    id="email"
                                    label="Email"
                                    name="email"
                                    error={!!error.email}
                                    required
                                    defaultValue={type !== "create" ? userData && userData.email : ""}
                                    className={classes.text}
                                    helperText={error.email ? error.email : ""}
                                    margin="normal"
                                    type="text"
                                    variant="outlined"
                                    onChange={(e) => handleChange(e)}
                                />
                            </div>
                            <div className={classes.textDiv}>
                                <TextField
                                    id="fullname"
                                    label="Fullname"
                                    name="fullname"
                                    error={!!error.fullname}
                                    required
                                    defaultValue={type !== "create" ? userData && userData.fullname : ""}
                                    className={classes.text}
                                    helperText={error.fullname ? error.fullname : ""}
                                    margin="normal"
                                    type="text"
                                    variant="outlined"
                                    onChange={(e) => handleChange(e)}
                                />
                            </div>
                            {type === 'create' && <div className={classes.textDiv}>
                                <TextField
                                    id="password"
                                    label="Password"
                                    name="password"
                                    error={!!error.fullname}
                                    required
                                    type='password'
                                    // defaultValue={type !== "create" ? userData && userData.fullname : ""}
                                    className={classes.text}
                                    helperText={error.fullname ? error.fullname : ""}
                                    margin="normal"
                                    variant="outlined"
                                    onChange={(e) => handleChange(e)}
                                />
                            </div>}
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={handleSubmit}
                                className={classes.button}
                                size="large"
                                disabled={loading}
                            >
                                Submit
                            </Button>
                        </form>
                        {loading && (
                            <div className={classes.spinner}>
                                <CircularProgress />
                                <Typography
                                    component="span"
                                    variant="body2"
                                    className={classes.inline}
                                    color="initial"
                                >
                                    Submiting User...
                                </Typography>
                            </div>
                        )}
                    </Paper>
                </div>
            </Dialog>
        </div>
    );
}
