import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Tabs, Tab, Divider, colors, Paper } from '@material-ui/core';
import RequestList from './RequestsList';


const useStyles = makeStyles(theme => ({
  root: {
    padding: '3rem'
  },
  tabs: {
    marginTop: '3rem'
  },
  divider: {
    backgroundColor: colors.grey[300]
  },
  content: {
    margin: '3rem'
  },
}));

export default function Requests({ history, match }) {
  const classes = useStyles();
  // const matches = useMediaQuery('(min-width:768px)');
  //   const { tab } = match.params;

  const [tab, setTab] = useState('all');
  const handleTabsChange = (event, value) => {
    // history.push(value);
    setTab(value);
  };



  const tabs = [
    { value: 'all', label: 'All' },
    { value: 'pending', label: 'Pending' },
    { value: 'in-progress', label: 'In-Progress' },
    { value: 'declined', label: 'Declined' },
    { value: 'processed', label: 'Processed' },
    // { value: 'abandoned', label: 'abandoned' }
  ];

  if (!tab) {
    return <Redirect to={'/me/requests'} />;
  }



  return (
    <Paper>
      <Tabs
        className={classes.tabs}
        onChange={handleTabsChange}
        scrollButtons="auto"
        value={tab}
        variant="scrollable">
        {tabs.map(tab => (
          <Tab key={tab.value} label={tab.label} value={tab.value} />
        ))}
      </Tabs>
      <Divider className={classes.divider} />
      <div className={classes.content}>
        {tab === 'all' && <RequestList type={'all'} />}
        {tab === 'pending' && <RequestList type={'pending'} />}
        {tab === 'processed' && <RequestList type={'processed'} />}
        {tab === 'in-progress' && <RequestList type={'in-progress'} />}
        {tab === 'declined' && <RequestList type={'declined'} />}
        {/* {tab === 'abandoned' && <RequestList type={'abandoned'} />} */}
      </div>
    </Paper>
  );
};




