/* eslint-disable no-unused-vars */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import Swal from "sweetalert2";
import { MenuItem } from "@material-ui/core";
import { AppConfig } from "../app-config";

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: "relative"
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1
    },
    container: {
        display: "flex",
        height: "100%",
        alignItems: "center",
        justifyContent: "center"
    },
    paper: {
        minHeight: "45vh",
        height: "auto",
        width: "70%",
        minWidth: "300px",
        margin: "4rem"
        // verticalAlign: "middle",
    },
    form: {
        width: "100%",
        display: "flex",
        height: "auto",
        alignItems: "center",
        justifyContent: "center",
        padding: "4rem 0",
        flexDirection: "column"
    },
    text: {
        width: "100%",
        marginBottom: "1.4rem"
    },
    button: {
        marginTop: "1rem"
    },
    inline: {
        marginLeft: "1rem"
    },
    large: {
        width: theme.spacing(12),
        height: theme.spacing(12),
        marginRight: "1rem"
    },
    spinner: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: "1rem",
        height: "100%"
    },
    textDiv: {
        width: "90%",
        // marginBottom: "1.4rem",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    username: {
        width: "90%",
    },
    textField: {
        width: "48%",
    },
}));

const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
};


export default function PromoterProfile() {
    const [Company, setCompany] = React.useState({});
    const [error, setError] = React.useState({
        name: "", first_name: "", last_name: "", email: "", phone: "", address: "", rc_no: "", entity_type_id: "", username: "", password: ""
    });

    const [loading, setLoading] = React.useState(false);
    const [alert, showAlert] = React.useState(false);
    const [entities, setEntities] = React.useState([]);
    // const [companyData, setCompanyData] = React.useState({});

    const classes = useStyles();
    const user = localStorage.getItem('userDetails');

    const userData = JSON.parse(user);

    const handleChange = (event) => {
        setError({ ...error, [event.target.name]: "" });
        setCompany({
            ...Company,
            [event.target.name]: event.target.value
        });
    };


    const handleSubmit = async () => {
        const { name, first_name, last_name, email, phone, address, rc_no, entity_type_id, username } = Company;

        if (!name) { return setError({ ...error, name: "Please provide a company name" }); };
        if (!first_name) { return setError({ ...error, first_name: "Please provide a First name" }); };
        if (!last_name) { return setError({ ...error, last_name: "Please provide a Last name" }); };
        if (!email) { return setError({ ...error, email: "Please provide a Email" }); };
        if (!phone) { return setError({ ...error, phone: "Please provide a Phone" }); };
        if (!address) { return setError({ ...error, address: "Please provide a Address" }); };
        if (!rc_no) { return setError({ ...error, rc_no: "Please provide a Rc no" }); };
        if (!entity_type_id) { return setError({ ...error, entity_type_id: "Please provide a Entity type id" }); };

        if (phone.length > 11) { return setError({ ...error, phone: "Phone number must be 11 digits" }); };

        const body = {
            name, first_name, last_name, email, phone, address, rc_no, entity_type_id, username, company_id: userData.data.company_id
        };

        try {
            setLoading(true);

            let reqMethod = "PUT";
            let url = AppConfig.URL + "/company/update";

            const { status } = await fetch(url, {
                method: reqMethod,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + sessionStorage["token"]
                },
                body: JSON.stringify(body)
            }).then((res) => res.json());
            if (status) {
                setLoading(false);
                setCompany({
                    name: "", first_name: "", last_name: "", email: "", phone: "", address: "", rc_no: "", entity_type_id: ""
                });
                showAlert(true);
                setTimeout(() => {
                    closeAlert();
                }, 1000);
            } else {
                Swal.fire("Something went wrong", "Unable to process request", "info");
                setLoading(false);
            }

        } catch (err) {
            Swal.fire("Something went wrong", "Unable to process request");
            setLoading(false);
        }
    };

    const getEntities = async () => {
        try {
            const response = await fetch(`${AppConfig.URL}/entity_types/list`, {
                method: "GET",
            });
            const { status, data, message } = await response.json();
            if (status === true) {
                setEntities(data.data)
            } else {
                Swal.fire('', message, 'info');
            }
        } catch (error) {
            Swal.fire('Something went wrong', 'Unable to process request', 'error');
        }
    };

    const getComapnyData = async () => {
        setLoading(true)
        try {
            const response = await fetch(`${AppConfig.URL}/company/get/${userData.data.company_id}`, {
                // const response = await fetch(`${AppConfig.URL}/company/get/1`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${userData.access_token}`
                }
            });
            const { status, data, message } = await response.json();
            if (status === true) {
                setCompany(data);
                setLoading(false)

            } else {
                Swal.fire('', message, 'info');
            }
        } catch (error) {
            Swal.fire('Something went wrong', 'Unable to process request', 'error');
        }
    }

    React.useEffect(() => {
        getEntities();
        getComapnyData();
    }, [])


    const closeAlert = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        showAlert(false);
    };

    if (loading) return <div className={classes.spinner}><CircularProgress /></div>


    return (
        <div>
            <Snackbar
                open={alert}
                autoHideDuration={2000}
                onClose={closeAlert}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            >
                <Alert onClose={closeAlert} severity="success">
                    Company updated successfully!
        </Alert>
            </Snackbar>

            <div className={classes.container}>
                <Paper elevation={3} className={classes.paper}>
                    <form className={classes.form} noValidate autoComplete="off">
                        <Typography variant="h4">Company Profile</Typography>
                        <div className={classes.textDiv}>
                            <TextField
                                label="Company Name"
                                name="name"
                                error={!!error.name}
                                required
                                className={classes.textField}
                                helperText={error.name ? error.name : ""}
                                margin="normal"
                                type="text"
                                variant="outlined"
                                value={Company.name}
                                onChange={(e) => handleChange(e)}
                            />
                            <TextField
                                error={!!error.entity_type_id}
                                select
                                variant="outlined"
                                label="Entity type"
                                name="entity_type_id"
                                value={Company.entity_type_id}
                                onChange={(e) => handleChange(e)}
                                className={classes.textField}
                                helperText={error.entity_type_id ? error.entity_type_id : ""}
                            >
                                {entities.map((option) => (
                                    <MenuItem key={option.entity_type_id} value={option.entity_type_id}>
                                        {option.entity_type}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>

                        <div className={classes.textDiv}>
                            <TextField
                                label="First name"
                                name="first_name"
                                error={!!error.first_name}
                                required
                                className={classes.textField}
                                helperText={error.first_name ? error.first_name : ""}
                                margin="normal"
                                type="text"
                                variant="outlined"
                                value={Company.first_name}
                                onChange={(e) => handleChange(e)}
                            />
                            <TextField
                                label="Last name"
                                name="last_name"
                                error={!!error.last_name}
                                required
                                className={classes.textField}
                                helperText={error.last_name ? error.last_name : ""}
                                margin="normal"
                                type="text"
                                variant="outlined"
                                value={Company.last_name}
                                onChange={(e) => handleChange(e)}
                            />
                        </div>

                        <div className={classes.textDiv}>
                            <TextField
                                label="Email"
                                name="email"
                                error={!!error.email}
                                required
                                className={classes.textField}
                                helperText={error.email ? error.email : ""}
                                margin="normal"
                                type="text"
                                variant="outlined"
                                value={Company.email}
                                onChange={(e) => handleChange(e)}
                            />
                            <TextField
                                label="Phone"
                                name="phone"
                                error={!!error.phone}
                                required
                                className={classes.textField}
                                helperText={error.phone ? error.phone : ""}
                                margin="normal"
                                type="text"
                                variant="outlined"
                                value={Company.phone}
                                onChange={(e) => handleChange(e)}
                            />
                        </div>





                        <div className={classes.textDiv}>
                            <TextField
                                label="Address"
                                name="address"
                                error={!!error.address}
                                required
                                className={classes.textField}
                                helperText={error.address ? error.address : ""}
                                margin="normal"
                                type="text"
                                variant="outlined"
                                value={Company.address}
                                onChange={(e) => handleChange(e)}
                            />
                            <TextField
                                label="RC nunmber"
                                name="rc_no"
                                error={!!error.rc_no}
                                required
                                className={classes.textField}
                                helperText={error.rc_no ? error.rc_no : ""}
                                margin="normal"
                                type="text"
                                variant="outlined"
                                value={Company.rc_no}
                                onChange={(e) => handleChange(e)}
                            />
                        </div>



                        {/* <div className={classes.username}> */}
                        <TextField
                            label="Username"
                            name="username"
                            error={!!error.username}
                            required
                            className={classes.username}
                            helperText={error.username ? error.username : ""}
                            margin="normal"
                            type="text"
                            variant="outlined"
                            value={Company.username || ''}
                            onChange={(e) => handleChange(e)}
                        />
                        {/* </div> */}

                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={handleSubmit}
                            className={classes.button}
                            size="large"
                            disabled={loading}
                        >
                            Submit
              </Button>
                    </form>
                    {loading && (
                        <div className={classes.spinner}>
                            <CircularProgress />
                            <Typography
                                component="span"
                                variant="body2"
                                className={classes.inline}
                                color="initial"
                            >
                                Submiting Company...
                </Typography>
                        </div>
                    )}
                </Paper>
            </div>
        </div>
    );
}
