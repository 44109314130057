import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemPrimaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FolderIcon from "@material-ui/icons/Folder";
import DeleteIcon from "@material-ui/icons/Delete";
import Chip from "@material-ui/core/Chip";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import { Link } from "react-router-dom";
import RequestInformationDialog from './RequestInformationDialog'
import { CommandItem } from "devextreme-react/diagram";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,

    },
    dataContainer: {
        maxWidth: "100%",
        marginRight: "0"
    },
    demo: {
        backgroundColor: theme.palette.background.default,
    },
    title: {
        margin: theme.spacing(4, 0, 2),
    },
    primary: {
        color: "gray"
    },
    secondary: {
        color: "rgba(0, 0, 0, 0.87)",

        fontSize: "18px"
    },
    addButton: {
        background: "#48BB78",
        color: "white",
        "&:hover": {
            background: "#48BB78"
        }
    },
    dates: {
        border: 'solid red',
        marginRight: 8
    }
}));

function generate(element) {
    return [0, 1, 2].map((value) =>
        React.cloneElement(element, {
            key: value,
        })
    );
}

const toMoney = (value) => {
    let parsedValue = new Number(value).toLocaleString("en-US");
    return parsedValue
}

export default function RequestDetails({ requestDetails, applicationHistory }) {
    const classes = useStyles();
    const [dense, setDense] = React.useState(false);
    const [secondary, setSecondary] = React.useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [informationId, setInformationId] = useState('')

    const handleClickOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const addRequestInfo = (id) => {
        setInformationId(id)
        setOpenDialog(true)

    }

    return requestDetails && (
        <div className={classes.root}>
            {/* <Grid container className={classes.dataContainer} fluid spacing={2}> */}
            <Grid container className={classes.dataContainer} spacing={2}>
                <Grid item xs={12} md={4}>
                    <Typography variant="h6" className={classes.title}>
                        Company Details
                    </Typography>
                    <div className={classes.demo}>
                        <List dense>
                            <ListItem>
                                <ListItemText
                                    secondary={<span className={classes.secondary}>{requestDetails.company.name}</span>}
                                    primary="Company Name:"
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    secondary={<span className={classes.secondary}>{requestDetails.company.rc_no}</span>}
                                    primary="RC Number: "
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    secondary={<span className={classes.secondary}>{requestDetails.company.address}</span>}
                                    primary="Address: "
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    secondary={<span className={classes.secondary}>{requestDetails.company.phone}</span>}
                                    primary="Phone Number: "
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    secondary={<span className={classes.secondary}>{requestDetails.company.email}</span>}
                                    primary="Email: "
                                />
                            </ListItem>

                        </List>
                    </div>
                </Grid>


                <Grid item xs={12} md={4}>
                    <Typography variant="h6" className={classes.title}>
                        Service Request
                    </Typography>
                    <div className={classes.demo}>
                        <List dense>
                            <ListItem>
                                <ListItemText
                                    secondary={<span className={classes.secondary}>{requestDetails.service.name}</span>}
                                    primary="Service Requested:"
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    secondary={<span className={classes.secondary}>{requestDetails?.reference}</span>}
                                    primary="Reference: "
                                />
                            </ListItem>
                            {requestDetails?.service?.processing_fee ?
                                <ListItem>
                                    <ListItemText
                                        secondary={<span className={classes.secondary}>{!requestDetails?.service?.processing_fee ? "$0" : toMoney(requestDetails?.service?.processing_fee)}</span>}
                                        primary={(requestDetails.isProcessingFeePaid && requestDetails?.service?.processing_fee) ? "Processing fee | PAID:" : "Processing fee:"}
                                    />
                                </ListItem> : null

                            }
                            <ListItem>
                                <ListItemText
                                    secondary={<span className={classes.secondary}>{
                                        `$ ${toMoney(requestDetails.fee)} `
                                    }</span>}
                                    primary={`Actual Fee | ${requestDetails.service.payBefore ? "Before Approval: " : "After Approval:"}`}
                                />
                                {/* <Chip label="paid" /> */}
                            </ListItem>


                            <ListItem>
                                <ListItemText
                                    secondary={<span className={classes.secondary}>{

                                        new Date(requestDetails.application_date).toLocaleString()
                                    }</span>}
                                    primary="Application Date: "
                                />
                            </ListItem>
                            {requestDetails?.remitta_code ?
                                <ListItem>
                                    <ListItemText
                                        secondary={<span className={classes.secondary}>{requestDetails.remitta_code} </span>}
                                        primary="Remitta code:"
                                    />
                                </ListItem> : null}
                        </List>
                    </div>
                </Grid>



                <Grid item xs={12} md={4}>
                    <Typography variant="h6" className={classes.title}>
                        Admin Actions
                    </Typography>
                    {<div className={classes.demo}>
                        <List dense={dense}>
                            {console.log(applicationHistory.data)}
                            {applicationHistory.data.map((document, index) => (

                                <div key={document.comment + index}>
                                    <ListItem key={index + "approval"}>


                                        <ListItemText
                                            primary={`${document.approval_instance.approval_stage.approval_stage} Remarks (${new Date(document.date_approve).toLocaleString()})`}
                                            secondary={document.comment}
                                        />


                                        {document.approval_asset ?
                                            null
                                            // <ListItemText>
                                            //     <a href={document.approval_asset?.upload_url} target="_blank" download>

                                            //         <IconButton edge="end" aria-label="download">
                                            //             <CloudDownloadIcon />
                                            //         </IconButton>
                                            //     </a>
                                            // </ListItemText>

                                            : null}

                                        {(document?.approval_instance.approval_type?.approval_type == "Request Information") ?
                                            null
                                            // <ListItemPrimaryAction>
                                            //     <Button className={classes.addButton} onClick={() => addRequestInfo(document?.requested_information?.id)}>Add information</Button>
                                            // </ListItemPrimaryAction> 
                                            : null}
                                    </ListItem>

                                    {document?.requested_information ? document?.requested_information?.conversations.map((item, index) => (
                                        <ListItem key={item.comment + index}>
                                            <ListItemText
                                                primary={`User Response (${new Date(item.created_at).toLocaleString()})`}
                                                secondary={item.comment}
                                            />
                                            <ListItemSecondaryAction>
                                                <a href={item.upload_url} target="_blank" download>
                                                    <IconButton edge="end" aria-label="download">
                                                        <CloudDownloadIcon />
                                                    </IconButton>
                                                </a>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    )

                                    ) : null}
                                    <hr />
                                </div>)
                            )}

                            {document?.requested_information ? document?.requested_information?.conversations.map((item) => (
                                <ListItem>

                                    {/* <ListItemText
                                        primary={"Response"}
                                        secondary={item.comment}
                                    />
                                    <ListItemSecondaryAction>
                                        <a href={item.upload_url} target="_blank" download>
                                            <IconButton edge="end" aria-label="download">
                                                <CloudDownloadIcon />
                                            </IconButton>

                                        </a>
                                    </ListItemSecondaryAction> */}



                                </ListItem>
                            )

                            ) : null}



                        </List>
                    </div>}
                </Grid>
                {requestDetails?.service?.name === 'Zone OPL' || requestDetails?.service?.name === 'Zone OPL Renewal Fees' || requestDetails?.service?.name === 'Free Zone' || requestDetails?.service?.name === 'Industrial Park' ?
                    <>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h6" className={classes.title}>Zone Details</Typography>
                            {requestDetails.zone !== null ?
                                <div className={classes.demo}>
                                    <List dense>
                                        <ListItem>
                                            <ListItemText
                                                secondary={<span className={classes.secondary}>{requestDetails?.zone?.name}</span>}
                                                primary="Name:"
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText
                                                secondary={<span className={classes.secondary}>{requestDetails?.zone?.address}</span>}
                                                primary="Address: "
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText
                                                secondary={<span className={classes.secondary}>{requestDetails?.zone?.zone_type}</span>}
                                                primary="Zone Type: "
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText
                                                secondary={<span className={classes.secondary}>{requestDetails?.zone?.state}</span>}
                                                primary="State: "
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText
                                                secondary={<span className={classes.secondary}>{requestDetails?.zone?.lga}</span>}
                                                primary="LGA: "
                                            />
                                        </ListItem>
                                    </List>
                                </div> : <p>No zone details found</p>
                            }
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h6" className={classes.title}>ZMC Details</Typography>
                            {requestDetails.zmc !== null ?
                                <div className={classes.demo}>
                                    <List dense>
                                        <ListItem>
                                            <ListItemText
                                                secondary={<span className={classes.secondary}>{requestDetails?.zmc?.name}</span>}
                                                primary="Name:"
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText
                                                secondary={<span className={classes.secondary}>{requestDetails?.zmc?.address}</span>}
                                                primary="Address: "
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText
                                                secondary={<span className={classes.secondary}>{requestDetails?.zmc?.email}</span>}
                                                primary="Email: "
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText
                                                secondary={<span className={classes.secondary}>{requestDetails?.zmc?.phone}</span>}
                                                primary="Phone: "
                                            />
                                        </ListItem>
                                    </List>
                                </div> : <p>No ZMC details found</p>
                            }
                        </Grid>
                    </>
                    : null
                }
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                    <Typography variant="h6" className={classes.title}>
                        Uploaded Documents
                </Typography>
                    {!requestDetails.application_attachments.length ? (
                        <h1>No document found</h1>
                    ) : (
                        <div className={classes.demo}>
                            <List dense={dense}>
                                {requestDetails.application_attachments.map(
                                    (document, index) => (
                                        <div key={index + document.upload_date}>
                                            <ListItem>
                                                <ListItemAvatar>
                                                    <Avatar>
                                                        <FolderIcon />
                                                    </Avatar>
                                                </ListItemAvatar>

                                                {!document.document ? null : (
                                                    <ListItemText
                                                        primary={document.document.name}
                                                        // secondary={document.document.description}
                                                        secondary={<span>{`Uploaded: ${new Date(document.upload_date).toLocaleString()}`}</span>}

                                                    />
                                                )}

                                                {!document.document ? null : (
                                                    <ListItemText
                                                        primary={`Last Updated:`}
                                                        secondary={document.update_date ? `${new Date(document.update_date).toLocaleString()}` : 'File has never been updated'}
                                                    />
                                                )}

                                                <a href={document.upload_url} target="_blank" download>
                                                    <ListItemSecondaryAction>
                                                        <IconButton edge="end" aria-label="download">
                                                            <CloudDownloadIcon />
                                                        </IconButton>
                                                    </ListItemSecondaryAction>
                                                </a>

                                            </ListItem>
                                            <hr />
                                        </div>
                                    )

                                )}
                            </List>
                        </div>
                    )}
                </Grid>
            </Grid>

        </div >

    );
}
