import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from "@material-ui/lab/Alert";
import CircularProgress from '@material-ui/core/CircularProgress';
import { useParams, } from "react-router-dom";
import { useHistory } from "react-router-dom";




import axiosInstance from "../../../Api/Api.js";




function Alert(props) {
  return <MuiAlert elevation={6} vertical="top" color="primary" variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "white",
    padding: '40px',
    width: 'fit-content',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0px',
    '& > *': {
      margin: theme.spacing(1),
      width: '50ch',
    },

  },
  centerForm: {
    display: "flex",
    flexDirection: "row",
    alignItems: 'center',
    justifyContent: 'center',
    width: "100%",
    height: "100%"
  },
  formTitle: {
    fontWeight: 'bold',
    fontSize: '24px',
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    marginBottom: '20px',
  },
  buttonWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
  }
}));

export default function EditCreate(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    email: "",
    password: "",
    fullname: "",
    role_id: '',

  });

  const [open, setOpen] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const history = useHistory();
  const [erorrMessage, setErrorMessage] = useState("");
  const [roles, setRoles] = useState([]);


  const params = useParams();


  const handleChange = (prop) => (event) => {
    event.preventDefault();
    setValues({ ...values, [prop]: event.target.value });
  };

  const fetchRoles = () => {
    setLoading(true);

    axiosInstance
      .get("/role/list")
      .then(function (response) {
        console.log(response);
        if (response.status === 200) {
          setRoles(response.data.data.data);
          console.log(response.data.data.data);
          console.log(props.match.params);
        } else {
          console.log(response);
        }
      })
      .catch(function (error) {
        // console.log(error.response.data.message);
      })
      .finally(() => setLoading(false));
  };


  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };



  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitLoading(true);
    axiosInstance.post("/users/create_admin", { users: [{ ...values }] })
      .then(function (response) {
        if (response.status === 200) {
          history.push("/admin-account/view-users");
          console.log(response);
          setErrorMessage(response.data.message);
          console.log(response.data.message);
          setOpen(true);
        } else {
          console.log(response);
        }
      })
      .catch(function (error) {
        console.log(error.response.data.message);
        const { message } = error.response.data;
        setErrorMessage(message);
        setOpen(true);
      })
      .finally(() => setSubmitLoading(false));
  };

  useEffect(() => {
    fetchRoles();
  }, []);

  return (
    <div className={classes.centerForm}>
      <form className={classes.root} noValidate autoComplete="off">
        <div className={classes.formTitle}>Create User </div>
        <TextField type="text" InputLabelProps={{
          shrink: true,
        }} label="Email"
          value={values.email}
          onChange={handleChange("email")}
        />
        <TextField InputLabelProps={{
          shrink: true,
        }} label="Password"
          value={values.password}
          onChange={handleChange("password")}
        />

        <TextField InputLabelProps={{
          shrink: true,
        }} label="Full Name"
          value={values.fullname}
          onChange={handleChange("fullname")}
        />


        <TextField select InputLabelProps={{
          shrink: true,
        }} label="Role"
          value={values.role_id}
          onChange={handleChange("role_id")}
        > {roles.map((option) => (
          <MenuItem
            key={option.role_id}
            value={option.role_id}
          >
            {option.role_name}
          </MenuItem>
        ))}

        </TextField>

        <div className={classes.buttonWrapper}>
          <Button
            variant="contained"
            onClick={handleSubmit}
            color="primary"
            disabled={!(values.email && values.password
              && values.fullname && values.role_id)}
          >

            {submitLoading ? <CircularProgress color="secondary" /> : "Submit"}
          </Button>
        </div >
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success">
            {erorrMessage}
          </Alert>
        </Snackbar>
      </form>
    </div>
  );
}
