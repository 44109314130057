import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from "@material-ui/lab/Alert";
import CircularProgress from '@material-ui/core/CircularProgress';
import { useParams,  } from "react-router-dom";



import axiosInstance from "../../../Api/Api.js";




function Alert(props) {
    return <MuiAlert elevation={6} vertical="top" color="primary" variant="filled" {...props} />;
  }

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "white",
    padding: '40px',
    width: 'fit-content',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0px',
    '& > *': {
      margin: theme.spacing(1),
      width: '50ch',
    },
   
  },
  centerForm: {
    display: "flex",
    flexDirection: "row",
      alignItems: 'center',
      justifyContent: 'center',
      width: "100%",
      height: "100%"
},
formTitle: {
  fontWeight: 'bold',
    fontSize: '24px',
    display: 'flex', 
    width: '100%',
    justifyContent: 'center',
    marginBottom: '20px',
},
buttonWrapper: {
  display: 'flex',
  width: '100%',
  justifyContent: 'flex-end',
}
}));

export default function EditCreate(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    process_module: ''
    
  });

  const [open, setOpen] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  const [erorrMessage, setErrorMessage] = useState("");
  
  

  


  const handleChange = (prop) => (event) => {
    event.preventDefault();
    setValues({ ...values, [prop]: event.target.value });
  };

  
  
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

 

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitLoading(true);
    axiosInstance.post("/approval_processmodule/add", {...values})
      .then(function (response) {
        if (response.status === 200) {
          console.log(response);
        setErrorMessage(response.data.message);
        setOpen(true);
        } else {
          console.log(response);
        }
      })
      .catch(function (error) {
        console.log(error.response.data.message);
        const { message } = error.response.data;
        setErrorMessage(message);
        setOpen(true);
      })
      .finally(() => setSubmitLoading(false));
  };

  

  return (
      <div className={classes.centerForm}>
    <form className={classes.root} noValidate autoComplete="off">
       <div className={classes.formTitle}>Create Process Module </div>
      <TextField  type="text" InputLabelProps={{
            shrink: true,
          }} label="Process Module"
          value={values.process_module}
          onChange={handleChange("proccess_module")}
          />
         
          
                
              <div className={classes.buttonWrapper}>
              <Button 
              variant="contained" 
              onClick={handleSubmit} 
              color="primary"
              disabled={!(values.process_module)}
              >
                  
                  {submitLoading ? <CircularProgress color="secondary" />:  "Submit" }
</Button>
</div >
<Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          {erorrMessage}
        </Alert>
      </Snackbar>
    </form>
    </div>
  );
}
