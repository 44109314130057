import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { useHistory, Link } from "react-router-dom";
import { Grid, TablePagination } from "@material-ui/core";
import { HistoryTwoTone } from "@material-ui/icons";
import CircularProgress from "@material-ui/core/CircularProgress";
import axiosInstance from "../../../Api/Api.js";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableFont: {
    // fontWeight: "bold",
    fontSize: "18px",
  },
  tableHeader: {
    paddingLeft: "0",
    fontSize: "18px",
    width: "100%",
    marginLeft: "10px",
  },
  headerContainer: {
    marginBottom: "10px",
  },
});

export default function BasicTable() {
  const classes = useStyles();
  const history = useHistory();
  const [allRequests, setAllRequests] = useState([]);
  const [loading, setLoading] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [paginated, setPaginated] = useState(false);

  // const showDetails = (rowId) => {
  //   console.log(rowId);
  //   history.push(`/admin-account/services/edit-create/${rowId}`);
  // };

  const showRequestDetails = (rowId) => {
    console.log(rowId);
    // history.push(`/me/history-request-details/${rowId}`);
    history.push(`/admin-account/history-request-details/${rowId}`);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setPaginated(true);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const fetchHistory = () => {
    setLoading(true);
    axiosInstance
      .get("/application_approval/list")
      .then(function (response) {
        if (response.status === 200) {
          setAllRequests(response.data.data);
          // console.log(response.data, allRequests);
          console.log(response.data.data)
        } else {
          console.log(response);
        }
      })
      .catch(function (error) {
        // console.log(error.response.data.message);
      })
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    fetchHistory();
  }, []);

  const formatDate = (date) => {
    const options = {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    if (date) {
      return new Date(date).toLocaleDateString(undefined, options);
    }
    return null;
  };

  return loading ? (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      style={{ height: "100%" }}
    >
      <CircularProgress color="primary" />
    </Grid>
  ) : !allRequests.length ? (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      style={{ height: "100%" }}
    >
      <Typography variant="h6">No Record found</Typography>
    </Grid>
  ) : (
    <div>
      <Grid
        container
        direction="row"
        justify="space-between"
        className={classes.headerContainer}
        alignItems="flex-end"
      >
        <div>
          <Typography className={classes.tableHeader}>
            Approval History
          </Typography>
        </div>

      </Grid>
      <TableContainer component={Paper} variant="outlined">
        <Table className={classes.table} aria-label="data-table">
          <TableHead>
            <TableRow className={classes.tableFont}>
              <TableCell className={classes.tableFont}>S/N</TableCell>
              <TableCell className={classes.tableFont}>Reference</TableCell>
              <TableCell className={classes.tableFont}>Service Name</TableCell>
              <TableCell className={classes.tableFont}>
                Application Date
              </TableCell>
              <TableCell className={classes.tableFont}>
                Approval Action Date
              </TableCell>
              {/* <TableCell className={classes.tableFont}>Actual Fee </TableCell> */}
              <TableCell className={classes.tableFont}>Comment</TableCell>
              <TableCell className={classes.tableFont}>Status</TableCell>
              <TableCell className={classes.tableFont}>Action</TableCell>

            </TableRow>
          </TableHead>
          <TableBody>
            {allRequests && allRequests.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
              <TableRow key={row.date_approve}>
                <TableCell component="th" scope="row">
                  {index + 1}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.reference}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell>{row.application_date}</TableCell>
                <TableCell>{row.date_approve}</TableCell>
                <TableCell>{row.comment}</TableCell>
                {
                  row.approval_type == 'Approve' ? <TableCell style={{ color: 'green', fontWeight: 'bold' }}> Approved</TableCell> : row.approval_type == 'Declined' ? <TableCell style={{ color: 'red', fontWeight: 'bold' }}> Declined</TableCell> : <TableCell style={{ color: '#F7CB73', fontWeight: 'bold' }}> {row.approval_type}</TableCell>
                }

                {/* <TableCell>{row.approval_type === 'Approve' ? 'Approved' : row.approval_type === 'Decline' ? 'Declined' : row.approval_type}</TableCell> */}
                <TableCell>
                  {/* {" "} */}
                  <Button size="small" variant="outlined" color="primary" onClick={() => showRequestDetails(row.application_id)}>
                    View Details
                      </Button>
                </TableCell>
                {/* <TableCell component="th" scope="row">
                      {row.application_id}
                    </TableCell> */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={allRequests.length}
          onChangePage={handleChangePage}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[10, 20, 50, 100]}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </TableContainer>
    </div>
  );
}
