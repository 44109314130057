import React, { Component } from "react";
import "./errorHandler.css";

export class ErrorPage extends Component {
  render() {
    return (
      <div className="error-page">
        <div className="error-image"></div>
        <div className="error-instruction">
          <h1 className="error-header">...Something went wrong!</h1>
          <p>We are working to fix this as soon as posible.</p>

          <p>You can also reach out to our support team via:</p>

          <div>
            <div>
              <i className="fa fa-phone"></i>08149906336
            </div>
            <div>
              <i className="fab fa-whatsapp"></i>09030009930
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ErrorPage;
