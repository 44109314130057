import React from 'react';
import { TableContainer, Table, TableBody, TableCell, TableHead, TableRow, capitalize, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';


const useStyles = makeStyles({ 
    table: {
        // minWidth: 650,
    },
});


export default function Tables({ list, renewal }) {
    const classes = useStyles();

    return (
        <TableContainer>
            <Table className={classes.table} aria-label="caption table">
                
                <TableHead>
                    <TableRow>
                        {
                            renewal ? (
                                <>
                                    <TableCell><Typography variant="subtitle1" component="h6" gutterBottom>Title</Typography></TableCell>
                                    <TableCell><Typography variant="subtitle1" component="h6" gutterBottom>Amount</Typography></TableCell>
                                </>
                            ) : (
                                    list && Object.keys(list[0]).map((title) => (
                                        <TableCell><Typography variant="subtitle1" component="h6" gutterBottom>{capitalize(title)}</Typography></TableCell>
                                    )))}

                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        renewal ? (
                    list && list.map((l) => (

                            <TableRow>
                            <TableCell><Typography variant="caption" gutterBottom>{l.topic}</Typography></TableCell>
                                <TableCell><Typography variant="caption" gutterBottom>{moment(l.due_date).format('DD MMM YYYY | hh:mm')}</Typography></TableCell>
                                </TableRow>
                        ))) : (
                    list && list.map((row) => (
                        <TableRow>
                            {row && Object.values(row).map((val) => (
                                <TableCell><Typography variant="caption" gutterBottom>{val}</Typography></TableCell>
                            ))}
                        </TableRow>
                    )))}
                </TableBody>
            </Table>
        </TableContainer>
    )
} 