import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from "@material-ui/lab/Alert";
import CircularProgress from '@material-ui/core/CircularProgress';
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";



import axiosInstance from "../../Api/Api.js";




function Alert(props) {
  return <MuiAlert elevation={6} vertical="top" color="primary" variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '50ch',
    },
  },
}));

export default function EditCreate(props) {
  const classes = useStyles();
  const params = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    category_id: '',
    name: "",
    actual_fee: "",
    processmodule_id: '',
    service_type_id: ''
  });

  const [open, setOpen] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  const [erorrMessage, setErrorMessage] = useState("");
  const [categories, setCategories] = useState([]);
  const [processModule, setProcessModule] = useState([]);
  const [serviceTypes, setServiceTypes] = useState([]);




  const handleChange = (prop) => (event) => {
    event.preventDefault();
    setValues({ ...values, [prop]: event.target.value });
  };

  const fetchProcessModules = () => {
    setLoading(true);

    axiosInstance
      .get("/approval_processmodule/list")
      .then(function (response) {
        if (response.status === 200) {
          setProcessModule(response.data.data.data);
        } else {
          console.log(response);
        }
      })
      .catch(function (error) {
        // console.log(error.response.data.message);
      })
      .finally(() => setLoading(false));
  };

  const fetchServiceTypes = () => {
    setLoading(true);

    axiosInstance
      .get("/service_types/list")
      .then(function (response) {

        if (response.status === 200) {
          setServiceTypes(response.data.data.data);
        } else {
          console.log(response);
        }
      })
      .catch(function (error) {
        // console.log(error.response.data.message);
      })
      .finally(() => setLoading(false));
  };
  const fetchSingleService = () => {
    setLoading(true);
    axiosInstance
      .get(`/services/get_one/${params.rowId}`)
      .then(function (response) {
        if (response.status === 200) {
          console.log(response.data.data);
          const {
            category_id,
            name,
            actual_fee,
            processmodule_id,
            service_type_id,
          } = response.data.data;

          setValues({
            ...values,
            category_id,
            name,
            actual_fee,
            processmodule_id,
            service_type_id,
          });
          console.log(response.data.data);
        } else {
          console.log(response);
        }
      })
      .catch(function (error) {
        // console.log(error.response.data.message);
      })
      .finally(() => setLoading(false));
  };

  const fetchCategories = () => {
    setLoading(true);

    axiosInstance
      .get("/category/list")
      .then(function (response) {

        if (response.status === 200) {
          setCategories(response.data.data);
        } else {
          console.log(response);
        }
      })
      .catch(function (error) {
        // console.log(error.response.data.message);
      })
      .finally(() => setLoading(false));
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitLoading(true);
    const { category_id, name, actual_fee, processmodule_id, service_type_id } = values;
    const endpoint =
      params.rowId === "create" ? "/services/add" : "/services/update";
    const body =
      params.rowId === "create"
        ? {
          category_id,
          name,
          actual_fee,
          processmodule_id,
          service_type_id,
        }
        : {
          service_id: params.rowId,
          name,
        };
    const method = params.rowId === "create" ? "post" : "put";
    axiosInstance[method](endpoint, body)
      .then(function (response) {
        if (response.status === 200) {
          history.push("/admin-account/services");
          setErrorMessage(response.data.message);
          setOpen(true);
        } else {
          console.log(response);
        }
      })
      .catch(function (error) {
        console.log(error.response.data.message);
        const { message } = error.response.data;
        setErrorMessage(message);
        setOpen(true);
      })
      .finally(() => setSubmitLoading(false));
    // axiosInstance
    //   .post("/services/add", {
    //     ...values
    //   })
    //   .then(function (response) {
    //     if (response.status === 200) {
    //       console.log(response);
    //       //   history.push("/admin-account/action-submitted");
    //       setErrorMessage("Zone Successfully created");
    //       setOpen(true);
    //     } else {
    //       console.log(response);
    //     }
    //   })
    //   .catch(function (error) {
    //     console.log(error.response.data.message);
    //     const { message } = error.response.data;
    //     setErrorMessage(message);
    //     setOpen(true);
    //   })
    //   .finally(() => setSubmitLoading(false));
  };

  useEffect(() => {
    fetchProcessModules();
    fetchServiceTypes();
    fetchCategories();
    if (params.rowId !== "create") {
      fetchSingleService();
    }
  }, []);

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <div style={{ fontWeight: "bold" }}>Create New Service</div>
      <TextField type="text" InputLabelProps={{
        shrink: true,
      }} label="Service Name"
        value={values.name}
        onChange={handleChange("name")}
      />
      <TextField InputLabelProps={{
        shrink: true,
      }} label="Actual Fee"
        value={values.actual_fee}
        onChange={handleChange("actual_fee")}
      />


      <TextField select InputLabelProps={{
        shrink: true,
      }} label="Category"
        value={values.category_id}
        onChange={handleChange("category_id")}

      >
        {categories.map((option, index) => (
          <MenuItem
            key={option.id}
            value={option.id}
          >
            {option.name}
          </MenuItem>
        ))}
      </TextField>
      <TextField select InputLabelProps={{
        shrink: true,
      }} label="Process Module"
        value={values.processmodule_id}
        onChange={handleChange("processmodule_id")}
      > {processModule.map((option, index) => (
        <MenuItem
          key={option.processmodule_id}
          value={option.processmodule_id}
        >
          {option.processmodule}
        </MenuItem>
      ))}

      </TextField>
      <TextField select InputLabelProps={{
        shrink: true,
      }} label="Service Type"
        value={values.service_type_id}
        onChange={handleChange("service_type_id")}
      > {serviceTypes.map((option, index) => (
        <MenuItem
          key={option.service_type_id}
          value={option.service_type_id}
        >
          {option.service_type}
        </MenuItem>
      ))}

      </TextField>



      <div>
        <Button
          variant="contained"
          onClick={handleSubmit}
          color="primary"
          disabled={!(values.category_id && values.name
            && values.actual_fee && values.processmodule_id && values.service_type_id)}
        >

          {submitLoading ? <CircularProgress color="secondary" /> : "Create Service"}
        </Button>
      </div>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          {erorrMessage}
        </Alert>
      </Snackbar>
    </form>
  );
}
