import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemPrimaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FolderIcon from '@material-ui/icons/Folder';
import DeleteIcon from '@material-ui/icons/Delete';
import Chip from '@material-ui/core/Chip';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { Link } from 'react-router-dom';
import RequestInformationDialog from './RequestInformationDialog';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  dataContainer: {
    maxWidth: '100%',
    marginRight: '0',
  },
  demo: {
    backgroundColor: theme.palette.background.default,
  },
  title: {
    margin: theme.spacing(4, 0, 2),
  },
  primary: {
    color: 'gray',
  },
  secondary: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '18px',
    display: 'block'
  },
  addButton: {
    background: '#48BB78',
    color: 'white',
    marginLeft: '20px',
    '&:hover': {
      background: '#48BB78',
    },
  },
  fileTitle: {
    border: '1px solid gray',
    borderRadius: '10px',
    padding: '10px',
    textAlign: 'center',
    maxWidth: '150px',
  },
  truncate: {
    width: '120px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

function generate(element) {
  return [0, 1, 2].map((value) =>
    React.cloneElement(element, {
      key: value,
    })
  );
}

const toMoney = (value) => {
  let parsedValue = new Number(value).toLocaleString('en-US');
  return parsedValue;
};

export default function RequestDetails({
  requestDetails,
  applicationHistory,
  setDialogAlert,
}) {
  const classes = useStyles();
  const [dense, setDense] = React.useState(false);
  const [secondary, setSecondary] = React.useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [informationId, setInformationId] = useState('');
  const [documentId, setDocumentId] = useState('')

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const addRequestInfo = (id) => {
    setInformationId(id);
    setOpenDialog(true);
  };

  return (
    requestDetails && (
      <div className={classes.root}>
        {/* <Grid container className={classes.dataContainer} fluid spacing={2}> */}
        <Grid container className={classes.dataContainer} spacing={2}>
          <Grid item xs={12} md={5}>
            <Typography variant="h6" className={classes.title}>
              Service Request
            </Typography>
            <div className={classes.demo}>
              <List dense>
                <ListItem>
                  <ListItemText
                    secondary={
                      <span className={classes.secondary}>
                        {requestDetails.service.name}
                      </span>
                    }
                    primary="Service Requested:"
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    secondary={
                      <span className={classes.secondary}>
                        {requestDetails?.reference}
                      </span>
                    }
                    primary="Reference: "
                  />
                </ListItem>
                {requestDetails?.service?.processing_fee ? (
                  <ListItem>
                    <ListItemText
                      secondary={
                        <span className={classes.secondary}>
                          {!requestDetails?.service?.processing_fee
                            ? '$0'
                            : toMoney(requestDetails?.service?.processing_fee)}
                        </span>
                      }
                      primary={
                        requestDetails.isProcessingFeePaid &&
                          requestDetails?.service?.processing_fee
                          ? 'Processing fee | PAID:'
                          : 'Processing fee:'
                      }
                    />
                  </ListItem>
                ) : null}
                <ListItem>
                  <ListItemText
                    secondary={
                      <span className={classes.secondary}>{`$ ${toMoney(
                        requestDetails.fee
                      )} `}</span>
                    }
                    primary={`Actual Fee | ${requestDetails.service.payBefore
                      ? `Before Approval | ${requestDetails.isPaid ? ' PAID:' : ' UNPAID:'}`
                      : `After Approval | ${requestDetails.isPaid ? ' PAID:' : ' UNPAID:'}`
                      }`}
                  />
                  {/* <Chip label="paid" /> */}
                </ListItem>

                <ListItem>
                  <ListItemText
                    secondary={
                      <span className={classes.secondary}>
                        {new Date(
                          requestDetails.application_date
                        ).toLocaleString()}
                      </span>
                    }
                    primary="Application Date: "
                  />
                </ListItem>
                {requestDetails?.remitta_code ? (
                  <ListItem>
                    <ListItemText
                      secondary={
                        <span className={classes.secondary}>
                          {requestDetails.remitta_code}{' '}
                        </span>
                      }
                      primary="Remitta code:"
                    />
                  </ListItem>
                ) : null}
              </List>
            </div>
          </Grid>
          <Grid item xs={12} md={7}>
            <Typography variant="h6" className={classes.title}>
              Admin Actions
            </Typography>
            {
              <div className={classes.demo}>
                <List dense={dense}>
                  {applicationHistory.data.map((document, index) => (
                    <div key={index + 'approval'}>
                      <ListItem>
                        <ListItemText
                          primary={`Admin Remarks (${new Date(document.date_approve).toLocaleString()})`}
                          secondary={document?.requested_information?.comment || document.comment}
                        // secondary={document.comment}
                        />
                        {document?.requested_information?.document_id ? localStorage.setItem('docuId', document.requested_information.document_id) : null}
                        {document.approval_asset ? (
                          <ListItemText className={classes.fileTitle}>
                            <a
                              href={document?.approval_asset?.upload_url}
                              target="_blank"
                              download
                            >
                              <IconButton edge="end" aria-label="download">
                                <CloudDownloadIcon />
                              </IconButton>
                            </a>
                            <Tooltip
                              title={document?.approval_asset?.file_title}
                            >
                              <div className={classes.truncate}>
                                {document?.approval_asset?.file_title}
                              </div>
                            </Tooltip>
                          </ListItemText>
                        ) : null}

                        {document?.approval_instance.approval_type
                          ?.approval_type == 'Request Information' ? (
                          <ListItemText>
                            <Button
                              className={classes.addButton}
                              onClick={() =>
                                addRequestInfo(
                                  document?.requested_information?.id
                                )
                              }
                            >
                              Add information
                            </Button>
                          </ListItemText>
                        ) : null}
                      </ListItem>

                      {document?.requested_information
                        ? document?.requested_information?.conversations.map(
                          (item, index) => (
                            <ListItem key={index + 'comment'}>
                              <ListItemText
                                // primary={'User Response'}
                                primary={`User Response (${new Date(item.created_at).toLocaleString()})`}
                                secondary={item.comment}
                              />
                              <ListItemSecondaryAction>
                                <a
                                  href={item.upload_url}
                                  target="_blank"
                                  download
                                >
                                  <IconButton
                                    edge="end"
                                    aria-label="download"
                                  >
                                    <CloudDownloadIcon />
                                  </IconButton>
                                </a>
                              </ListItemSecondaryAction>
                            </ListItem>
                          )
                        )
                        : null}
                      <hr />
                    </div>
                  ))}

                  {document?.requested_information
                    ? document?.requested_information?.conversations.map(
                      (item, index) => (
                        <ListItem key={index + item.comment}>
                          <ListItemText
                            primary={'Response'}
                            secondary={item.comment}
                          />
                          <ListItemSecondaryAction>
                            <a
                              href={item.upload_url}
                              target="_blank"
                              download
                            >
                              <IconButton edge="end" aria-label="download">
                                <CloudDownloadIcon />
                              </IconButton>
                            </a>
                          </ListItemSecondaryAction>
                        </ListItem>
                      )
                    )
                    : null}
                </List>
              </div>
            }
          </Grid>
        </Grid>
        <RequestInformationDialog
          open={openDialog}
          handleClose={handleCloseDialog}
          handleClickOpen={handleClickOpenDialog}
          informationId={informationId}
          setOpenDialog={setOpenDialog}
          setDialogAlert={setDialogAlert}
          documentId={documentId}
        />
      </div>
    )
  );
}
