import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";
import axiosInstance from "../../Api/Api.js";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";


function Alert(props) {
    return (
        <MuiAlert
            elevation={6}
            vertical="top"
            color="primary"
            variant="filled"
            {...props}
        />
    );
}
const useStyles = makeStyles((theme) => ({
    root: {
        "& > *": {
            margin: theme.spacing(1),
            width: "50ch",
        },
    },
}));

export default function EditCreate(props) {
    const classes = useStyles();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [values, setValues] = useState({
        service_type: "",
        service_code: "",
        service_type_id: "",
    });

    const [open, setOpen] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);

    const [erorrMessage, setErrorMessage] = useState("");

    const params = useParams();

    const handleChange = (prop) => (event) => {
        event.preventDefault();
        setValues({ ...values, [prop]: event.target.value });
    };


    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };

    const fetchServiceType = () => {
        setLoading(true);
        axiosInstance
            .get(`/service_types/get/${params.rowId}`)
            .then(function (response) {
                if (response.status === 200) {
                    console.log(response.data.data);
                    const {
                        service_type,
                        service_code,
                        service_type_id,
                    } = response.data.data;

                    setValues({
                        ...values,
                        service_type,
                        service_code,
                        service_type_id,
                    });
                    console.log(response.data.data);
                } else {
                    console.log(response);
                }
            })
            .catch(function (error) {
                // console.log(error.response.data.message);
            })
            .finally(() => setLoading(false));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmitLoading(true);
        const { service_type, service_code, service_type_id, } = values;
        const endpoint =
            params.rowId === "create" ? "/service_types/add" : "/service_types/update";
        const body =
            params.rowId === "create"
                ? {
                    service_type,
                    service_code,
                }
                : {
                    service_type,
                    service_type_id: params.rowId

                };
        const method = params.rowId === "create" ? "post" : "put";
        axiosInstance[method](endpoint, body)
            .then(function (response) {
                if (response.status === 200) {
                    history.push("/admin-account/service_types");
                    setErrorMessage(response.data.message);
                    setOpen(true);
                } else {
                    console.log(response);
                }
            })
            .catch(function (error) {
                console.log(error.response.data.message);
                const { message } = error.response.data;
                setErrorMessage(message);
                setOpen(true);
            })
            .finally(() => setSubmitLoading(false));
    };

    useEffect(() => {
        if (params.rowId !== "create") {
            fetchServiceType();
        }
    }, []);

    return (
        <form className={classes.root} noValidate autoComplete="off">
            {/* <div style={{ fontWeight: "bold" }}>Create New Document</div> */}
            <div style={{ fontWeight: "bold" }}>
                {params.rowId === "create" ? "Create New Service Type" : "Edit Service Type"}
            </div>
            <TextField
                type="text"
                InputLabelProps={{
                    shrink: true,
                }}
                label="Service Type"
                value={values.service_type}
                onChange={handleChange("service_type")}
            />
            <TextField
                InputLabelProps={{
                    shrink: true,
                }}
                label="Service Code"
                value={values.service_code}
                onChange={handleChange("service_code")}
            />




            <div>
                <Button
                    variant="contained"
                    onClick={handleSubmit}
                    color="primary"
                    disabled={
                        !(
                            values.service_type &&
                            values.service_code

                        )
                    }
                >
                    {/* {submitLoading ? <CircularProgress color="secondary" /> : "Create Document"} */}
                    {submitLoading ? (
                        <CircularProgress color="secondary" />
                    ) : params.rowId === "create" ? (
                        "Create Service Type"
                    ) : (
                        "Update"
                    )}
                </Button>
            </div>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success">
                    {erorrMessage}
                </Alert>
            </Snackbar>
        </form>
    );
}
