import { CircularProgress, Paper, TablePagination } from "@material-ui/core";
import { AppConfig } from "app-config";
import React, { useState } from "react";
import Swal from "sweetalert2";
import { TableContainer, Table, TableBody, TableCell, TableHead, TableRow, capitalize, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { blue, green, grey } from "@material-ui/core/colors";


const useStyles = makeStyles(theme => ({
    table: {
        // minWidth: 650,
    },
    row: {
        '& .MuiTableRow-root': {
            '&:nth-of-type(odd)': {
                backgroundColor: grey[50]
            }
        }
    },
    head: {
        backgroundColor: green[200]
    },
    spinner: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
}));

export default function Tariffs() {
    const [loading, setLoading] = useState(false);
    const [tariffs, setTariffs] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const classes = useStyles();

    const user = localStorage.getItem('userDetails');

    const userData = JSON.parse(user)

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const getTariffs = async () => {
        setLoading(true)
        try {
            const response = await fetch(`${AppConfig.URL}/services/list`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${userData.access_token}`
                }
            });
            const { status, data, message } = await response.json();
            if (status === true) {
                
                setTariffs(data);
            } else {
                Swal.fire('', message, 'info');
            }
            setLoading(false)
        } catch (error) {
            Swal.fire('Something went wrong', 'Unable to fetch records', 'error');
            setLoading(false)
        }
    }


    React.useEffect(() => {
        getTariffs()
    }, []);

    const formatAsMoney = fee => {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD'
        }).format(fee);
    };


    if (loading) return <div className={classes.spinner}><CircularProgress /></div>

    return (
        <>
            <Typography>List of Tariffs</Typography>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="caption table">
                    <TableHead className={classes.head}>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell align="left">Cost</TableCell>
                            <TableCell align="left">Category</TableCell>
                            {/* <TableCell>Action</TableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody className={classes.row}>
                        {tariffs && tariffs.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((tariff) => (
                            <TableRow>
                                <TableCell>{tariff.name}</TableCell>
                                <TableCell align="left">{formatAsMoney(tariff.actual_fee)}</TableCell>
                                <TableCell align="left">{tariff.category.name}</TableCell>
                                {/* <TableCell>
                                {<Button
                            // autoFocus
                            variant="outlined"
                            color="primary"
                            // onClick={handleSubmit}
                            className={classes.button}
                            size="large"
                            disabled={loading}
                        >
                            Start Application
                        </Button>}</TableCell> */}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <TablePagination
                    component="div"
                    count={tariffs && tariffs.length}
                    onChangePage={handleChangePage}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[10, 20, 50, 100]}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </TableContainer>
        </>
    )
}