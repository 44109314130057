import React, { Component } from "react";
import ErrorPage from "./ErrorPage";

class ErrorHandler extends Component {
  constructor(props) {
    super(props);
    this.state = { errorOccurred: false };
  }

  static getDerivedStateFromError(error) {
    return { errorOccurred: true };
  }
  componentDidCatch(error, info) {
    this.setState({ errorOccurred: true });
  }

  render() {
    return this.state.errorOccurred ? <ErrorPage /> : this.props.children;
  }
}

export default ErrorHandler;
