import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";
import axiosInstance from "../../Api/Api.js";
import { useParams } from "react-router-dom";

function Alert(props) {
    return (
        <MuiAlert
            elevation={6}
            vertical="top"
            color="primary"
            variant="filled"
            {...props}
        />
    );
}
const useStyles = makeStyles((theme) => ({
    root: {
        "& > *": {
            margin: theme.spacing(1),
            width: "50ch",
        },
    },
}));

export default function EditCreate(props) {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [values, setValues] = useState({
        name: ""
    });

    const [open, setOpen] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);

    const [erorrMessage, setErrorMessage] = useState("");

    const params = useParams();

    const handleChange = (prop) => (event) => {
        event.preventDefault();
        setValues({ ...values, [prop]: event.target.value });
    };



    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };

    const fetchCategorytDetails = () => {
        setLoading(true);
        axiosInstance
            .get(`/category/detail/${params.rowId}`)
            .then(function (response) {
                if (response.status === 200) {
                    console.log(response.data.data);
                    const {
                        name,
                        description,
                        isRequired,
                        service_id,
                    } = response.data.data;

                    setValues({
                        ...values,
                        name,

                    });
                    console.log(response.data.data);
                } else {
                    console.log(response);
                }
            })
            .catch(function (error) {
                // console.log(error.response.data.message);
            })
            .finally(() => setLoading(false));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmitLoading(true);
        const { name } = values;
        const endpoint =
            params.rowId === "create" ? "/category/add" : "/category/update";
        const body =
            params.rowId === "create"
                ? {
                    name,

                }
                : {
                    category_id: params.rowId,
                    name

                };
        const method = params.rowId === "create" ? "post" : "put";
        axiosInstance[method](endpoint, body)
            .then(function (response) {
                if (response.status === 200) {
                    setErrorMessage(response.data.message);
                    setOpen(true);
                } else {
                    console.log(response);
                }
            })
            .catch(function (error) {
                console.log(error.response.data.message);
                const { message } = error.response.data;
                setErrorMessage(message);
                setOpen(true);
            })
            .finally(() => setSubmitLoading(false));
    };

    useEffect(() => {
        async function fetchAllData() {
            try {
                if (params.rowId !== "create") {
                    await fetchCategorytDetails();
                }
            } catch (error) {
                console.log(error);
            }
        }
        fetchAllData();
    }, []);

    return (
        <form className={classes.root} noValidate autoComplete="off">
            {/* <div style={{ fontWeight: "bold" }}>Create New Document</div> */}
            <div style={{ fontWeight: "bold" }}>
                {params.rowId === "create" ? "Create New Category" : "Edit Category"}
            </div>
            <TextField
                type="text"
                InputLabelProps={{
                    shrink: true,
                }}
                label="Category Name"
                value={values.name}
                onChange={handleChange("name")}
            />

            <div>
                <Button
                    variant="contained"
                    onClick={handleSubmit}
                    color="primary"
                    disabled={
                        !(
                            values.name

                        )
                    }
                >
                    {/* {submitLoading ? <CircularProgress color="secondary" /> : "Create Document"} */}
                    {submitLoading ? (
                        <CircularProgress color="secondary" />
                    ) : params.rowId === "create" ? (
                        "Create Category"
                    ) : (
                        "Update"
                    )}
                </Button>
            </div>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success">
                    {erorrMessage}
                </Alert>
            </Snackbar>
        </form>
    );
}
