import HomeIcon from '@material-ui/icons/Home';
import Homepage from 'pages/Homepage';
import ZmcDashboard from 'pages/ZmcDashboard/ZmcDashboard';
import AddBoxIcon from '@material-ui/icons/AddBox';
import CreateApplication from 'pages/StartRequest';
import { AccountCircle, AttachMoney, Autorenew, People, ViewListOutlined } from '@material-ui/icons';
import RoomIcon from '@material-ui/icons/Room';
import Tariffs from 'pages/TariffList';
import Renewals from 'pages/Renewals';
import ProfileUpdate from 'pages/ProfileUpdate';
import Users from 'pages/Users';
import TeamCreate from 'pages/TeamCreate';
import Teams from 'pages/Teams';
import Requests from 'pages/Request';
import Zones from 'pages/Zones';
import PromoterZones from 'pages/PromoterZones';
import PromoterProfile from 'pages/PromoterProfile';
import CompanyRequestDetials from 'pages/CompanyRequest/CompanyRequestDetails'

const getUserDetails = () => {
    return JSON.parse(localStorage.getItem('userDetails'));


}


export const zmcItems = [
    {
        path: '/me/zmc-dashboard',
        title: 'Dashboard',
        icon: HomeIcon,
        component: ZmcDashboard
    },

    {
        path: '/me/zmc-profile',
        title: 'Profile',
        icon: AccountCircle,
        component: ProfileUpdate,
    },
    {
        path: '/me/users',
        title: 'Users',
        icon: People,
        component: Users,
    },
    {
        path: '/me/teams',
        title: 'Directors',
        icon: People,
        component: Teams,
    },
    {
        path: '/me/team/create',
        title: 'TeamCreate',
        icon: People,
        component: TeamCreate,
        hidden: true
    },
    {
        path: '/me/zmc-zones',
        title: 'My Zones',
        icon: RoomIcon,
        component: Zones,
    },

]

export const partnerItems = [
    {
        path: '/me',
        title: 'Dashboard',
        icon: HomeIcon,
        component: Homepage
    },
    {
        path: '/me/request',
        title: 'Start an Application',
        icon: AddBoxIcon,
        component: CreateApplication,
    },
    {
        path: '/me/tariffs',
        title: 'Tariffs',
        icon: AttachMoney,
        component: Tariffs,
    },
    {
        path: '/me/zones',
        title: 'Zones',
        icon: RoomIcon,
        component: PromoterZones,
    },
    {
        path: '/me/requests',
        title: 'Requests',
        icon: ViewListOutlined,
        component: Requests,
    },
    // {
    //     path: '/me/renewals',
    //     title: 'Renewals',
    //     icon: Autorenew,
    //     component: Renewals,
    // },
    {
        path: '/me/profile',
        title: 'Profile',
        icon: AccountCircle,
        component: PromoterProfile
    },
    {
        path: '/me/users',
        title: 'Users',
        icon: People,
        component: Users,
    },


]



// const items = (getUserDetails()?.data?.company?.entity_type_id == 3) ? zmcItems : partnerItems;


export default {
    items: [...partnerItems, ...zmcItems]
}