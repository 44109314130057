import React, { useEffect, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { useHistory, Link } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import axiosInstance from "../../Api/Api.js";
import Swal from "sweetalert2";
import { green, grey } from "@material-ui/core/colors";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableFont: {
    // fontWeight: "bold",
    fontSize: "18px",
  },
  tableHeader: {
    paddingLeft: "0",
    fontSize: "18px",
    width: "100%",
    marginLeft: "10px",
  },
  headerContainer: {
    marginBottom: "10px"
  },
  active: {
    color: green['500'],
  },
  inactive: {
    color: grey['500'],
  }
});

export default function ProcessFlow() {
  const classes = useStyles();
  const history = useHistory();
  const [processFlows, setProcessFlows] = useState([]);
  const [loading, setLoading] = useState(false);


  const showDetails = (rowId) => {
    history.push(`/admin-account/process-flow/${rowId}/edit`);
  };

  const fetchProcessFlows = () => {
    setLoading(true)
    axiosInstance
      .get("approval_processflow/list")
      .then(function (response) {
        if (response.status === 200) {
          setProcessFlows(response.data.data.data);
          console.log(response, processFlows);
        } else {
          console.log(response);
        }
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire('Error', 'Something went wrong, please contact support', 'error')
      })
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    fetchProcessFlows();
  }, []);



  return loading ? (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      style={{ height: "100%" }}

    >
      <CircularProgress color="primary" />
    </Grid>
  ) : (!processFlows.length ?
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      style={{ height: "100%" }}
    >
      <Typography variant="h6">No Record found</Typography>
    </Grid> :
    <div>
      <Grid
        container
        direction="row"
        justify="space-between"
        className={classes.headerContainer}
        alignItems="flex-end"
      >
        <div><Typography className={classes.tableHeader}>Approval Process Flow</Typography></div>
        <Link to="/admin-account/process-flow/create">
          <Button variant="contained" color="primary">
            Add new
</Button>
        </Link>
      </Grid>
      <TableContainer component={Paper} variant="outlined">
        <Table className={classes.table} aria-label="data-table">
          <TableHead>
            <TableRow className={classes.tableFont}>
              <TableCell className={classes.tableFont}>Process Module</TableCell>
              <TableCell className={classes.tableFont}>Approval Flow Stages</TableCell>
              <TableCell className={classes.tableFont}>Status </TableCell>
              <TableCell className={classes.tableFont}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {processFlows.map((row) => (
              <TableRow key={row.ProcessFlow_id}>
                <TableCell component="th" scope="row">
                  {row.approval_processmodule.processmodule}
                </TableCell>
                <TableCell>{row.approval_stage.approval_stage}</TableCell>
                <TableCell><Typography className={row.status === 1 ? classes.active : classes.inactive}>{row.status === 1 ? 'Active' : 'Inactive'}</Typography></TableCell>



                <TableCell>
                  {" "}
                  <Link href="#" onClick={() => showDetails(row.ProcessFlow_id)}>
                    Edit
                  </Link>

                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
    );
}
