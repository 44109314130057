import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import RequestDetails from './RequestDetails';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import { useParams, useHistory } from 'react-router-dom';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { AlertTitle } from '@material-ui/lab';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';

import axiosInstance from '../../Api/Api.js';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    '& .makeStyles-paper-24': {
      color: 'rgba(0, 0, 0, 0.87)',
    },
    '& .makeStyles-paper-25': {
      color: 'rgba(0, 0, 0, 0.87)',
    },
    '& .makeStyles-paper-28': {
      color: 'rgba(0, 0, 0, 0.87)',
    },
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: 'rgba(0, 0, 0, 0.87)',
  },

  formRoot: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '100%',
    },
  },
  buttonal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
  },

  uploadLabel: {
    textAlign: 'left !important',
    marginLeft: '10px',
  },
  checkbox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

export default function ApprovalAction(props) {
  const classes = useStyles();

  const [values, setValues] = useState({
    approval_instance_id: '',
    comment: '',
    document_id: '',
  });
  const [applicationId, setApplicationId] = useState(null);
  const [requestDetails, setRequestDetails] = useState('');
  const [documentId, setDocumentId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [approvalLoading, setApprovalLoading] = useState(false);
  const [action, setAction] = useState('');
  const [applicationHistory, setApplicationHistory] = useState([]);
  const [approvalFile, setApprovalFile] = useState('');
  const [fileTitle, setFileTitle] = useState('');

  const params = useParams();

  const [approvalIntances, setApprovalIntances] = useState([]);
  const history = useHistory();

  const [checked, setChecked] = useState(false);

  const handleCheck = (event) => {
    setChecked(event.target.checked);
  };

  const handleChange = (prop) => (event) => {
    event.preventDefault();
    setValues({ ...values, [prop]: event.target.value });
    // console.log('Document selected with ID:', event, values);
    // setValues({ id: event.target.value });
  };

  const handleDocument = (prop) => (event) => {
    event.preventDefault();
    // setDocumentId({ ...documentId, [prop]: event.target.value });
    setDocumentId(event.target.value);

    setTimeout(() => {
      console.log('DOCUMENT ID::', documentId);
    }, 2000);
  };
  const handleFileChange = (e) => {
    setApprovalFile(e.target.files[0]);
  };

  const handleFileTitleChange = (e) => {
    setFileTitle(e.target.value);
  };

  const fetchAllApprovalInstances = () => {
    setLoading(true);

    axiosInstance
      .get('/approval_instances/list')
      .then(function (response) {
        if (response.status === 200) {
          setApprovalIntances(response.data.data);
          // console.log(approvalIntances);
          // console.log(props.match.params);
        } else {
          console.log(response);
        }
      })
      .catch(function (error) {
        // console.log(error.response.data.message);
      })
      .finally(() => setLoading(false));
  };

  const fetchRequestDetails = () => {
    setLoading(true);
    axiosInstance
      .get(`/application/get/${params.rowId}`)
      .then(function (response) {
        if (response.status === 200) {
          setApplicationHistory(response.data.data.history);
          setRequestDetails(response.data.data.application);
          console.log(response.data.data)
          setDocumentId(response.data.data.application_attachments);
        } else {
          console.log(response)
        }
      })
      .catch(function (error) {
        // console.log(error.response.data.message);
        console.log(error);
      })
      .finally(() => setLoading(false));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setApprovalLoading(true);
    const { approval_instance_id, comment } = values;
    const formData = new FormData();
    formData.append('approval_instance_id', approval_instance_id);
    formData.append('comment', comment);
    formData.append('replace_document', checked);
    if (checked) {
      formData.append('document_id', values.document_id);
    }
    formData.append('application_id', applicationId);
    if (approvalFile) {
      formData.append('file_title', fileTitle);
      formData.append('file', approvalFile);
    }

    axiosInstance
      .post('/application_approval/add', formData)
      .then(function (response) {
        if (response.status === 200) {
          console.log(response);
          if (requestDetails?.reached_final_approval) {
            return history.push('/admin-account/final-approval');
          }
          return history.push('/admin-account/action-submitted');
        } else {
          console.log(response);
        }
      })
      .catch(function (error) {
        const { message } = error.response.data;
        setErrorMessage(message);
        setOpen(true);
      })
      .finally(() => setApprovalLoading(false));
  };

  console.log();
  const [open, setOpen] = useState(false);
  const [erorrMessage, setErrorMessage] = useState('');

  const handleClick = () => {
    setOpen(true);
  };

  const getAdmin = () => {
    const userDetails = localStorage.getItem('userDetails');
    if (userDetails) {
      return JSON.parse(userDetails);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  console.log(requestDetails);

  useEffect(() => {
    setApplicationId(params.rowId);
    fetchRequestDetails();
    fetchAllApprovalInstances();
  }, []);

  return loading ? (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      style={{ height: '100%' }}
    >
      <CircularProgress color="secondary" />
    </Grid>
  ) : (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={8}>
          <Paper variant="outlined" className={classes.paper}>
            {requestDetails?.reached_final_approval && getAdmin()?.data?.role.role_id !== 11 ? (
              <Alert variant="standard" severity="info">
                <AlertTitle>
                  <strong>Final Approval</strong>
                </AlertTitle>
                Please ensure that this application meets all requirements
                before this final approval.
              </Alert>
            ) : null}
            <Typography variant="h6">
              APPLICATION DETAILS
              <Divider />
            </Typography>
            <RequestDetails
              requestDetails={requestDetails}
              applicationHistory={applicationHistory}
            />
          </Paper>
        </Grid>
        {getAdmin()?.data?.role.role_id !== 9 && getAdmin()?.data?.role.role_id !== 11 ? (
          <Grid item xs={12} sm={4} position="fixed">
            <Paper className={classes.paper}>
              <Typography variant="h6">
                {requestDetails?.reached_final_approval ? 'FINAL ' : ''}APPROVAL
                FORM
                {requestDetails?.reached_final_approval ? 'FINAL ' : ''}APPROVAL
                FORM
                <Divider />
              </Typography>
              <form className={classes.formRoot} noValidate autoComplete="off">
                <div>
                  {approvalIntances.length ? (
                    <TextField
                      id="standard-secondary"
                      label="Approval Action"
                      variant="outlined"
                      select
                      value={values.approval_instance_id}
                      onChange={handleChange('approval_instance_id')}
                    >
                      {approvalIntances.map((option) => (
                        <MenuItem
                          key={option.approval_instance_id}
                          value={option.approval_instance_id}
                        >
                          {option.approval_type === 'Approve' && requestDetails?.reached_final_approval ?
                            'Approve' : option.approval_type === 'Approve' &&
                              !requestDetails?.reached_final_approval ? 'Approve to Proceed' : option.approval_type}
                        </MenuItem>
                      ))}
                    </TextField>
                  ) : null}
                </div>

                <div className={classes.checkbox}>
                  <div>
                    <p>Document re-upload required?</p>
                  </div>

                  <Checkbox
                    onChange={handleCheck}
                    color="primary"
                    checked={checked}
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                  />
                </div>
                {checked ? (
                  <TextField
                    id="standard-secondary"
                    label="Documents"
                    variant="outlined"
                    select
                    value={values.document_id}
                    onChange={handleChange('document_id')}
                  >
                    {requestDetails?.application_attachments?.map((option) => (
                      <MenuItem
                        key={option.document.id}
                        value={option.document_id}
                      >
                        {option.document.name}
                      </MenuItem>
                    ))}
                  </TextField>
                ) : (
                  null
                  // <div>
                  //   <div>
                  //     <label
                  //       className={classes.uploadLabel}
                  //       htmlFor="filetitle"
                  //     >
                  //       File Title:
                  //     </label>
                  //     <TextField
                  //       id="outlined-secondary"
                  //       variant="outlined"
                  //       name="filetitle"
                  //       type="text"
                  //       onChange={handleFileTitleChange}
                  //     />
                  //     <label
                  //       className={classes.uploadLabel}
                  //       htmlFor="uploadfile"
                  //     >
                  //       Upload file(Optional):
                  //     </label>
                  //     <TextField
                  //       id="outlined-secondary"
                  //       variant="outlined"
                  //       name="uploadfile"
                  //       type="file"
                  //       onChange={handleFileChange}
                  //     />
                  //   </div>
                  // </div>
                )}

                <div>
                  <TextField
                    id="outlined-secondary"
                    label="Remarks"
                    variant="outlined"
                    multiline
                    rows={4}
                    value={values.remark}
                    onChange={handleChange('comment')}
                  />
                </div>
                <div className={classes.buttonal}>
                  <Button
                    align="right"
                    className={classes.margin}
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                  // disabled={!(values.approval_instance_id && values.comment)}
                  >
                    {approvalLoading ? <CircularProgress /> : 'Submit'}
                  </Button>
                </div>
              </form>
            </Paper>
          </Grid>
        ) : null}
      </Grid>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          {erorrMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}
