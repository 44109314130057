import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import axiosInstance from '../../Api/Api.js';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: '700px',
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
}));

export default function RequestInformationDialog({
  handleClose,
  setOpenDialog,
  handleClickOpen,
  informationId,
  open,
  setDialogAlert,
  documentId,
}) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState('');
  const [file, setFile] = useState('');

  const [alert, setAlert] = useState(false);
  const docId = localStorage.getItem('docuId')
  const handleCommentChange = (event) => {
    event.preventDefault();
    setComment(event.target.value);
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = (e) => {
    setLoading(true);
    const formData = new FormData();

    formData.append('comment', comment);
    formData.append('requested_information_id', informationId);

    if (file) {
      formData.append('file', file);
      formData.append('document_id', docId);
      formData.append('replace_document', true)
    } else {
      formData.append('replace_document', false)
    }
    // console.log(formData, comment, file);
    axiosInstance
      .post('/application_approval/conversation/add', formData)
      .then(function (response) {
        if (response.status === 200) {
          setDialogAlert('Successful');

          console.log(response);
          setOpenDialog(false);
        } else {
          console.log(response);
        }
      })
      .catch(function (error) {
        console.log(error?.response?.data?.message);
      })
      .finally(() => setLoading(false));
  };

  // const handleClick = () => {
  //   setAlert(true);
  // };

  const handleSuccessAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlert(false);
  };
  return (
    <div>
      <Snackbar
        open={alert}
        autoHideDuration={2000}
        onClose={handleSuccessAlert}
      >
        <Alert onClose={handleSuccessAlert} severity="success">
          Document sent Successfully
        </Alert>
      </Snackbar>

      <form className={classes.form} noValidate>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">ADD INFORMATION</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please provide the required information
            </DialogContentText>

            <TextField
              autoFocus
              margin="dense"
              id="name:"
              label="Note"
              multiline
              rows={4}
              type="text"
              onChange={handleCommentChange}
              fullWidth
            />
            {docId ?
              <>
                <div style={{ marginTop: '20px' }}>File Upload:</div>
                <input
                  onChange={handleFileChange}
                  margin="dense"
                  id="file"
                  label="File Upload"
                  type="file"
                  fullWidth
                />
              </> : null
            }

          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            {loading ? (
              <Button color="primary">Loading...</Button>
            ) : (
              <Button onClick={handleSubmit} color="primary">
                Submit
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </form>
    </div>
  );
}
