import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";
import axiosInstance from "../../Api/Api.js";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";


function Alert(props) {
  return (
    <MuiAlert
      elevation={6}
      vertical="top"
      color="primary"
      variant="filled"
      {...props}
    />
  );
}
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "50ch",
    },
  },
}));

export default function EditCreate(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    name: "",
    description: "",
    isRequired: "",
    service_id: "",
    id: "",
  });

  const [open, setOpen] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  const [erorrMessage, setErrorMessage] = useState("");
  const [serviceTypes, setServiceTypes] = useState([]);
  const history = useHistory();
  const params = useParams();

  const handleChange = (prop) => (event) => {
    event.preventDefault();
    setValues({ ...values, [prop]: event.target.value });
  };

  const fetchServiceName = () => {
    setLoading(true);

    axiosInstance
      .get("services/list")
      .then(function (response) {
        if (response.status === 200) {
          setServiceTypes(response.data.data);
        } else {
          console.log(response);
        }
      })
      .catch(function (error) {
        // console.log(error.response.data.message);
      })
      .finally(() => setLoading(false));
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const fetchDocumentDetails = () => {
    setLoading(true);
    axiosInstance
      .get(`/documents/get/${params.rowId}`)
      .then(function (response) {
        if (response.status === 200) {
          console.log(response.data.data);
          const {
            name,
            description,
            isRequired,
            service_id,
          } = response.data.data;

          setValues({
            ...values,
            name,
            description,
            isRequired,
            service_id,
          });
          console.log(response.data.data);
        } else {
          console.log(response);
        }
      })
      .catch(function (error) {
        // console.log(error.response.data.message);
      })
      .finally(() => setLoading(false));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitLoading(true);
    const { name, description, isRequired, service_id } = values;
    const endpoint =
      params.rowId === "create" ? "/documents/add" : "/documents/update";
    const body =
      params.rowId === "create"
        ? {
          name,
          description,
          isRequired,
          service_id,
        }
        : {
          document_id: params.rowId,
          name,
          description,
          isRequired,
          service_id,
        };
    const method = params.rowId === "create" ? "post" : "put";
    axiosInstance[method](endpoint, body)
      .then(function (response) {
        if (response.status === 200) {
          setErrorMessage(response.data.message);
          history.push("/admin-account/document");
          setOpen(true);
        } else {
          console.log(response);
        }
      })
      .catch(function (error) {
        console.log(error.response.data.message);
        const { message } = error.response.data;
        setErrorMessage(message);
        setOpen(true);
      })
      .finally(() => setSubmitLoading(false));
  };

  useEffect(() => {
    async function fetchAllData() {
      try {
        await fetchServiceName();
        if (params.rowId !== "create") {
          await fetchDocumentDetails();
        }
      } catch (error) {
        console.log(error);
      }
    }
    fetchAllData();
  }, []);

  return (
    <form className={classes.root} noValidate autoComplete="off">
      {/* <div style={{ fontWeight: "bold" }}>Create New Document</div> */}
      <div style={{ fontWeight: "bold" }}>
        {params.rowId === "create" ? "Create New Document" : "Edit Document"}
      </div>
      <TextField
        type="text"
        InputLabelProps={{
          shrink: true,
        }}
        label="Document Name"
        value={values.name}
        onChange={handleChange("name")}
      />
      <TextField
        InputLabelProps={{
          shrink: true,
        }}
        label="description"
        value={values.description}
        onChange={handleChange("description")}
      />

      <TextField
        select
        InputLabelProps={{
          shrink: true,
        }}
        label="Required"
        value={values.isRequired}
        onChange={handleChange("isRequired")}
      >
        <MenuItem key="1" value="1">
          yes
        </MenuItem>
        <MenuItem key="0" value="0">
          No
        </MenuItem>
      </TextField>
      <TextField
        select
        InputLabelProps={{
          shrink: true,
        }}
        label="Service ID"
        value={values.service_id}
        onChange={handleChange("service_id")}
      >
        {serviceTypes.map((option, index) => (
          <MenuItem key={option.id} value={option.id}>
            {option.name}
          </MenuItem>
        ))}
      </TextField>

      <div>
        <Button
          variant="contained"
          onClick={handleSubmit}
          color="primary"
          disabled={
            !(
              values.name &&
              values.description &&
              values.service_id &&
              values.isRequired
            )
          }
        >
          {/* {submitLoading ? <CircularProgress color="secondary" /> : "Create Document"} */}
          {submitLoading ? (
            <CircularProgress color="secondary" />
          ) : params.rowId === "create" ? (
            "Create Document"
          ) : (
            "Update"
          )}
        </Button>
      </div>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          {erorrMessage}
        </Alert>
      </Snackbar>
    </form>
  );
}
