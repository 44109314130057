import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Tabs, Tab, Divider, colors, Paper, Typography } from '@material-ui/core';
import AdminZonesList from './AdminZonesList';


const useStyles = makeStyles(theme => ({
    root: {
        padding: '3rem'
    },
    tabs: {
        marginTop: '3rem'
    },
    divider: {
        backgroundColor: colors.grey[300]
    },
    content: {
        margin: '3rem'
    },
    heading: {
        textAlign: 'center',
        paddingTop: 32
    }
}));

export default function AdminZones({ history, match }) {
    const classes = useStyles();
    const [tab, setTab] = useState('all');
    const handleTabsChange = (event, value) => {
        setTab(value);
    };

    const tabs = [
        { value: 'all', label: 'All' },
        { value: 'pending', label: 'Pending' },
        { value: 'approved', label: 'Approved' },
    ];

    if (!tab) {
        return <Redirect to={'/admin-account'} />;
    }

    return (
        <>
            <Typography variant="h4" color="textSecondary" className={classes.heading}>Zones</Typography>
            <Paper>
                <Tabs
                    className={classes.tabs}
                    onChange={handleTabsChange}
                    scrollButtons="auto"
                    value={tab}
                    variant="scrollable">
                    {tabs.map(tab => (
                        <Tab key={tab.value} label={tab.label} value={tab.value} />
                    ))}
                </Tabs>
                <Divider className={classes.divider} />
                <div className={classes.content}>
                    {tab === 'all' && <AdminZonesList type={''} />}
                    {tab === 'pending' && <AdminZonesList type={'-pending'} />}
                    {tab === 'approved' && <AdminZonesList type={'-approved'} />}
                </div>
            </Paper>
        </>
    );
};




