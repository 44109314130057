/* eslint-disable no-unused-vars */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import Swal from "sweetalert2";
import { MenuItem, InputAdornment } from "@material-ui/core";
import { AppConfig } from "../app-config";
import Header from "./SigninHeader";
import { useHistory } from "react-router-dom";
import MuiPhoneNumber from "material-ui-phone-number";
import Popover from '@material-ui/core/Popover';
import InfoIcon from '@material-ui/icons/Info';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  container: {
    display: "flex",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    minHeight: "45vh",
    height: "auto",
    width: "70%",
    minWidth: "300px",
    margin: "1rem",
    // verticalAlign: "middle",
  },
  form: {
    width: "100%",
    display: "flex",
    height: "auto",
    alignItems: "center",
    justifyContent: "center",
    padding: "2rem 0",
    flexDirection: "column",
  },
  loginLink: {
    color: "gray",
    marginTop: "20px",
  },
  text: {
    width: "100%",
    marginBottom: "1.4rem",
  },
  button: {
    marginTop: "1rem",
  },
  inline: {
    marginLeft: "1rem",
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
    boxShadow: "none",
    marginRight: "1rem",
  },
  spinner: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "1rem",
    height: "100%",
  },
  textDiv: {
    width: "90%",
    // marginBottom: "1.4rem",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  textField: {
    width: "48%",
  },
  textFieldLift: {
    width: "48%",
    position: "relative",
    bottom: 4
  },
  adornment: {
    color: "grey",
    position: "relative",
    left: -45,
    bottom: -5
  },
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
  },
  info: {
    position: "relative",
    top: -20,
    left: -20
  }
}));

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

export default function CreateCompany() {
  const [Company, setCompany] = React.useState({
    name: "",
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    address: "",
    rc_no: "",
    entity_type_id: "",
    username: "",
    password: "",
  });
  const [error, setError] = React.useState({
    name: "",
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    address: "",
    rc_no: "",
    entity_type_id: "",
    username: "",
    password: "",
  });

  const [loading, setLoading] = React.useState(false);
  const [alert, showAlert] = React.useState(false);
  const [entities, setEntities] = React.useState([]);
  const [isDisabled, setIsDisabled] = React.useState(true)
  const [entityName, setEntityName] = React.useState('')
  const [showTag, setShowTag] = React.useState(false)
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleChange = (event) => {
    setError({ ...error, [event.target.name]: "" });
    setCompany({
      ...Company,
      [event.target.name]: event.target.value,
    });
  };

  const handleEntityChange = (event) => {
    setError({ ...error, [event.target.name]: "" });
    if (event.target.value) setIsDisabled(false)
    if (event.target.value === 3) {
      setShowTag(true)
    } else setShowTag(false)
    setCompany({
      ...Company,
      [event.target.name]: event.target.value,
    });
  };

  const handleNameChange = (event) => {
    setError({ ...error, [event.target.name]: "" });
    setEntityName(`${event.target.value}`)
    setCompany({
      ...Company,
      [event.target.name]: event.target.value,
    });
  };
  const handlePhoneChange = (value) => {
    setError({ ...error, phone: "" });
    setCompany({
      ...Company,
      phone: value,
    });
  };

  const history = useHistory();


  const handleBlurField = () => {
    if (Company.name) {
      let rawName = Company.name
      let parsedName = rawName.replace(/\s/g, '').toLocaleLowerCase()
      if (parsedName[parsedName.length - 1] === "c" && parsedName[parsedName.length - 2] === "z" && parsedName[parsedName.length - 3] === "f") {
        console.log("")
      } else {
        setCompany({
          ...Company,
          name: `${Company.name} FZC`,
        });
        // return setError({ ...error, name: "Add FZC suffix to your company name" });
      }
    }

  }

  const handleSubmit = async () => {

    const {
      name,
      first_name,
      last_name,
      email,
      phone,
      address,
      rc_no,
      entity_type_id,
      username,
      password,
    } = Company;

    if (!name) {
      return setError({ ...error, name: "Please provide a company name" });
    }
    if (!first_name) {
      return setError({ ...error, first_name: "Please provide a First name" });
    }
    if (!last_name) {
      return setError({ ...error, last_name: "Please provide a Last name" });
    }
    if (!email) {
      return setError({ ...error, email: "Please provide a Email" });
    }
    if (phone?.length < 6) {
      return setError({ ...error, phone: "Please provide a valid Phone" });
    }
    if (!address) {
      return setError({ ...error, address: "Please provide a Address" });
    }
    // if (!rc_no) {
    //   return setError({ ...error, rc_no: "Please provide a Rc no" });
    // }
    if (!username) {
      return setError({ ...error, username: "Please provide a username" });
    }
    if (!password) {
      return setError({ ...error, password: "Please provide a password" });
    }
    if (!entity_type_id) {
      return setError({
        ...error,
        entity_type_id: "Please provide a Entity type id",
      });
    }

    // if (phone.length > 11) {
    //   return setError({ ...error, phone: "Phone number must be 11 digits" });
    // }
    let body = {}
    rc_no ? body = {
      name,
      first_name,
      last_name,
      email,
      phone,
      address,
      rc_no,
      entity_type_id,
      username,
      password,
    } :
      body = {
        name,
        first_name,
        last_name,
        email,
        phone,
        address,
        entity_type_id,
        username,
        password,
      }
    try {
      setLoading(true);

      let reqMethod = "POST";
      let url = AppConfig.URL + "/company/register";

      const { status, message } = await fetch(url, {
        method: reqMethod,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + sessionStorage["token"],
        },
        body: JSON.stringify(body),
      }).then((res) => res.json());
      if (status) {
        setLoading(false);
        showAlert(true);
        setCompany({
          name: "",
          first_name: "",
          last_name: "",
          email: "",
          phone: "",
          address: "",
          rc_no: "",
          entity_type_id: "",
        });
        history.push('/login')
        setTimeout(() => {
          closeAlert();
        }, 1000);
        // } 
        // else if (!window.navigator.online) {
        //   Swal.fire("Internet", "Check your connection", "error");
      } else {
        Swal.fire("", message, "info");
        setLoading(false);
      }
    } catch (err) {
      Swal.fire("Something went wrong", "Unable to register company", "info");
      setLoading(false);
    }
  };

  const getEntities = async () => {
    try {
      const response = await fetch(`${AppConfig.URL}/entity_types/list`, {
        method: "GET",
      });
      const { status, data, message } = await response.json();
      if (status === true) {
        setEntities(data.data);
      } else {
        Swal.fire("", message, "info");
      }
    } catch (error) {
      Swal.fire("Something went wrong", "Unable to get entity types", "error");
    }
  };

  React.useEffect(() => {
    getEntities();
  }, []);

  const closeAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    showAlert(false);
  };

  return (
    <div>
      <Header />
      <Snackbar
        open={alert}
        autoHideDuration={2000}
        onClose={closeAlert}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={closeAlert} severity="success">
          Company Created Successfully!
        </Alert>
      </Snackbar>

      <div className={classes.container}>
        <Paper elevation={0} className={classes.paper}>
          <form className={classes.form} noValidate autoComplete="off">
            <Typography variant="h4">Register Here</Typography>
            <h3 style={{ marginTop: '25px' }}>Personal Information</h3>
            <div className={classes.textDiv}>
              <TextField
                label="First name"
                name="first_name"
                error={!!error.first_name}
                id={
                  !error.first_name
                    ? "outlined-basic"
                    : "outlined-error-helper-text"
                }
                required
                className={classes.textField}
                helperText={error.first_name ? error.first_name : ""}
                margin="normal"
                type="text"
                variant="outlined"
                onChange={(e) => handleChange(e)}
              />
              <TextField
                label="Last name"
                name="last_name"
                error={!!error.last_name}
                id={
                  !error.last_name
                    ? "outlined-basic"
                    : "outlined-error-helper-text"
                }
                required
                className={classes.textField}
                helperText={error.last_name ? error.last_name : ""}
                margin="normal"
                type="text"
                variant="outlined"
                onChange={(e) => handleChange(e)}
              />
            </div>

            <div className={classes.textDiv}>
              <TextField
                label="Email"
                name="email"
                error={!!error.email}
                id={
                  !error.email ? "outlined-basic" : "outlined-error-helper-text"
                }
                required
                className={classes.textField}
                helperText={error.email ? error.email : ""}
                margin="normal"
                type="text"
                variant="outlined"
                onChange={(e) => handleChange(e)}
              />
              <MuiPhoneNumber
                label="Phone"
                name="phone"
                defaultCountry={"ng"}
                error={!!error.phone}
                id={
                  !error.phone ? "outlined-basic" : "outlined-error-helper-text"
                }
                required
                className={classes.textField}
                helperText={error.phone ? error.phone : ""}
                margin="normal"
                type="text"
                value={Company?.phone}
                variant="outlined"
                onChange={handlePhoneChange}
              />
            </div>

            <h3 style={{ marginTop: '25px' }}>Company Information</h3>

            <div className={classes.textDiv}>
              <TextField
                id={
                  !error.entity_type_id
                    ? "outlined-basic"
                    : "outlined-error-helper-text"
                }
                error={!!error.entity_type_id}
                select
                variant="outlined"
                label="Entity type"
                name="entity_type_id"
                value={Company.entity_type_id}
                onChange={(e) => handleEntityChange(e)}
                className={classes.textField}
                helperText={error.entity_type_id ? error.entity_type_id : ""}
              >
                {entities.map((option) => (
                  <MenuItem
                    key={option.entity_type_id}
                    value={option.entity_type_id}
                  >
                    {option.entity_type}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                label="Entity Name"
                name="name"
                error={!!error.name}
                id={
                  !error.name ? "outlined-basic" : "outlined-error-helper-text"
                }
                required
                className={classes.textFieldLift}
                helperText={error.name ? error.name : ""}
                margin="normal"
                type="text"
                variant="outlined"
                disabled={isDisabled}
                value={entityName}
                InputProps={{
                  endAdornment: <InputAdornment position="end">{showTag ? "FZC" : null}</InputAdornment>,
                }}
                onBlur={(e) => handleBlurField(e)}
                onChange={(e) => handleNameChange(e)}

              />
              {/* {showTag ?
                <div className={classes.info}>
                  <Typography
                    aria-owns={open ? 'mouse-over-popover' : undefined}
                    aria-haspopup="true"
                    onMouseEnter={handlePopoverOpen}
                    onMouseLeave={handlePopoverClose}
                  >
                    <InfoIcon fontSize="small" color="primary" />
                  </Typography>
                  <Popover
                    id="mouse-over-popover"
                    className={classes.popover}
                    classes={{
                      paper: classes.paper,
                    }}
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    onClose={handlePopoverClose}
                    disableRestoreFocus
                  >
                    <Typography>ZMC names must have a suffix of FZC <br /> e.g Alpha Managers FZC</Typography>
                  </Popover>
                </div> : null
              } */}

            </div>

            <div className={classes.textDiv}>
              <TextField
                label="Address"
                name="address"
                error={!!error.address}
                id={
                  !error.address
                    ? "outlined-basic"
                    : "outlined-error-helper-text"
                }
                required
                className={classes.textField}
                helperText={error.address ? error.address : ""}
                margin="normal"
                type="text"
                variant="outlined"
                onChange={(e) => handleChange(e)}
              />
              <TextField
                label="RC number"
                name="rc_no"
                error={!!error.rc_no}
                id={
                  !error.rc_no ? "outlined-basic" : "outlined-error-helper-text"
                }
                className={classes.textField}
                helperText={error.rc_no ? error.rc_no : ""}
                margin="normal"
                type="text"
                variant="outlined"
                onChange={(e) => handleChange(e)}
              />

            </div>

            <div className={classes.textDiv}>
              <TextField
                label="Username"
                name="username"
                error={!!error.username}
                id={
                  !error.username
                    ? "outlined-basic"
                    : "outlined-error-helper-text"
                }
                required
                className={classes.textField}
                helperText={error.username ? error.username : ""}
                margin="normal"
                type="text"
                variant="outlined"
                onChange={(e) => handleChange(e)}
              />
              <TextField
                label="Password"
                name="password"
                error={!!error.password}
                id={
                  !error.password
                    ? "outlined-basic"
                    : "outlined-error-helper-text"
                }
                required
                className={classes.textField}
                helperText={error.password ? error.password : ""}
                margin="normal"
                type="password"
                variant="outlined"
                onChange={(e) => handleChange(e)}
              />
            </div>

            <div className={classes.textDiv}>
              <Button
                // autoFocus
                variant="outlined"
                color="primary"
                onClick={handleSubmit}
                className={classes.button}
                size="large"
                disabled={loading}
              >
                Submit
              </Button>
            </div>
            <p
              className={classes.loginLink}
              tw="mt-8 text-sm text-gray-400 text-center"
            >
              Already have an account?{" "}
              <a href={"/login"} tw="border-b border-gray-500 border-dotted">
                Sign In
              </a>
            </p>
          </form>
          {loading && (
            <div className={classes.spinner}>
              <CircularProgress />
              <Typography
                component="span"
                variant="body2"
                className={classes.inline}
                color="initial"
              >
                Submiting Company...
              </Typography>
            </div>
          )}
        </Paper>
      </div>
    </div >
  );
}
