import React from "react";
import tw from "twin.macro";
import HeaderBase, {
  LogoLink as LogoLinkBase,
  NavLinks,
  NavLink as NavLinkBase,
  PrimaryLink as PrimaryLinkBase,
} from "../components/headers/light.js";
import { Content2Xl } from "components/misc/Layouts.js";
import logoImageSrc from "images/NEPZA.jpg";
import NepzaLogo from "images/Nepzalogo.png";
import NepzaImage from "images/Nepzaimage.png";

const PrimaryBackgroundContainer = tw.div`-mx-8 px-8 shadow text-gray-800`;
const Header = tw(HeaderBase)`max-w-none  py-2  px-8`;
const NavLink = tw(
  NavLinkBase
)`lg:text-gray-700 lg:hocus:text-gray-900 lg:hocus:border-gray-900`;
const LogoLink = tw(LogoLinkBase)`text-gray-100 hocus:text-gray-300`;
const PrimaryLink = tw(
  PrimaryLinkBase
)`shadow-raised lg:bg-green-500 shadow-md lg:hocus:bg-green-600`;

export default () => {
  const logoLink = (
    <LogoLink href="/">
      <img src={NepzaImage} alt="NepzaLogo" />
    </LogoLink>
  );
  const navLinks = [
    <NavLinks key={1}>
      <NavLink href="#services">Services</NavLink>
      <NavLink href="#pricing">Tariffs</NavLink>
      <NavLink href="/login">Login</NavLink>
      <PrimaryLink href="/register">Register</PrimaryLink>
    </NavLinks>,
  ];
  return (
    <PrimaryBackgroundContainer>
      <Content2Xl>
        <Header logoLink={logoLink} links={navLinks} />
      </Content2Xl>
    </PrimaryBackgroundContainer>
  );
};
