import React, { useState, useEffect } from 'react'
import { DropzoneArea } from 'material-ui-dropzone';
import { Avatar, Box, Button, CircularProgress, Grid, IconButton, LinearProgress, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, makeStyles, MenuItem, Snackbar, TextField, Typography } from '@material-ui/core';
import { CancelOutlined, } from '@material-ui/icons';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Swal from 'sweetalert2';
import { AppConfig } from "../../app-config";
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import Alert from '@material-ui/lab/Alert';
import TabPanel from '@material-ui/lab/TabPanel';


const useStyles = makeStyles((theme) => ({
    select: {
        width: "100%",
        marginBottom: "1.4rem"
    },
    button: {
        marginTop: "1rem"
    },
    inline: {
        marginLeft: "1rem"
    },
    large: {
        width: theme.spacing(12),
        height: theme.spacing(12),
        marginRight: "1rem"
    },
    spinner: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "1rem",
        height: "100%"
    },
    fileTitle: {
        color: "blue",
    },
    download: {
        marginBottom: '20px',
        color: 'blue'
    },
    container: {
        margin: '1rem',
        // display: 'block',
        justifyContent: 'center',
        // alignItems: 'center'
    },
    list: {
        maxHeight: '270px',
        overflowY: 'auto'
    },
    caption: {
        color: "red",
        marginBottom: 16,
        // margin: '0 auto',

    },
    textField: {
        width: "100%",
    },
}))
export default function DocumentsUpload({ reqBody }) {
    const [files, setFiles] = useState([]);
    const [singleFile, setSingleFile] = useState({})
    const [uploadedFiles, setUploadedFiles] = useState([])
    const [progress, setProgress] = useState(0);
    const [docId, setDocId] = useState('');
    const [docTypes, setDocTypes] = useState([]);
    const [uploadedDocs, setUploadedDocs] = useState([]);
    const [loading, setLoading] = useState(false);
    const [alert, showAlert] = useState(false);
    const classes = useStyles();
    const [duplicateAlert, showDuplicateAlert] = useState(false)
    const [uploading, setUploading] = useState(false)

    const setDocumentId = (id) => {
        // const found = files.find((item) => item?.id == id);
        // if (!found) {
        setDocId(id)
        // }
    }

    const checkUploadedFiles = () => {
        const user = localStorage.getItem('userDetails') ? JSON.parse(localStorage.getItem('userDetails')) : null;
        if (user) {
            var fileIds = []
            if (user.data.company.zmc_profile.documents.length > 0) {
                user.data.company.zmc_profile.documents.forEach(element => {
                    fileIds = [...fileIds, element.id]
                });
            }
            setUploadedDocs(fileIds)
        }
    }
    const getDocTypes = async () => {
        setLoading(true)
        var userToken = localStorage.getItem('userDetails') ? JSON.parse(localStorage.getItem('userDetails')).access_token : null
        try {
            const response = await fetch(`${AppConfig.URL}/zmc/required-documents`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            });
            const { status, data, message } = await response.json();
            if (status === true) {
                setDocTypes(data);
            } else {
                Swal.fire('', message, 'info');
            }
            setLoading(false)
        } catch (error) {
            console.log(error);
            Swal.fire('Something went wrong', 'Unable to load record', 'error');
            setLoading(false)
        }
    };

    const getDocTitle = (id) => {
        const doc = docTypes?.find((item) => id == item.id)
        return doc.name;
    }

    const handleRemove = (id) => {
        const filteredFiles = files.filter(file => file.id !== id);
        setFiles(filteredFiles);
    }

    useEffect(() => {
        console.log(singleFile)
        handleShow()
        handleSingleSubmit()
    }, [singleFile,])


    const handleShow = () => {
        console.log(files)
        console.log(singleFile)
    }

    const handleSingleSubmit = async () => {
        console.log(singleFile);
        if (singleFile.upload === undefined) {
            return
        }

        // const mutatedFiles = [...files];

        const formData = new FormData();
        // if (files.length !== 9) {
        //     Swal.fire("Please upload all 9 required documents");
        //     return
        // }

        // for (let i = 0; i < files.length; i++) {
        formData.append(`zmc_required_document_id`, singleFile.id);
        formData.append(`file_title`, singleFile.docTitle);
        formData.append(`file`, singleFile.upload);
        // mutatedFiles[i].file = formData;
        // }
        try {
            setUploading(true);
            // setLoading(true);

            let reqMethod = "POST";
            let url = `${AppConfig.URL}/zmc/upload-document`;
            var userToken = localStorage.getItem('userDetails') ? JSON.parse(localStorage.getItem('userDetails')).access_token : null

            const { status, data, message } = await fetch(url, {
                method: reqMethod,
                headers: {
                    Authorization: `Bearer ${userToken}`,
                    Accept: "application/json*",
                },
                body: formData
            }).then((res) => res.json());
            if (status) {
                console.log(data)
                setUploadedFiles(uploadedFiles => {
                    const copyFiles = [...uploadedFiles]
                    let i = copyFiles.find(item => item.id === data.id)
                    if (i !== undefined) {
                        copyFiles.splice(i, 1)
                    }
                    setUploading(false)
                    return [...copyFiles, data]
                    // const newFiles = [...uploadedFiles, data]
                    // return newFiles
                })
                console.log(uploadedFiles)
                // let userDetails = JSON.parse(localStorage.getItem('userDetails'));
                // userDetails.data.company.zmc_profile.documents = data;
                // localStorage.setItem('userDetails', JSON.stringify(userDetails));
                // setLoading(false);
                // showAlert(true);
                // window.location.reload()
            } else {
                // Swal.fire("Something went wrong", message, "info");
                // setLoading(false);
                setUploading(false)
                console.log(message)
            }
        } catch (err) {
            setUploading(false)
            console.log(err)
            // Swal.fire("Something went wrong", "Unable to process request", "info");
            // setLoading(false);
        }
    };

    const handleSubmit = async () => {
        // console.log(files);


        // const formData = new FormData();
        // formData.append(`zmc_required_document_id`, singleFile.id);
        // formData.append(`file_title`, singleFile.docTitle);
        // formData.append(`file`, singleFile.upload);


        // console.log(formData);
        try {
            setLoading(true);

            let reqMethod = "GET";
            let url = `${AppConfig.URL}/zmc/finalize-uploads`;
            var userToken = localStorage.getItem('userDetails') ? JSON.parse(localStorage.getItem('userDetails')).access_token : null

            const { status, data, message } = await fetch(url, {
                method: reqMethod,
                headers: {
                    Authorization: `Bearer ${userToken}`,
                    // Accept: "application/json*",
                },
                // body: formData
            }).then((res) => res.json());
            if (status) {
                console.log(data)
                let userDetails = JSON.parse(localStorage.getItem('userDetails'));
                userDetails.data.company.zmc_profile.documents = data;
                localStorage.setItem('userDetails', JSON.stringify(userDetails));
                setLoading(false);
                showAlert(true);
                window.location.reload()
            } else {
                setDocTypes(data)
                // console.log(data)
                // console.log(message)
                Swal.fire(message);
                setLoading(false);
            }
        } catch (err) {

            Swal.fire("Something went wrong", "Unable to process request", "info");
            setLoading(false);
        }
    };

    React.useEffect(() => {
        checkUploadedFiles()
        getDocTypes()
    }, []);
    const closeAlert = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        showAlert(false);
    };

    if (loading) {
        return (
            <div className={classes.spinner}>
                <CircularProgress />
            </div>
        )
    }

    return (
        <TabPanel value="files">
            <Typography variant="caption" className={classes.caption}>
                Kindly upload all listed documents.
            </Typography>
            <Snackbar
                open={alert}
                autoHideDuration={2000}
                onClose={closeAlert}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
                <Alert onClose={closeAlert} severity="success">
                    {docTypes.length > 1 ? 'Documents uploaded successfully!' : 'Document uploaded successfully!'}
                </Alert>
            </Snackbar>
            <Snackbar
                open={duplicateAlert}
                autoHideDuration={2000}
                onClose={() => showDuplicateAlert(false)}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
                <Alert onClose={closeAlert} severity="warning">
                    File already uploaded. To re-upload the same file please delete the previous file and re-upload
                </Alert>
            </Snackbar>
            <Grid container direction="row" justify="center" alignItems="center" spacing={6}>
                <Grid item xs={12} sm={6} className={classes.container}>
                    <TextField
                        id="outlined-error-helper-text"
                        select
                        variant="outlined"
                        label="Document Type"
                        name="docId"
                        value={docId}
                        className={classes.select}
                        onChange={(e) => setDocumentId(e.target.value)}
                    >
                        {/* {docTypes.map((option) => uploadedDocs.includes(option.id) ? '' : ( */}
                        {docTypes.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                                {option.name}
                            </MenuItem>
                        ))}
                    </TextField>


                    {docId && <DropzoneArea
                        onDrop={(file) => {
                            const reader = new FileReader();
                            reader.onprogress = function (e) {
                                setProgress(parseInt(Math.round((e.loaded * 100) / e.total)))
                            };
                            files && reader.readAsBinaryString(file[0]);

                            reader.onloadend = () => {
                                const found = files.find((item) => item?.id == docId);

                                // if (found) {
                                //     return showDuplicateAlert(true)
                                // }

                                // file[0] && setSingleFile(files => {

                                //     const fileValue = { path: file[0].path, upload: file[0], id: docId, docTitle: getDocTitle(docId) }
                                //     console.log(fileValue)
                                //     return fileValue
                                // });
                                file[0] && setSingleFile({
                                    path: file[0].path,
                                    upload: file[0],
                                    id: docId,
                                    docTitle: getDocTitle(docId)
                                });


                                file[0] && setFiles(files => {

                                    // const temp = Object.freeze({ path: file[0].path, upload: file[0], id: docId, docTitle: getDocTitle(docId) });

                                    // return [...files, { ...temp }]

                                    const fileValues = [...files, { path: file[0].path, upload: file[0], id: docId, docTitle: getDocTitle(docId) }]
                                    console.log(fileValues)
                                    return fileValues
                                    // return [...files, { path: file[0].path, upload: file[0], id: docId, docTitle: getDocTitle(docId) }]
                                }
                                );
                            }
                            // files && console.log(reader.readAsArrayBuffer(file[0]));

                        }}
                        maxFileSize={200000000}
                        filesLimit={50}
                        showPreviewsInDropzone={false}
                        showAlerts={['error', 'info']}
                    />}
                </Grid>

                {docId && <Grid item xs={12} sm={6}>
                    <List className={classes.list}>
                        {/* {files.map(file => (
                            <ListItem key={file.id}>
                                <ListItemAvatar>
                                    <Avatar>
                                        <PictureAsPdfIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText>
                                    {file.path}
                                    <Typography className={classes.fileTitle}>{file.docTitle}</Typography>
                                    <Box display="flex" alignItems="center">
                                        <Box width="100%" mr={1}>
                                            <LinearProgress variant="determinate" value={progress} />
                                        </Box>
                                        <Box minWidth={35}>
                                            <Typography variant="body2" color="textSecondary">{`${progress}%`}</Typography>
                                        </Box>
                                    </Box>
                                </ListItemText>
                                <ListItemSecondaryAction>
                                    <IconButton edge="end" aria-label="delete" onClick={() => handleRemove(file.id)}>
                                        <CheckCircleOutlineIcon />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        ))} */}
                        {uploadedFiles && <Typography variant="h5">Uploaded Documents:</Typography>}
                        {uploadedFiles.map((file, index) => (
                            <ListItem key={file.file_title + index}>
                                <ListItemAvatar>
                                    <Avatar>
                                        <PictureAsPdfIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText>
                                    {/* {file.upload_url} */}
                                    <Typography className={classes.fileTitle}>{file.file_title}</Typography>
                                    <Box display="flex" alignItems="center">
                                        <Box width="100%" mr={1}>
                                            <LinearProgress variant="determinate" value={progress} />
                                        </Box>
                                        <Box minWidth={35}>
                                            <Typography variant="body2" color="textSecondary">{`${progress}%`}</Typography>
                                        </Box>
                                    </Box>
                                </ListItemText>
                                <ListItemSecondaryAction>
                                    <IconButton edge="end" aria-label="delete">
                                        <CheckCircleOutlineIcon />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        ))}
                    </List>
                </Grid>}
            </Grid>
            {docId &&
                (loading ? <CircularProgress /> : <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleSubmit}
                    className={classes.button}
                    size="large"
                    disabled={loading || uploading}
                >
                    Proceed
            </Button>)}
        </TabPanel>
    )
}