import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  CircularProgress,
  Typography,
  Card,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';
import Swal from 'sweetalert2';
import { AppConfig } from 'app-config';
import { Link as RouterLink } from 'react-router-dom';
import logo from 'images/Nepzaimage.png';
import tw from 'twin.macro';
import 'styles/receipt.css';

const LogoImage = tw.img`h-12  my-10`;
const useStyles = makeStyles((theme) => ({
  // const useStyles = makeStyles({
  spinner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '12rem',
    flexDirection: 'column',
  },
  cardHeader: {
    display: 'flex',
    marginTop: '-30px',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid #424242',
    marginBottom: '1.3rem',
  },
  button: {
    margin: '1rem',
    backgroundColor: '#68d391',
    color: 'white',
    fontSize: '12px',
    width: '15rem',
    padding: '8px',
    borderRadius: '8px',
    transition: theme.transitions.create(
      ["background", "background-color", "color"],
      {
        duration: theme.transitions.duration.complex
      }
    ),
    "&:hover": {
      backgroundColor: "green",
      color: "white"
    }
  },
  payer_information: {
    width: '100%',
    display: 'flex',
    padding: '5px',
    flexDirection: 'column',
    marginBottom: '2rem',
  },

  infoDiv: {
    lineHeight: '1.7',
  },
  infoHeader: {
    borderRadius: '5px',
    width: '100%',
    height: '36px',
    padding: '9px',
    paddingLeft: '15px',
    backgroundColor: '#005A00',
    fontSize: '16px',
    color: '#fff',
  },
  infoPara: {
    marginTop: '15px',
    paddingLeft: '10px',
    marginBottom: '7px',
    fontSize: '16px',
    lineHeight: '19px',
    fontWeight: 'normal',
    color: '#000000',
  },
  separateProperties: {
    display: 'flex',
    width: '100%',
    height: '50px',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: 8
  },

  reciepient_num: {
    fontWeight: 'bold',
    fontSize: '10px',
    lineHeight: '18px',
  },
  payer: {
    fontSize: '20px',
    color: '#000000',
    textTransform: 'uppercase',
  },
  Billers_info: {
    width: '100%',
    paddingTop: '3rem',
  },
  contact: {
    width: '100%',
    marginTop: '2.5rem',
    color: '#black',
  },
  support: {
    marginTop: '3rem',
    padding: '7px',
    borderBottom: '3px solid #005A00',
    marginBottom: '1rem',
  },
  Card: {
    // backgroundColor: '#black',
    width: '100%',
  },
}));
// });

export default function CompletePayment(props) {
  const [loading, setLoading] = useState(false);
  const [verified, setVerified] = useState(false);
  const [error, setError] = useState(false);
  const [response, setResponse] = useState({});
  const [orderId, setOrderId] = useState("");
  const [isChecked, setIsChecked] = useState(false)

  const classes = useStyles();
  const user = localStorage.getItem('userDetails');
  const userData = JSON.parse(user);
  let url
  const search = new URLSearchParams(window.location.search);

  // const orderId = search.get('orderID');

  const formatAsMoney = (fee) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(fee);
  };
  const verifyPayment = async () => {
    setLoading(true);

    try {
      const query = new URLSearchParams(window.location.search);
      const orderId = query.get('orderID');
      setOrderId(orderId);
      localStorage.getItem("payProcessingFee") === 'true' ? url = `${AppConfig.URL}/application/verify_processing_fee/${orderId}` : url = `${AppConfig.URL}/application/verify/${orderId}`
      const response = await fetch(
        url,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${userData.access_token}`,
          },
        }
      );

      const { status, data } = await response.json();
      setVerified(true);
      setIsChecked(true)
      setResponse(data);
      console.log(data)
      setLoading(false);
    } catch (error) {
      setIsChecked(true)
      setError(true);
      setLoading(false);
      Swal.fire('Error', 'Something went wrong, contact support', 'error');
    }
  };

  React.useEffect(() => {
    verifyPayment();
  }, []);

  if (loading)
    return (
      <div className={classes.spinner}>
        <Typography>Please wait while we verify your payment</Typography>
        <CircularProgress />
      </div>
    );
  return (
    <div className={classes.spinner}>
      <Card>
        {verified && isChecked ? (
          <div>
            <div className="invoice-box">
              <div className={classes.cardHeader}>
                <div className="">
                  <LogoImage src={logo} />
                </div>
                <div className="Receipt_number">
                  <h2 className={classes.reciepient_num}>
                    Receipt Number:{orderId}
                  </h2>
                  <h2 className={classes.reciepient_num}>
                    RRR:{response && response.remitta_code}
                  </h2>
                  <h2 className={classes.reciepient_num}>
                    Date : {response && response.date}
                  </h2>
                </div>
              </div>

              <div className={classes.payer_information}>
                <h1 className={classes.payer}>payer Information</h1>
                <p> {response && response.payer_information.name}</p>
                <p> {response && response.payer_information.email}</p>
                <p> {response && response.payer_information.phone}</p>
              </div>

              <div>
                <h3 className={classes.infoHeader}>Biller Information</h3>
                <div className="biller">
                  <div className={classes.separateProperties}>
                    <p className={classes.infoPara}>Name:</p>
                    <span>Nigeria Export Processing Zones Authority</span>
                  </div>
                  <div
                    className={classes.separateProperties}
                    style={{ background: '#E5E5E5' }}
                  >
                    <p className={classes.infoPara}>Address:</p>
                    <span>2 Zambezi Crescent, Cadastral Zone A6,<br />Off Aguiyi Ironsi St, Maitama, Abuja</span>
                  </div>
                  <div className={classes.separateProperties}>
                    <p className={classes.infoPara}>Email:</p>
                    <span>nepzaopms@nepza.gov.ng</span>
                  </div>
                </div>
              </div>
              <div>
                <h3 className={classes.infoHeader}>Service Description</h3>
                <div className="biller">
                  <div className={classes.separateProperties}>
                    <p
                      className={classes.infoPara}
                      style={{ marginTop: '10px' }}
                    >
                      Payment Description:
                    </p>
                    <span> {response && response.service_description.payment_description}</span>
                  </div>
                  <div
                    className={classes.separateProperties}
                    style={{ background: '#E5E5E5' }}
                  >
                    <p className={classes.infoPara}>Service Name:</p>
                    <span> {response && response.service_description.service_name}</span>
                  </div>
                  <div className={classes.separateProperties}>
                    <p className={classes.infoPara}>Amount:</p>
                    <span>
                      {formatAsMoney(
                        response && response.service_description.service_amount
                      )}
                    </span>
                  </div>
                  <div
                    className={classes.separateProperties}
                    style={{ background: '#E5E5E5' }}
                  >
                    <p
                      className={classes.infoPara}
                      style={{ borderColor: 'grey' }}
                    >
                      Total Amount:
                    </p>
                    <span>
                      {formatAsMoney(
                        response && response.service_description.service_amount
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <div className="no-print">
                <Button
                  component={RouterLink}
                  to="/me"
                  className={classes.button}
                >
                  Go to dashboard
                </Button>
                <Button
                  className={classes.button}
                  onClick={(e) => window.print()}
                >
                  Print
                </Button>
              </div>

              <div className={classes.support}>
                <h2
                  style={{
                    fontSize: '18px',
                    lineHeight: '21px',
                    color: '#000000',
                  }}
                >
                  NEED HELP?
                </h2>
                <p
                  style={{
                    fontSize: '14px',
                    lineHeight: '18px',
                    color: '#000000',
                  }}
                >
                  Contact our support team
                </p>
              </div>

              <p
                style={{
                  fontSize: '18px',
                  lineHeight: '21px',
                  color: '#000000',
                }}
              >
                www.irecharge.com.ng
              </p>
            </div>
          </div>
        ) : isChecked ? (
          <div>
            <div className="invoice-box">
              <Table cellPadding="0" cellSpacing="0">
                <TableBody>
                  <TableRow className="top">
                    <TableCell colSpan="2">
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell className="title">
                              <LogoImage src={logo} />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableCell>
                  </TableRow>
                  <TableRow className="details">
                    <TableCell>
                      {error ? (
                        <Typography component="h3" variant="h4">
                          Contact our support line for further help
                        </Typography>
                      ) : (
                        <Typography
                          component="h3"
                          variant="h4"
                          style={{ textAlign: 'center' }}
                        >
                          Payment was unsuccessful
                        </Typography>
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ textAlign: 'center' }}>
                      <Button
                        component={RouterLink}
                        to="/me"
                        className={classes.button}
                      >
                        Go to dashboard
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          </div>
        ) : null}
      </Card>
    </div>
  );
}
