import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import Typography from "@material-ui/core/Typography";
import MailIcon from "@material-ui/icons/Mail";
import DeleteIcon from "@material-ui/icons/Delete";
import Label from "@material-ui/icons/Label";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import InfoIcon from "@material-ui/icons/Info";
import ForumIcon from "@material-ui/icons/Forum";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import SettingsApplicationsIcon from "@material-ui/icons/SettingsApplications";
import { Link } from "react-router-dom";
import HomeIcon from '@material-ui/icons/Home';
import ViewListIcon from "@material-ui/icons/ViewList";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import HistoryIcon from "@material-ui/icons/History";
import BusinessIcon from '@material-ui/icons/Business';
import RoomIcon from '@material-ui/icons/Room';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import PublicIcon from "@material-ui/icons/Public";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import FormatIndentIncreaseIcon from "@material-ui/icons/FormatIndentIncrease";

const useTreeItemStyles = makeStyles((theme) => ({
  root: {
    marginBottom: "10px",
    color: theme.palette.text.secondary,

    "&:hover > $content": {
      backgroundColor: theme.palette.action.hover,
    },
    "&:focus > $content, &$selected > $content": {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
      color: "var(--tree-view-color)",
    },
    "&:focus > $content $label, &:hover > $content $label, &$selected > $content $label": {
      backgroundColor: "transparent",
    },
  },
  content: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(1),
    borderBottomRightRadius: theme.spacing(1),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    "$expanded > &": {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  group: {
    marginLeft: 0,
    "& $content": {
      paddingLeft: theme.spacing(2),
    },
  },
  expanded: {},
  selected: {},
  label: {
    fontWeight: "inherit",
    color: "inherit",
  },
  labelRoot: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0.5, 0),
  },
  labelIcon: {
    marginRight: theme.spacing(1),
  },
  labelText: {
    fontWeight: "bold",
    flexGrow: 1,
  },
}));

function StyledTreeItem(props) {
  const classes = useTreeItemStyles();
  const {
    labelText,
    labelIcon: LabelIcon,
    labelInfo,
    color,
    bgColor,
    ...other
  } = props;

  return (
    <TreeItem
      label={
        <div className={classes.labelRoot}>
          <LabelIcon color="inherit" className={classes.labelIcon} />
          <Typography variant="body1" className={classes.labelText}>
            {labelText}
          </Typography>
          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>
        </div>
      }
      style={{
        "--tree-view-color": color,
        "--tree-view-bg-color": bgColor,
      }}
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label,
      }}
      {...other}
    />
  );
}

StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelIcon: PropTypes.elementType.isRequired,
  labelInfo: PropTypes.string,
  labelText: PropTypes.string.isRequired,
};

const useStyles = makeStyles({
  root: {
    height: 450,
    flexGrow: 1,
    maxWidth: 400,
  },
});

export default function SettingsMenu() {
  const classes = useStyles();

  const getUserRole = () => {
    const userDetails = localStorage.getItem("userDetails");
    const user = JSON.parse(userDetails);
    if (user) {
      return user.data.role_id;
    }
    return null;
  };

  return (
    <TreeView
      className={classes.root}
      //   defaultExpanded={['3']}
      defaultCollapseIcon={<ArrowDropDownIcon />}
      defaultExpandIcon={<ArrowRightIcon />}
      defaultEndIcon={<div style={{ width: 24 }} />}
    >
      {(getUserRole() !== 12) &&
        (<Link to="/admin-account/nepza-admin">
          <StyledTreeItem
            nodeId="1"
            color="#48BB78"
            bgColor="#F0FFF4"
            labelText="Dashboard"
            labelIcon={HomeIcon}
          />
        </Link>
        )}
      {(getUserRole() !== 12) &&
        (<Link to="/admin-account">
          <StyledTreeItem
            nodeId="2"
            color="#48BB78"
            bgColor="#F0FFF4"
            labelText="Applications"
            labelIcon={ViewListIcon}
          />
        </Link>)
      }

      {(getUserRole() === 7 || getUserRole() === 11) &&
        (<Link to="/admin-account/admin-zones">
          <StyledTreeItem
            nodeId="27"
            color="#48BB78"
            bgColor="#F0FFF4"
            labelText="Zones"
            labelIcon={RoomIcon}
          />
        </Link>)
      }

      {(getUserRole() === 7 || getUserRole() === 11) &&
        (<Link to="/admin-account/admin-promoters">
          <StyledTreeItem
            nodeId="28"
            color="#48BB78"
            bgColor="#F0FFF4"
            labelText="Promoters"
            labelIcon={BusinessIcon}
          />
        </Link>)
      }

      {(getUserRole() === 7 || getUserRole() === 11) &&
        (<Link to="/admin-account/zmc-applications">
          <StyledTreeItem
            nodeId="29"
            color="#48BB78"
            bgColor="#F0FFF4"
            labelText="ZMC"
            labelIcon={LocationCityIcon}
          />
        </Link>)
      }

      {(getUserRole() === 7 || getUserRole() === 11) &&
        (<Link to="/admin-account/admin-enterprises">
          <StyledTreeItem
            nodeId="30"
            color="#48BB78"
            bgColor="#F0FFF4"
            labelText="Enterprise"
            labelIcon={BusinessCenterIcon}
          />
        </Link>)
      }
      {(getUserRole() !== 12) &&
        (<Link to="/admin-account/history">
          <StyledTreeItem
            nodeId="3"
            color="#48BB78"
            bgColor="#F0FFF4"
            labelText="History"
            labelIcon={HistoryIcon}
          />
        </Link>
        )}
      {/* <Link to="/admin-account/change-password">
        <StyledTreeItem
          nodeId="3"
          color="#48BB78"
          bgColor="#F0FFF4"
          labelText="Change Password"
          labelIcon={HistoryIcon}
        />
      </Link> */}
      {getUserRole() === 6 && (
        <Link to="/admin-account/exchange-rate">
          <StyledTreeItem
            nodeId="19"
            labelText="Exchange Rate"
            labelIcon={MonetizationOnIcon}
            color="#48BB78"
            bgColor="#F0FFF4"
          />
        </Link>
      )}
      {(getUserRole() === 9 || getUserRole() === 12) && (
        <StyledTreeItem
          nodeId="4"
          labelText="User Management"
          labelIcon={SupervisorAccountIcon}
          color="#48BB78"
          bgColor="#F0FFF4"
        >
          <Link to="/admin-account/create-user">
            <StyledTreeItem
              labelText="Create User"
              labelIcon={SupervisorAccountIcon}
              color="#48BB78"
              bgColor="#F0FFF4"
            />
          </Link>
          <Link to="/admin-account/view-users">
            <StyledTreeItem
              labelText="View Users"
              labelIcon={SupervisorAccountIcon}
              color="#48BB78"
              bgColor="#F0FFF4"
            />
          </Link>
        </StyledTreeItem>
      )}

      {(getUserRole() === 9 || getUserRole() === 12) && (
        <>
          <StyledTreeItem
            nodeId="5"
            labelText="Role Management"
            labelIcon={PublicIcon}
          >
            <Link to="/admin-account/create-role">
              <StyledTreeItem
                labelText="Create Role"
                labelIcon={SupervisorAccountIcon}
                nodeId="6"
                color="#48BB78"
                bgColor="#F0FFF4"
              />
            </Link>
            <Link to="/admin-account/view-roles">
              <StyledTreeItem
                nodeId="7"
                labelText="View Roles"
                labelIcon={SupervisorAccountIcon}
                color="#48BB78"
                bgColor="#F0FFF4"
              />
            </Link>
          </StyledTreeItem>
          {/* <StyledTreeItem
            nodeId="8"
            labelText="Process Modules"
            labelIcon={AccountTreeIcon}
          >
            <Link to="/admin-account/create-process-module">
              <StyledTreeItem
                labelText="Create Process Module"
                labelIcon={FormatIndentIncreaseIcon}
                nodeId="9"
                color="#48BB78"
                bgColor="#F0FFF4"
              />
            </Link>
            <Link to="/admin-account/view-process-modules">
              <StyledTreeItem
                nodeId="10"
                labelText="Process Modules"
                labelIcon={FormatIndentIncreaseIcon}
                color="#48BB78"
                bgColor="#F0FFF4"
              />
            </Link>

            <Link to="/admin-account/process-flow">
              <StyledTreeItem
                nodeId="11"
                labelText="Process Flow"
                labelIcon={LocalOfferIcon}
                color="#48BB78"
                bgColor="#F0FFF4"
              />
            </Link>
          </StyledTreeItem> */}
        </>
      )}
      {(getUserRole() === 9 || getUserRole() === 12) && (
        <StyledTreeItem
          nodeId="12"
          labelText="SetUp"
          labelIcon={SettingsApplicationsIcon}
        >
          {/* <Link to="/admin-account/zones">
            <StyledTreeItem
              nodeId="13"
              labelText="Zones"
              labelIcon={SupervisorAccountIcon}
              color="#48BB78"
              bgColor="#F0FFF4"
            />
          </Link> */}
          <Link to="/admin-account/services">
            <StyledTreeItem
              nodeId="14"
              labelText="Services"
              labelIcon={ForumIcon}
              color="#48BB78"
              bgColor="#F0FFF4"
            />
          </Link>
          <Link to="/admin-account/service_types">
            <StyledTreeItem
              nodeId="14"
              labelText="Service Types"
              labelIcon={BusinessCenterIcon}
              color="#48BB78"
              bgColor="#F0FFF4"
            />
          </Link>

          <Link to="/admin-account/document">
            <StyledTreeItem
              nodeId="10"
              labelText="Document"
              labelIcon={HistoryIcon}
              color="#48BB78"
              bgColor="#F0FFF4"
            />
          </Link>

          <Link to="/admin-account/view-categories">
            <StyledTreeItem
              nodeId="16"
              labelText="Categories"
              labelIcon={BusinessIcon}
              color="#48BB78"
              bgColor="#F0FFF4"
            />
          </Link>
          {/* <Link to="/admin-account/view-categories">
            <StyledTreeItem
              nodeId="16"
              labelText="Users"
              labelIcon={ForumIcon}
              color="#48BB78"
              bgColor="#F0FFF4"
            />
          </Link> */}
        </StyledTreeItem>
      )}
    </TreeView>
  );
}
