import React, { useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "pages/HeroSection.js";
import Services from "pages/Services.js";
import MoreServices from "pages/LandingpageSections/MoreServices";
import MainFeature from "components/features/TwoColWithButton.js";
import Pricing from "pages/Tarrifs.js";
import FAQ from "pages/FAQ.js";
import Footer from "pages/Footer.js";
import Header from "pages/Header.js";
import serverRedundancyIllustrationImageSrc from "images/stepsimage.png";

export default () => {

  const [moreServices, setMoreServices] = useState(false);
  const toggleMoreServices = () => {
    setMoreServices(!moreServices)
  }
  return (
    <AnimationRevealPage>
      <Header />
      <Hero />
      <Services toggleMoreServices={toggleMoreServices} />
      {moreServices && <MoreServices />}
      <Pricing />
      <MainFeature
        subheading=""
        heading="How it works"
        imageSrc={serverRedundancyIllustrationImageSrc}
        buttonRounded={false}
      />
      <FAQ />
      <Footer />
    </AnimationRevealPage>
  );
};
