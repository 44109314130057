import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { useHistory, Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { HistoryTwoTone } from "@material-ui/icons";
import CircularProgress from "@material-ui/core/CircularProgress";
import axiosInstance from "../../Api/Api.js";

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    tableFont: {
        // fontWeight: "bold",
        fontSize: "18px",
    },
    tableHeader: {
        paddingLeft: "0",
        fontSize: "18px",
        width: "100%",
        marginLeft: "10px",
    },
    headerContainer: {
        marginBottom: "10px",
    },
});

export default function BasicTable() {
    const classes = useStyles();
    const history = useHistory();
    const [allRequests, setAllRequests] = useState([]);
    const [loading, setLoading] = useState([]);

    const showDetails = (rowId) => {
        console.log(rowId);
        history.push(`/admin-account/service-type/edit-create/${rowId}`);
    };

    const fetchAllServiceTypes = () => {
        setLoading(true);
        axiosInstance
            .get("/service_types/list")
            .then(function (response) {
                if (response.status === 200) {
                    setAllRequests(response.data.data.data);
                    console.log(response.data, allRequests);
                } else {
                }
            })
            .catch(function (error) {
                // console.log(error.response.data.message);
            })
            .finally(() => setLoading(false));
    };
    useEffect(() => {
        fetchAllServiceTypes();
    }, []);

    return loading ? (
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={{ height: "100%" }}
        >
            <CircularProgress color="primary" />
        </Grid>
    ) : !allRequests.length ? (
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={{ height: "100%" }}
        >
            <Typography variant="h6">No Record found</Typography>
        </Grid>
    ) : (
        <div>
            <Grid
                container
                direction="row"
                justify="space-between"
                className={classes.headerContainer}
                alignItems="flex-end"
            >
                <div>
                    <Typography className={classes.tableHeader}>Service Types</Typography>
                </div>
                <Link to="/admin-account/service-type/edit-create/create">
                    <Button variant="contained" color="primary">
                        Add new
                    </Button>
                </Link>
            </Grid>
            <TableContainer component={Paper} variant="outlined">
                <Table className={classes.table} aria-label="data-table">
                    <TableHead>
                        <TableRow className={classes.tableFont}>
                            <TableCell className={classes.tableFont}></TableCell>
                            <TableCell className={classes.tableFont}>Service Type</TableCell>
                            <TableCell className={classes.tableFont}>Service Code</TableCell>
                            <TableCell className={classes.tableFont}>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {allRequests.map((row) => (
                            <TableRow key={row.zone_id}>
                                <TableCell component="th" scope="row">
                                    {row.id}
                                </TableCell>
                                <TableCell>{row.service_type}</TableCell>
                                <TableCell>{row.service_code}</TableCell>

                                <TableCell>
                                    {" "}
                                    <Link href="#" onClick={() => showDetails(row.service_type_id)}>
                                        Edit
                                    </Link>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}
