import React, { useEffect, useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import {
  Button, Typography, Table, TableBody, TableCell, TableContainer, TableHead,
  TableRow, Paper, CircularProgress, TablePagination, colors, capitalize, Menu, MenuItem
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import moment from 'moment';
import { Link as RouterLink } from 'react-router-dom';
import { AppConfig } from 'app-config';
import Swal from 'sweetalert2';
import { green, grey } from '@material-ui/core/colors';
import axios from 'axios';
import { useHistory } from 'react-router-dom'
import Payment from "./InitiatePayment";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { TramRounded } from '@material-ui/icons';



const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: green[200],
    color: theme.palette.common.black
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: grey[50]
    }
  }
}))(TableRow);

const useStyles = makeStyles(theme => ({
  button: {
    margin: '1rem',
    backgroundColor: '#3f51b5',
    color: 'white',
    fontSize: '16px',
    width: '12rem',
    padding: '1rem',
    borderRadius: '8px'
  },
  table: {
    minWidth: 700
  },
  spinner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
  },
  delBtn: {
    color: 'red'
  },
  norec: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '50vh',
    flexDirection: 'column'
  },
  button: {
    display: 'block',
    marginTop: theme.spacing(2)
  },
  action: {
    display: 'flex',
    justifyContent: 'space-evenly'
  },
  payBtn: {
    color: colors.green[600]
  },
  head: {
    backgroundColor: grey[200]
  },
  bold: {
    fontWeight: "bold"
  }
}));

export default function PromoterZonesList({ type }) {

  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [paginated, setPaginated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [requests, setRequests] = useState([])
  const [anchorEl, setAnchorEl] = useState(null);
  const [isPaying, setIsPaying] = useState(false);
  const user = localStorage.getItem('userDetails');
  const [open, setOpen] = useState(false);

  const userData = JSON.parse(user)

  const history = useHistory();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setPaginated(true);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClickDialogOpen = () => {
    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
  };


  const getRequests = async () => {
    setLoading(true)
    try {
      const response = await fetch(`${AppConfig.URL}/zones/promoter/list?status=${type}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${userData.access_token}`
        }
      });
      const { status, data, message } = await response.json();
      if (status === true) {
        console.log(data)
        setRequests(data)


      } else {
        Swal.fire('', message, 'info');
      }
      setLoading(false)
    } catch (error) {
      Swal.fire('Something went wrong', 'Unable to get zones', 'error');
      setLoading(false)
    }
  }


  useEffect(() => {
    getRequests();
  }, []);



  if (!loading && requests.length === 0) {
    return (
      <div className={classes.norec}>
        <Typography component="h1" variant="h3">
          No record found
        </Typography>

        <Button
          className={classes.button}
          color="primary"
          onClick={getRequests}
          variant="contained">
          Go Back
        </Button>
      </div>
    );
  } else if ((loading && !paginated) || !requests.length) {
    return (
      <div className={classes.spinner}>
        <CircularProgress />
      </div>
    );
  }

  const statusColor = {
    processed: colors.green[600],
    pending: colors.orange[600],
    declined: colors.red[600],
    'in-progress': colors.blue[600],
    abandoned: colors.blue[600],
  };


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  return (
    <>
      <Typography color="textSecondary" gutterBottom variant="body2">
        Page {page + 1} of{' '}
        {Math.ceil(requests.length / 10)}
      </Typography>
      <TableContainer component={Paper}>
        <Table stickyHeader aria-label="customized table" className={classes.table}>
          <TableHead className={classes.head}>
            <TableRow>
              <StyledTableCell>S/N</StyledTableCell>
              {/* <StyledTableCell>View</StyledTableCell> */}
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell>Address</StyledTableCell>
              <StyledTableCell>Zone Type</StyledTableCell>
              <StyledTableCell>Promoter Name</StyledTableCell>
              <StyledTableCell>State</StyledTableCell>
              <StyledTableCell>LGA</StyledTableCell>
              {/* <StyledTableCell>Processing Fee Payment Status</StyledTableCell> */}
              {/* <StyledTableCell>Actual Fee Payment Status</StyledTableCell> */}

              {type == 'all' && <StyledTableCell>Approval Status</StyledTableCell>}
              {/* {type !== 'in-progress' ? <StyledTableCell align="center">Action</StyledTableCell> : null} */}
              {/* {<StyledTableCell align="center">Action</StyledTableCell>} */}

            </TableRow>
          </TableHead>
          <TableBody>
            {console.log(requests)}
            {requests &&
              requests.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((request, index) => (

                <StyledTableRow key={request.id}>
                  <StyledTableCell component="th" scope="row">
                    {page * 10 + index + 1}
                  </StyledTableCell>
                  {/* <StyledTableCell onClick={() => viewSingleRequest(request.id)}>view</StyledTableCell> */}

                  <StyledTableCell>
                    <Typography variant="body2">
                      {capitalize(request.name)}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell>{capitalize(request.address)}</StyledTableCell>
                  <StyledTableCell>{request.zone_type}</StyledTableCell>

                  <StyledTableCell>
                    {capitalize(request.promoter_name)}
                  </StyledTableCell>
                  <StyledTableCell>
                    {capitalize(request.state)}
                  </StyledTableCell>
                  <StyledTableCell>
                    {request.lga}
                  </StyledTableCell>


                  {type == 'all' && request.approved == true ? <StyledTableCell style={{ color: "green" }}>
                    Approved
                  </StyledTableCell> : type == 'all' && request.approved == false ? <StyledTableCell style={{ color: "red" }}>Pending</StyledTableCell> : null}

                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={requests.length}
          onChangePage={handleChangePage}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[10, 20, 50, 100]}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
        {isPaying ?
          <Dialog open={open} onClose={handleDialogClose} aria-labelledby="form-dialog-title">
            <Payment applicationId={localStorage.getItem("app_id_pay")} />
          </Dialog>
          : null}
      </TableContainer>

    </>
  );
};



