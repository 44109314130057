import React, { useEffect, useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import {
  Button, Typography, Table, TableBody, TableCell, TableContainer, TableHead,
  TableRow, Paper, CircularProgress, TablePagination, colors, capitalize, TextField,
  Menu, MenuItem
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import moment from 'moment';
import { Link as RouterLink } from 'react-router-dom';
import { AppConfig } from 'app-config';
import Swal from 'sweetalert2';
import { green, grey } from '@material-ui/core/colors';
import axios from 'axios';
import { useHistory } from 'react-router-dom'
import Payment from "./InitiatePayment";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Requests from './Request';



const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: green[200],
    color: theme.palette.common.black
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: grey[50]
    }
  }
}))(TableRow);

const useStyles = makeStyles(theme => ({
  button: {
    margin: '1rem',
    backgroundColor: '#3f51b5',
    color: 'white',
    fontSize: '16px',
    width: '12rem',
    padding: '1rem',
    borderRadius: '8px'
  },
  table: {
    minWidth: 700
  },
  spinner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
  },
  delBtn: {
    color: 'red'
  },
  norec: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '50vh',
    flexDirection: 'column'
  },
  button: {
    display: 'block',
    marginTop: theme.spacing(2)
  },
  action: {
    display: 'flex',
    justifyContent: 'space-evenly'
  },
  payBtn: {
    color: colors.green[600]
  },
  head: {
    backgroundColor: grey[200]
  },
  bold: {
    fontWeight: "bold"
  }
}));

export default function RequestList({ type }) {

  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [paginated, setPaginated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [requests, setRequests] = useState([])
  const [anchorEl, setAnchorEl] = useState(null);
  const [isPaying, setIsPaying] = useState(false);
  const user = localStorage.getItem('userDetails');
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const userData = JSON.parse(user)

  const history = useHistory();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setPaginated(true);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClickDialogOpen = () => {
    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
  };


  const getRequests = async () => {
    setLoading(true)
    try {
      const response = await fetch(`${AppConfig.URL}/application/status?status=${type}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${userData.access_token}`
        }
      });
      const { status, data, message } = await response.json();
      if (status === true) {
        console.log(data)
        setRequests(data)
        // const filteredRequests = data.filter(d => d.isUploaded);
        // setRequests(filteredRequests);

      } else {
        Swal.fire('', message, 'info');
      }
      setLoading(false)
    } catch (error) {
      Swal.fire('Something went wrong', 'Unable to get application status', 'error');
      setLoading(false)
    }
  }

  const viewSingleRequest = (id) => {
    console.log('p2 id: ' + id)
    history.push(`/me/company-request-details/${id}`)
  }



  useEffect(() => {
    getRequests();
  }, []);



  if (!loading && requests.length === 0) {
    return (
      <div className={classes.norec}>
        <Typography component="h1" variant="h3">
          No record found
        </Typography>

        <Button
          className={classes.button}
          color="primary"
          onClick={getRequests}
          variant="contained">
          Go Back
        </Button>
      </div>
    );
  } else if ((loading && !paginated) || !requests.length) {
    return (
      <div className={classes.spinner}>
        <CircularProgress />
      </div>
    );
  }

  const statusColor = {
    processed: colors.green[600],
    pending: colors.orange[600],
    declined: colors.red[600],
    'in-progress': colors.blue[600],
    abandoned: colors.blue[600],
  };

  const makePayment = async (id) => {
    const body = {
      application_id: id
    }
    setLoading(true);
    const { data, status } = await fetch('https://nepza.herokuapp.com/api/v1/application/make_payment', {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userData.access_token}`,
      },
      body: JSON.stringify(body)
    }).then((res) => res.json());
    setLoading(false)
    if (status) {
      const paymentData = data.remitta_data;
      Swal.fire({
        title: 'Continue to Remitta',
        icon: 'info',
        html:
          `<div>NOTE: Payment can also be made at the Bank with RRR Code ${paymentData.rrr} if you do not intend to make payment via Debit/Credit Card.</div>` +
          '<br></br>' +
          `<form enctype="multipart/form-data" onSubmit="setLoading(true)" method="post" name="SubmitRemitaForm" action="${paymentData.action}/finalize.reg">` +
          `<input type="hidden" value="${paymentData.merchantId}" name="merchantId" />` +
          `<input type="hidden" value="${paymentData.hash}" name="hash" />` +
          `<input type="hidden" value="${paymentData.rrr}" name="rrr" />` +
          '<input type="hidden" value="http://localhost:3000/finalize" name="responseurl" />' +
          `<input type="submit" style="cursor:pointer;background-color:green;color:white;font-size:16px;width:12rem;padding:1rem;borderRadius:8px" value="Pay Now" name="submit_btn" />` +
          '</form>',
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton: false,
        focusConfirm: false,
      })
    }

  }

  const toMoney = (value) => {
    let parsedValue = new Number(value).toLocaleString("en-US");
    return parsedValue
  }



  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSearch = () => {
    // setSearchTerm(searchTerm);
    if (searchTerm !== "") {
      const newRequests = requests.filter((request) => {
        return Object.values(request)
          .join(" ")
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
      });
      if (newRequests.length > 0) {
        setSearchResults(newRequests);
      } else {
        Swal.fire(`No ${type !== 'all' ? type : ''} application matches your search term`);
        setSearchResults(newRequests);
      }

    } else {
      setSearchResults(requests);
    }
  };


  return (
    <>
      <TextField size="small"
        variant="outlined"
        className={classes.textfield}
        onChange={(e) => setSearchTerm(e.target.value)}
        variant="outlined"
        placeholder="Reference|Service|Remitta Code"
      />
      <Button variant="outlined"
        color="primary"
        onClick={handleSearch}
        size="large"
        style={{ marginLeft: 1, paddingBottom: 1 }}
      >
        Search
      </Button>
      {(requests && searchResults.length === 0) || (!searchTerm) ?
        <Typography color="textSecondary" gutterBottom variant="body2">
          Page {page + 1} of{' '}
          {Math.ceil(requests.length / 10)}
        </Typography> :
        searchResults && searchTerm ?
          <Typography color="textSecondary" gutterBottom variant="body2">
            Page {page + 1} of{' '}
            {Math.ceil(searchResults.length / 10)}
          </Typography> : null}

      <TableContainer component={Paper}>
        <Table stickyHeader aria-label="customized table" className={classes.table}>
          <TableHead className={classes.head}>
            <TableRow>
              <StyledTableCell>S/N</StyledTableCell>
              {/* <StyledTableCell>View</StyledTableCell> */}
              <StyledTableCell>Date</StyledTableCell>
              <StyledTableCell>Reference</StyledTableCell>
              <StyledTableCell>Service</StyledTableCell>
              <StyledTableCell>Processing Fee <br />(Status)</StyledTableCell>
              <StyledTableCell>Actual Fee <br /> (Status)</StyledTableCell>
              <StyledTableCell>Remitta Code</StyledTableCell>
              {/* <StyledTableCell>Processing Fee Payment Status</StyledTableCell> */}
              {/* <StyledTableCell>Actual Fee Payment Status</StyledTableCell> */}

              {type !== 'all' && <StyledTableCell>Request Status</StyledTableCell>}
              {/* {type !== 'in-progress' ? <StyledTableCell align="center">Action</StyledTableCell> : null} */}
              {<StyledTableCell align="center">Action</StyledTableCell>}

            </TableRow>
          </TableHead>
          <TableBody>
            {console.log(requests)}
            {/* {searchTerm ? display = searchResults : display = requests} */}
            {(requests && searchResults.length === 0) || (!searchTerm) ?
              requests.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((request, index) => (

                <StyledTableRow key={request.id}>
                  <StyledTableCell component="th" scope="row">
                    {page * 10 + index + 1}
                  </StyledTableCell>
                  {/* <StyledTableCell onClick={() => viewSingleRequest(request.id)}>view</StyledTableCell> */}

                  <StyledTableCell>
                    <Typography variant="body2">
                      {moment(request.application_date).format('DD MMM YYYY | hh:mm')}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell>{capitalize(request.reference)}</StyledTableCell>
                  <StyledTableCell>{request.service_name}</StyledTableCell>
                  <StyledTableCell>
                    {request.processing_fee ? '$' + toMoney(request.processing_fee) : 'N/A'} <br />
                    <span className={classes.bold}>
                      {(request.isProcessingFeePaid === 1) && (request.service_name === 'Free Zone' || request.service_name === 'Industrial Park') ? '(Paid)' :
                        (request.isProcessingFeePaid === 0) && (request.service_name === 'Free Zone' || request.service_name === 'Industrial Park') ? '(Unpaid)' :
                          (request.isProcessingFeePaid === 1) ? '(Paid)' : ''
                      }
                    </span>
                  </StyledTableCell>
                  <StyledTableCell>
                    {'$' + toMoney(request.fee)}
                    <br />
                    <span className={classes.bold}>
                      {request.isPaid === 1 ? '(Paid)' : '(Unpaid)'}
                    </span>
                    {console.log(request)}
                  </StyledTableCell>
                  <StyledTableCell>
                    {request.remitta_code ? request.remitta_code : 'Not Generated'}
                  </StyledTableCell>
                  {/* <StyledTableCell>
                    {(request.isProcessingFeePaid === 1) && (request.service.name === 'Free Zone' || request.service.name === 'Industrial Park') ? 'Paid' :
                      (request.isProcessingFeePaid === 0) && (request.service.name === 'Free Zone' || request.service.name === 'Industrial Park') ? 'Unpaid' :
                        (request.isProcessingFeePaid === 1) ? 'Paid' : '-'
                    }
                  </StyledTableCell> */}
                  {/* <StyledTableCell>
                    {request.isPaid === 1 ? 'Paid' : 'Unpaid'}
                    {console.log(request)}
                  </StyledTableCell> */}

                  {type !== 'all' && <StyledTableCell>
                    <Typography
                      style={{ color: statusColor[request.status] }}
                    >
                      {
                        capitalize(type)
                      }
                    </Typography>
                  </StyledTableCell>}

                  {/* <StyledTableCell>


                    {!request.isPaid && !request.service.payBefore && request.status === "processed" ? <div className={classes.action}>
                      <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                        <ArrowDropDownIcon />
                      </Button>
                      <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                      >
                        <MenuItem onClick={() => viewSingleRequest(request.id)}>View Application</MenuItem>
                        <MenuItem onClick={() => setIsPaying(true)}>Make Payment {isPaying ? <Payment applicationId={request.id} /> : null}</MenuItem>
                      </Menu>

                    </div> :
                      <div className={classes.action}>
                        <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                          <ArrowDropDownIcon />
                        </Button>
                        <Menu
                          id="simple-menu"
                          anchorEl={anchorEl}
                          keepMounted
                          open={Boolean(anchorEl)}
                          onClose={handleClose}
                        >
                          {console.log('p1 id: ' + request.id)}
                          <MenuItem onClick={() => viewSingleRequest(request.id)}>View Application</MenuItem>
                        </Menu>
                      </div>
                    }


                  </StyledTableCell> */}

                  <StyledTableCell>
                    <Button variant="outlined" color="primary" size="small" onClick={() => viewSingleRequest(request.id)}>View Details</Button><br />
                    {!request.isPaid && !request.payBefore && request.status === "processed" ?

                      <Button variant="outlined" color="primary" size="small" onClick={() => {
                        localStorage.setItem("app_id_pay", request.id)
                        setIsPaying(true)
                        handleClickDialogOpen()
                      }}>Make Payment
                      </Button>
                      : null}
                    {/* {!request.isPaid && !request.service.payBefore && request.status === "processed" ?
                        <Button variant="outlined" color="primary" onClick={() => {
                          setIsPaying(true)
                        }}>
                          Pay {isPaying ? <Payment applicationId={request.id} /> : null}
                        </Button> : null} */}

                  </StyledTableCell>

                </StyledTableRow>
              )) : searchResults && searchTerm ? searchResults.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((request, index) => (
                <StyledTableRow key={request.id}>
                  <StyledTableCell component="th" scope="row">
                    {page * 10 + index + 1}
                  </StyledTableCell>
                  {/* <StyledTableCell onClick={() => viewSingleRequest(request.id)}>view</StyledTableCell> */}
                  <StyledTableCell>
                    <Typography variant="body2">
                      {moment(request.application_date).format('DD MMM YYYY | hh:mm')}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell>{capitalize(request.reference)}</StyledTableCell>
                  <StyledTableCell>{request.service_name}</StyledTableCell>
                  <StyledTableCell>
                    {request.processing_fee ? '$' + toMoney(request.processing_fee) : 'N/A'} <br />
                    <span className={classes.bold}>
                      {(request.isProcessingFeePaid === 1) && (request.service_name === 'Free Zone' || request.service_name === 'Industrial Park') ? '(Paid)' :
                        (request.isProcessingFeePaid === 0) && (request.service_name === 'Free Zone' || request.service_name === 'Industrial Park') ? '(Unpaid)' :
                          (request.isProcessingFeePaid === 1) ? '(Paid)' : ''
                      }
                    </span>
                  </StyledTableCell>
                  <StyledTableCell>
                    {'$' + toMoney(request.fee)}
                    <br />
                    <span className={classes.bold}>
                      {request.isPaid === 1 ? '(Paid)' : '(Unpaid)'}
                    </span>
                    {console.log(request)}
                  </StyledTableCell>
                  <StyledTableCell>
                    {request.remitta_code ? request.remitta_code : 'Not Generated'}
                  </StyledTableCell>
                  {/* <StyledTableCell>
                  {(request.isProcessingFeePaid === 1) && (request.service.name === 'Free Zone' || request.service.name === 'Industrial Park') ? 'Paid' :
                    (request.isProcessingFeePaid === 0) && (request.service.name === 'Free Zone' || request.service.name === 'Industrial Park') ? 'Unpaid' :
                      (request.isProcessingFeePaid === 1) ? 'Paid' : '-'
                  }
                </StyledTableCell> */}
                  {/* <StyledTableCell>
                  {request.isPaid === 1 ? 'Paid' : 'Unpaid'}
                  {console.log(request)}
                </StyledTableCell> */}

                  {type !== 'all' && <StyledTableCell>
                    <Typography
                      style={{ color: statusColor[request.status] }}
                    >
                      {
                        capitalize(type)
                      }
                    </Typography>
                  </StyledTableCell>}

                  {/* <StyledTableCell>


                  {!request.isPaid && !request.service.payBefore && request.status === "processed" ? <div className={classes.action}>
                    <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                      <ArrowDropDownIcon />
                    </Button>
                    <Menu
                      id="simple-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      <MenuItem onClick={() => viewSingleRequest(request.id)}>View Application</MenuItem>
                      <MenuItem onClick={() => setIsPaying(true)}>Make Payment {isPaying ? <Payment applicationId={request.id} /> : null}</MenuItem>
                    </Menu>

                  </div> :
                    <div className={classes.action}>
                      <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                        <ArrowDropDownIcon />
                      </Button>
                      <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                      >
                        {console.log('p1 id: ' + request.id)}
                        <MenuItem onClick={() => viewSingleRequest(request.id)}>View Application</MenuItem>
                      </Menu>
                    </div>
                  }


                </StyledTableCell> */}

                  <StyledTableCell>
                    <Button variant="outlined" color="primary" size="small" onClick={() => viewSingleRequest(request.id)}>View Details</Button><br />
                    {!request.isPaid && !request.payBefore && request.status === "processed" ?

                      <Button variant="outlined" color="primary" size="small" onClick={() => {
                        localStorage.setItem("app_id_pay", request.id)
                        setIsPaying(true)
                        handleClickDialogOpen()
                      }}>Make Payment
                      </Button>
                      : null}
                    {/* {!request.isPaid && !request.service.payBefore && request.status === "processed" ?
                      <Button variant="outlined" color="primary" onClick={() => {
                        setIsPaying(true)
                      }}>
                        Pay {isPaying ? <Payment applicationId={request.id} /> : null}
                      </Button> : null} */}

                  </StyledTableCell>

                </StyledTableRow>
              )) : null}









          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={(requests && searchResults.length === 0) || (!searchTerm) ?
            requests.length : searchResults && searchTerm ? searchResults.length : null}
          onChangePage={handleChangePage}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[10, 20, 50, 100]}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
        {isPaying ?
          <Dialog open={open} onClose={handleDialogClose} aria-labelledby="form-dialog-title">
            <Payment applicationId={localStorage.getItem("app_id_pay")} />
          </Dialog>
          : null}
      </TableContainer>

    </>
  );
};



