import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import Swal from "sweetalert2";
import { Stepper, Step, StepLabel, MenuItem } from "@material-ui/core";
import { AppConfig } from "../app-config";
import ZoneDeclaration from "./ZoneDeclaration";
import UploadDocuments from "./UploadDocuments";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  root: {
    width: '100%'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  container: {
    display: "flex",
    height: "100%",
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    minHeight: "45vh",
    height: "auto",
    width: "100%",
    minWidth: "300px"
    // verticalAlign: "middle",
  },
  form: {
    width: "100%",
    display: "flex",
    height: "50px",
    alignItems: "center",
    justifyContent: "center",
    padding: "8rem 0",
    flexDirection: "column"
  },
  textfields: {
    width: "100%",
    display: 'flex',
    flexDirection: 'row',
    alignItems: "center",
    justifyContent: "center",
  },
  text: {
    width: "70%",
    marginBottom: "1.4rem"
  },
  select: {
    width: "30%",
    margin: "1.4rem"
  },
  button: {
    marginTop: "1rem"
  },
  inline: {
    marginLeft: "1rem"
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
    marginRight: "1rem"
  },
  spinner: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "1rem",
    height: "100%"
  }
}));

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};



export default function CreateApplication({ setApplicationId, processCat, setProcess, currentStep, setServiceId, serviceId }) {

  const [loading, setLoading] = useState(false);
  const [alert, showAlert] = useState(false);
  const [selected, isSelected] = useState(false);
  const [subSelected, isSubSelected] = useState(false);
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState('');
  const [subCategories, setSubCategories] = useState([])
  //   const [subCategory, setSubCategory] = useState('')
  const [selectedSubCat, setSelectedSub] = useState({})
  const [payBefore, setPayBefore] = useState('')
  const [assetValue, setAssetValue] = useState("");
  const [moneyValue, setMoneyValue] = useState("");
  const user = localStorage.getItem('userDetails');

  const userData = JSON.parse(user)
  const classes = useStyles();

  const selectSubCategory = (e) => {
    setServiceId(e.target.value);
    // setSubCategory(e.target.value);
    const sub = subCategories.find(element => element.id === e.target.value);
    setSelectedSub(sub);
    setProcess(sub);
    isSubSelected(true);
  };

  const formatAsMoney = fee => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(fee);
  };

  const selectCategory = async (e) => {
    setLoading(true);
    isSelected(true)
    setCategory(e.target.value);
    console.log(e.target.value)
    const cat = categories.find(element => element.id === e.target.value);
    processCat(cat);

    try {
      const response = await fetch(`${AppConfig.URL}/category/get/${e.target.value}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${userData.access_token}`
        }
      });
      const { status, data, message } = await response.json();
      if (status === true) {
        setSubCategories(data)
        console.log(data)

      } else {
        Swal.fire('', message, 'info');
      }
      setLoading(false)
    } catch (error) {
      setLoading(false);
      if (!window.navigator.onLine) {
        Swal.fire('Internet', 'Check your connection', 'error')
      } else {
        Swal.fire('Something went wrong', 'Unable to get Categories', 'error');
      }
    }

  }
  const getCategories = async () => {
    setLoading(true);

    try {
      const response = await fetch(`${AppConfig.URL}/category/list`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${userData.access_token}`
        }
      });
      const { status, data, message } = await response.json();
      if (status === true) {

        setCategories(data);
      } else {
        Swal.fire('', message, 'info');
      }
      setLoading(false)
    } catch (error) {
      setLoading(false);
      if (!window.navigator.onLine) {
        Swal.fire('Internet', 'Check your connection', 'error')
      } else {
        Swal.fire('Something went wrong', 'Unable to get record', 'error');
      }
    }
  };

  React.useEffect(() => {
    getCategories()
  }, []);
  // console.log(categories)

  const handleSubmit = async () => {
    // console.log('category', category);
    // console.log('sub-category', subCategory);
    // console.log('selected-sub-category', selectedSubCat);

    try {
      setLoading(true);
      let info;
      let reqMethod = "POST";
      let url = AppConfig.URL + "/application/add";
      (selectedSubCat.fee_type === "stages" || selectedSubCat.fee_type === "fraction") ? info = {
        company_id: userData['data'].company_id,
        service_id: serviceId,
        amount: assetValue
      } : info = {
        company_id: userData['data'].company_id,
        service_id: serviceId
      }

      const { status, data } = await fetch(url, {
        method: reqMethod,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + userData.access_token
        },
        // body: JSON.stringify({ 
        //   company_id: userData['data'].company_id, 
        //   service_id: serviceId, 
        //   amount: assetValue 
        // })
        body: JSON.stringify(info)
        // body: JSON.stringify({company_id: userData['data'].company_id, service_id: subCategory})
      }).then((res) => res.json());
      if (status) {
        setLoading(false);
        setApplicationId(data.id)
        showAlert(true);
        // currentStep((prevActiveStep) => prevActiveStep + 1);
        currentStep((prevActiveStep) => {
          if (localStorage.getItem("name") === "Equitable" || localStorage.getItem("name") === "Legal") {
            return prevActiveStep + 2
          } else if (localStorage.getItem("name") === "Registry Search") {
            return prevActiveStep + 3
          } else {
            return prevActiveStep + 1
          }
        });
      } else if (!window.navigator.online) {
        Swal.fire('Internet', 'Check your connection', 'error')
      } else {
        Swal.fire("Something went wrong", "Unable to process request", "info");
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      Swal.fire("Something went wrong", "Unable to process request", "info");
      setLoading(false);
    }
  }

  const closeAlert = (event, reason) => {
    if (reason === "clickaway") return;
    showAlert(false);
  };

  const handleValueChange = (e) => {
    let value = e.target.value;
    const parsedValue = value.replace(/[^0-9]/g, "");
    setAssetValue(parsedValue);
    let toMoney = new Number(parsedValue).toLocaleString("en-US");
    setMoneyValue(toMoney)
  };

  return (
    <div>

      <Snackbar
        open={alert}
        autoHideDuration={2000}
        onClose={closeAlert}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={closeAlert} severity="success">
          Application Created Successfully!
        </Alert>
      </Snackbar>

      <div>
        <form className={classes.form}>
          <div className={classes.textfields}>
            <TextField
              id="outlined-error-helper-text"
              select
              className={classes.select}
              variant="outlined"
              label="Select a Service"
              name="serviceId"
              value={category}
              onChange={(e) => selectCategory(e)}
              disabled={loading}
            >
              {categories.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
            {selected && <>
              <TextField
                id="outlined-error-helper-text"
                select
                className={classes.select}
                variant="outlined"
                label="Select a process"
                name="subCategory"
                value={serviceId}
                //   value={subCategory}
                disabled={loading}
                onChange={(e) => selectSubCategory(e)}
              >
                {subCategories.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
              {/* {console.log(selectedSubCat.fee_type)} */}
              {(subSelected) && (selectedSubCat.payBefore || selectedSubCat.name === "Free Zone" || selectedSubCat.name === "Industrial Park" || selectedSubCat.fee_type === "fraction" || selectedSubCat.fee_type === "stages") ?

                <TextField
                  label={selectedSubCat.processing_fee ? "Processing Fee" : (selectedSubCat.fee_type === "stages" || selectedSubCat.fee_type === "fraction") ? "Asset Value" : "Cost"}
                  variant="outlined"
                  value={(selectedSubCat.fee_type === "stages" || selectedSubCat.fee_type === "fraction") ? '$ ' + moneyValue : formatAsMoney(selectedSubCat.processing_fee ? selectedSubCat.processing_fee : selectedSubCat.actual_fee)}
                  onChange={handleValueChange}
                  disabled={(selectedSubCat.fee_type === "stages" || selectedSubCat.fee_type === "fraction") ? false : true} className={classes.select} /> : null}
              {console.log(selectedSubCat)}
              {localStorage.setItem("payBefore", selectedSubCat.payBefore)}
              {localStorage.setItem("name", selectedSubCat.name)}
              {localStorage.setItem("processing_fee", selectedSubCat.processing_fee)}
              {localStorage.setItem("actual_fee", selectedSubCat.actual_fee)}

            </>}
          </div>
          {selected && subSelected &&
            <Button
              // autoFocus
              variant="outlined"
              color="primary"
              onClick={handleSubmit}
              className={classes.button}
              size="large"
              disabled={loading}
            >
              Start Application
              </Button>}
        </form>


        {loading && (
          <div className={classes.spinner}>
            <CircularProgress />

          </div>
        )}
      </div>
    </div>
  );
}
