import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Button, Typography, Table, TableBody, TableCell, TableContainer, TableHead,
    TableRow, Paper, CircularProgress, Grid, colors,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom'
import { ReactComponent as LoginIcon } from "feather-icons/dist/icons/log-in.svg";
import { Container as ContainerBase } from "components/misc/Layouts";
import tw from "twin.macro";
import axios from "axios";
import styled from "styled-components";


const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    tableFont: {
        fontSize: '18px',
    },
    tableHeader: {
        paddingLeft: '0',
        fontSize: '18px',
        width: '100%',
        marginLeft: '10px',
    },
    footer: {
        textAlign: 'right',
        marginTop: 8,
        paddingBottom: 8
    },
    btn: {
        marginLeft: 3
    },
    pageInput: {
        border: '1px solid #48BB78',
        width: 70
    }
});

const Container = tw(
    ContainerBase
)`min-h-screen  text-white font-medium flex justify-center -mx-8`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900  sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12   rounded`;
const LogoLink = tw.a``;
const LogoImage = tw.img`h-12 mx-auto`;
const MainContent = tw.div`mt-12 flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;
const FormContainer = tw.div`w-full flex-1 mt-8`;

const SocialButtonsContainer = tw.div`flex flex-col items-center`;
const SocialButton = styled.a`
    ${tw`w-full max-w-xs font-semibold rounded-lg py-3 border text-gray-900 bg-gray-100 hocus:bg-gray-200 hocus:border-gray-400 flex items-center justify-center transition-all duration-300 focus:outline-none focus:shadow-outline text-sm mt-5 first:mt-0`}
    .iconContainer {
      ${tw`bg-white p-2 rounded-full`}
    }
    .icon {
      ${tw`w-4`}
    }
    .text {
      ${tw`ml-4`}
    }
  `;

const DividerTextContainer = tw.div`my-12 border-b text-center relative`;
const DividerText = tw.div`leading-none px-2 inline-block text-sm text-gray-600 tracking-wide font-medium bg-white transform -translate-y-1/2 absolute inset-x-0 top-1/2 bg-transparent`;

const Form = tw.form`mx-auto max-w-xs`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-green-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button`
    ${tw`mt-5  font-semibold bg-green-500 text-gray-100 w-full py-4 rounded-lg hover:bg-green-600 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
    .icon {
      ${tw`w-6 h-6 -ml-2`}
    }
    .text {
      ${tw`ml-3`}
    }
  `;
const IllustrationContainer = tw.div`sm:rounded-r-lg flex-1 bg-purple-100 text-center hidden lg:flex justify-center`;
const IllustrationImage = styled.div`
    ${(props) => `background-image: url("${props.imageSrc}");`}
    ${tw`m-12 xl:m-16 w-full max-w-sm bg-contain bg-center bg-no-repeat`}
  `;
export default function ChangePassword({
    headingText = "Change password",
    submitButtonText = "Submit",
    SubmitButtonIcon = LoginIcon,
}) {

    const [passwordConfirmation, setPasswordConfirmation] = useState("");
    const [password, setPassword] = useState("");
    const [oldPassword, setOldPassword] = useState("");
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [loading, setLoading] = useState(false);

    const history = useHistory();

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        const user = localStorage.getItem("userDetails");
        const userData = JSON.parse(user);
        axios
            .post("https://nepza.herokuapp.com/api/v1/users/update_password", {
                old_password: oldPassword,
                password_confirmation: passwordConfirmation,
                password: password,
            }, {
                headers: {
                    Authorization: `Bearer ${userData.access_token}`,
                }
            })
            .then(function (response) {
                if (response.status === 201) {
                    history.push('/login')
                } else {
                    console.log(response);
                }
            })
            .catch(function (error) {
                console.log(error.response.data.message);
                const { message } = error.response.data;
                setSnackbarMessage(message);
                setOpenSnackbar(true);
            })
            .finally(() => setLoading(false));
    };



    return (
        <>
            {/* <h6>{headingText}</h6> */}
            <FormContainer>
                <Form>

                    <Input
                        type="password"
                        value={oldPassword}
                        onChange={(e) => setOldPassword(e.target.value)}
                        placeholder="Old Password"
                    />
                    <Input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Password"
                    />
                    <Input
                        type="password"
                        value={passwordConfirmation}
                        onChange={(e) => setPasswordConfirmation(e.target.value)}
                        placeholder="Confirm password"
                    />
                    <SubmitButton type="submit" onClick={handleSubmit}>
                        <SubmitButtonIcon className="icon" />
                        <span className="text">
                            {loading ? (
                                <CircularProgress color="secondary" />
                            ) : (
                                submitButtonText
                            )}
                        </span>
                    </SubmitButton>
                </Form>

            </FormContainer>
        </>
    );
};



