import React, { useState } from "react";
import Swal from "sweetalert2";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import { CircularProgress, LinearProgress, Typography } from "@material-ui/core";
import { TableCell, TableRow, } from '@material-ui/core';
import { blue, green, purple, red } from "@material-ui/core/colors";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import {
    Chart, Series,
} from "devextreme-react/chart";
import AddTeam from "./AddTeam"
import DocumentsUpload from "./DocumentsUpload"

const useStyles = makeStyles((theme) => ({
    spinner: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    status: {
        padding: 16
    },
    approved: {
        color: '#006400'
    },
    pending: {
        color: '#ffbf00'
    },


}));
const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: green[200],
        color: theme.palette.common.black
    },
    body: {
        fontSize: 14
    }
}))(TableCell);
const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(odd)': {
            // backgroundColor: grey[50]
        }
    }
}))(TableRow);

const TotalRequestBar = withStyles((theme) => ({
    root: { height: 5, borderRadius: 6 },
    bar: { backgroundColor: blue[500] },
}))(LinearProgress);
const ProcessedRequestBar = withStyles((theme) => ({
    root: { height: 5, borderRadius: 6 },
    bar: { backgroundColor: green[500] },
}))(LinearProgress);
const InProgreeRequestBar = withStyles((theme) => ({
    root: { height: 5, borderRadius: 6 },
    bar: { backgroundColor: purple[500] },
}))(LinearProgress);
const DeclinedRequestBar = withStyles((theme) => ({
    root: { height: 5, borderRadius: 6 },
    bar: { backgroundColor: red[500] },
}))(LinearProgress);

export default () => {
    const [loading, setLoading] = useState(false);
    const [stat, setStat] = useState({});
    const user = localStorage.getItem("userDetails");
    const [charts, setCharts] = useState([]);
    const userData = JSON.parse(user);
    const classes = useStyles();
    const [value, setValue] = React.useState('team');
    // const userToken = JSON.parse(userDetails)
    const userApprovals = userData.data.company.zmc_profile

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };
    const formatMoney = (money) => {
        return `$ ${money.toLocaleString()}`;
    };
    if (loading)
        return (
            <div className={classes.spinner}>
                <CircularProgress />
            </div>
        );

    return (
        <div className={classes.root}>
            <Grid container spacing={3} className={classes.cardGrid}>
                <Grid xs={12}>
                    <Typography className={classes.status}>Approval Status: {userApprovals?.approved ? <span className={classes.approved}>Approved</span> :
                        <span className={classes.pending}>Not Approved</span>}
                    </Typography>
                </Grid>
                {userData.data.company.zmc_profile.approved === "true" && stat.cards
                    ? stat.cards.map((item, index) => (
                        <Grid key={`item${index}`} item xs={12} sm={6} md={3}>
                            <Paper className={classes.paper}>
                                <div className={classes.cards}>
                                    <Typography variant="h5" style={{ color: blue[500] }}>
                                        {item.isMoney ? formatMoney(item.data) : item.data}
                                    </Typography>
                                    <CheckCircleOutlinedIcon style={{ color: blue[500] }} />
                                </div>
                                <div>
                                    <Typography>{item.title}</Typography>
                                </div>
                            </Paper>
                        </Grid>
                    ))
                    : <div className={classes.tabs}>
                        <TabContext value={value}>
                            <AppBar position="static">
                                <TabList onChange={handleTabChange} aria-label="simple tabs example">
                                    <Tab label="Add Team members" value="team" />
                                    <Tab label="Upload Required documents" value="files" />
                                </TabList>
                            </AppBar>
                            <AddTeam />
                            <DocumentsUpload />
                        </TabContext>
                    </div>}
            </Grid>
            {charts.length
                ? charts.map((item, index) => (
                    <Grid item xs={12} sm={12}>
                        <Paper className={classes.paper}>
                            <Chart id="chart" dataSource={item.data}>
                                <Series
                                    valueField="occurrence"
                                    argumentField="name"
                                    name={item.title}
                                    type="bar"
                                    color="#ffaa66"
                                />
                            </Chart>
                        </Paper>
                    </Grid>
                ))
                : null}
        </div>
    );
};
