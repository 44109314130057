import { Button, CircularProgress, Paper } from "@material-ui/core";
import { AppConfig } from "app-config";
import React, { useState } from "react";
import Swal from "sweetalert2";
import { TableContainer, Table, TableBody, TableCell, TableHead, TableRow, capitalize, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { blue, grey } from "@material-ui/core/colors";
import moment from 'moment';



const useStyles = makeStyles({
    table: {
        // minWidth: 650,
    },
    head: {
        backgroundColor: grey[200]
    },
    spinner: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
});

export default function Renewals() {
    const [loading, setLoading] = useState(false);
    const [renewals, setRenewals] = useState([]);
    const classes = useStyles();

    const user = localStorage.getItem('userDetails');

    const userData = JSON.parse(user)

    const getRenewals = async () => {
        setLoading(true)
        try {
            const response = await fetch(`${AppConfig.URL}/company/upcoming_renewals`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${userData.access_token}`
                }
            });
            const { status, data, message } = await response.json();
            if (status === true) {
                setRenewals(data);
            } else {
                Swal.fire('', message, 'info');
            }
            setLoading(false)
        } catch (error) {
            Swal.fire('Something went wrong', 'Unable to load upcoming renewals', 'error');
            setLoading(false)
        }
    }


    React.useEffect(() => {
        getRenewals()
    }, []);

    if (!loading && !renewals) {
        return (
          <div className={classes.norec}>
            <Typography component="h1" variant="h3">
              No record found
            </Typography>
    
            <Button
              className={classes.button}
              color="primary"
              onClick={getRenewals}
              variant="contained">
              Go Back
            </Button>
          </div>
        );
      }


    if (loading) return <div className={classes.spinner}><CircularProgress /></div>

    return (
        <>
        <Typography>List of Renewals</Typography>
        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="caption table">
                <TableHead className={classes.head}>
                    <TableRow>
                    <TableCell>S/N</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell  align="center">Due date</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {renewals && renewals.map((renewal, index) => (
                        <TableRow>
                        <TableCell>{index + 1}</TableCell>
                            <TableCell>{renewal.topic}</TableCell>
                            <TableCell  align="center">{moment(renewal.due_date).format('DD MMM YYYY | hh:mm')}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
        </>
    )
}