import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Tabs, Tab, Divider, colors, Paper } from '@material-ui/core';
import PromoterZonesList from './PromoterZonesList';


const useStyles = makeStyles(theme => ({
    root: {
        padding: '3rem'
    },
    tabs: {
        marginTop: '3rem'
    },
    divider: {
        backgroundColor: colors.grey[300]
    },
    content: {
        margin: '3rem'
    },
}));

export default function PromoterZones({ history, match }) {
    const classes = useStyles();
    // const matches = useMediaQuery('(min-width:768px)');
    //   const { tab } = match.params;

    const [tab, setTab] = useState('all');
    const handleTabsChange = (event, value) => {
        // history.push(value);
        setTab(value);
    };



    const tabs = [
        { value: 'all', label: 'All' },
        { value: 'pending', label: 'Pending' },
        { value: 'approved', label: 'Approved' },
    ];

    if (!tab) {
        return <Redirect to={'/me/zones'} />;
    }



    return (
        <Paper>
            <Tabs
                className={classes.tabs}
                onChange={handleTabsChange}
                scrollButtons="auto"
                value={tab}
                variant="scrollable">
                {tabs.map(tab => (
                    <Tab key={tab.value} label={tab.label} value={tab.value} />
                ))}
            </Tabs>
            <Divider className={classes.divider} />
            <div className={classes.content}>
                {tab === 'all' && <PromoterZonesList type={'all'} />}
                {tab === 'pending' && <PromoterZonesList type={'pending'} />}
                {tab === 'approved' && <PromoterZonesList type={'approved'} />}

            </div>
        </Paper>
    );
};




