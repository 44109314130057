import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";

import defaultCardImage from "images/shield-icon.svg";

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomizeIconImage from "images/customize-icon.svg";
import FastIconImage from "images/fast-icon.svg";
import ReliableIconImage from "images/reliable-icon.svg";
import SimpleIconImage from "images/simple-icon.svg";

const Container = tw.div`relative bg-white -mx-8 px-8 text-gray-800`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`;
const Subheading = tw(SubheadingBase)`mb-4 text-gray-800`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center text-gray-800`;

const VerticalSpacer = tw.div`mt-10 w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-xs`}
`;

const Card = styled.div`
  ${tw`flex flex-col items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw`bg-gray-100 text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-6 h-6`}
    }
  }

  .textContainer {
    ${tw`mt-6`}
  }

  .title {
    ${tw`tracking-wider font-bold text-xl leading-none`}
  }

  .description {
    ${tw`mt-2  font-normal text-gray-800 leading-snug`}
  }

  .action {
    ${tw`mt-2 cursor-pointer  font-normal text-gray-800 leading-snug`}
  }
`;

export default ({
  toggleMoreServices,
  cards = null,
  heading = "Our Services",
  subheading = "",
  description = "The OPMS is a web-based solution designed to provide NEPZA’s partners with easy access to the following services:",
}) => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component) or you can directly pass this using the cards prop:
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */


  const defaultCards = [
    {
      imageSrc: ShieldIconImage,
      title: "Zone Declaration",
      description: "The process of approving a location as a FreeZone.",
    },
    {
      imageSrc: SupportIconImage,
      title: "Enterprise Registeration",
      description:
        "Steps taken to register a company as a Free Zone entity by the Zone management.",
    },
    {
      imageSrc: FastIconImage,
      title: "Change of  Directors",
      description:
        "The prerequisite steps and processes for companies to change directors.",
    },
    {
      imageSrc: CustomizeIconImage,
      title: "OPL Renewal",
      description:
        "An annual action compulsory on all Free Zones Management and enterprises operating within them to renew their operating license.",
    },
    {
      imageSrc: ReliableIconImage,
      title: "Filling of Annual Returns",
      description:
        "Submission of the company’s end of financial year report that shows Free Zone Enterprises composition, operations and financial report through their Zone Management.",
    },
    {
      imageSrc: SimpleIconImage,
      title: "Registry Search",
      description:
        "The process of requesting information about an Enterprise with a specific Zone mostly by the Enterprise business partners to conduct due diligence on.",
    },
    {
      imageSrc: SimpleIconImage,
      title: "Change of Drirector's Address",
      description: "The process involved in changing your director's address",
    },
    { imageSrc: SimpleIconImage, title: "Other Services", action: "Read more" },
  ];

  if (!cards) cards = defaultCards;

  return (
    <Container id="services">
      <ThreeColumnContainer>
        {subheading && <Subheading>{subheading}</Subheading>}
        <Heading>{heading}</Heading>
        {/* {description && <Description>{description}</Description>} */}
        <VerticalSpacer />
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">{card.description || ""}</p>
                <p className="action" onClick={toggleMoreServices}>{card?.action || ""}</p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
    </Container>
  );
};
