import React, { useState, useEffect } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import axiosInstance from '../Api/Api.js'
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import { green } from '@material-ui/core/colors';
import { useHistory } from 'react-router-dom'
import { TramRounded } from '@material-ui/icons';


const useStyles = makeStyles((theme) => ({
   button: {
      margin: '2rem',
      backgroundColor: '#68d391',
      color: 'white',
      fontSize: '16px',
      width: '12rem',
      padding: '1rem',
      borderRadius: '8px'
   },
   dummyButton: {
      backgroundColor: "green",
      color: "white",
      "&:hover": {
         backgroundColor: "green"
      }
   },
   buttonProgress: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
   },
   initiatePayment: {
      display: "flex",
      flexDirection: "column",
      alignItems: 'center',
      margin: '30px'
   },
   formContainer: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "8rem 0",
      flexDirection: "column",
   },
}))

function Alert(props) {
   return <MuiAlert elevation={6} variant="filled" {...props} />;
}
export default function InitiatePayment({ user, paymentData, applicationId }) {
   const [dataTomakePayment, setDataToMakePayment] = useState('');
   const [loading, setLoading] = useState(false);
   const [openSnackbar, setOpenSnackbar] = useState(false)
   const [snackbarMessage, setSnackbarMessage] = useState('')
   const [dataForPayment, setDataForPayment] = useState('');
   const [applicationDetails, setApplicationDetails] = useState('')

   const history = useHistory()

   const handleClose = () => {
      setOpenSnackbar(false)
   }


   useEffect(() => {
      fetchApplicationDetails()
   }, [])

   const fetchApplicationDetails = () => {
      setLoading(true);
      let application = paymentData?.data?.application_id || applicationId;
      axiosInstance
         .get(`/application/get/${application}`)
         .then(response => {
            if (response.status === 200) {
               setApplicationDetails(response.data.data.application);
               let processingFee = response.data.data.application.processing_fee
               let isProcessingFeePaid = response.data.data.application.isProcessingFeePaid
               if (processingFee && !isProcessingFeePaid) {
                  localStorage.setItem('payProcessingFee', 'true')
               } else {
                  localStorage.setItem('payProcessingFee', 'false')
               }
               console.log(response.data.data.application);
            } else {
               console.log(response);
               setOpenSnackbar(true);
               setSnackbarMessage('Unable to load application details');
            }
         })
         .catch(error => {
            console.log(error)
            setOpenSnackbar(true);
            setSnackbarMessage('Error fetching application details');
         })
         .finally(() => setLoading(false));
   };

   const getDataToMakePayment = () => {
      setLoading(true);
      const requestId = paymentData?.data?.application_id || applicationId;
      // let link
      // (localStorage.getItem("name") === "Free Zone" || localStorage.getItem("name") === "Industrial Park") ?
      //    link = `/application/generate_rrr/${requestId}?type=processing_fee` :
      //    link = `/application/generate_rrr/${requestId}?type=regular`
      let link
      (localStorage.getItem("payProcessingFee") === "true") ?
         link = `/application/generate_rrr/${requestId}?type=processing_fee` :
         link = `/application/generate_rrr/${requestId}?type=regular`

      axiosInstance
         .get(link)

         .then(function (response) {

            if (response.status === 200) {
               console.log(response)
               setDataForPayment(response?.data?.data)

            } else {
               console.log(response);
            }
         })
         .catch(function (error) {
            console.log(error.response)
            setOpenSnackbar(true);
            setSnackbarMessage(error?.response?.data?.message);
            console.log(error?.response?.data?.message);
         })
         .finally(() => setLoading(false));

   }
   const classes = useStyles();
   const responseUrl = "http://localhost:3000/finalize";

   if (!dataForPayment) {
      return (<div className={classes.initiatePayment}>
         <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={openSnackbar}
            onClose={handleClose}
            autoHideDuration={6000}
            key="topcenter"
            severity="success"
         >
            <Alert onClose={handleClose} severity="error">
               {snackbarMessage}
            </Alert>
         </Snackbar>
         <Button variant="contained" disabled={loading} color="primary" onClick={getDataToMakePayment}>
            {loading ? <CircularProgress size={24} className={classes.buttonProgress} /> : ""}
     Proceed to payment
   </Button>
      </div>)
   }



   return (
      <div className={classes.initiatePayment}>
         <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={openSnackbar}
            onClose={handleClose}
            autoHideDuration={6000}
            key="topcenter"
            severity="success"
         >

            <Alert onClose={handleClose} severity="error">
               {snackbarMessage}
            </Alert>
         </Snackbar>
         {loading ? <CircularProgress /> :

            (<div><Typography gutterBottom component="h5">Click the button below to continue to Remiita for payment</Typography>
               <form enctype="multipart/form-data" method="post" name="SubmitRemitaForm" action={`${dataForPayment?.action}/finalize.reg`} className={classes.formContainer}>
                  <input type="hidden" value={`${dataForPayment?.merchantId}`} name="merchantId" />
                  <input type="hidden" value={`${dataForPayment?.hash}`} name="hash" />
                  <input type="hidden" value={`${dataForPayment?.rrr}`} name="rrr" />
                  {/* <input type="hidden" value="https://nepzaopms.com/finalize" name="responseurl" /> */}
                  <input type="hidden" value={`${window.location.origin}/finalize`} name="responseurl" />
                  <input type="submit" className={classes.button} value="Pay Now" name="submit_btn" />
                  <div style={{}}>{`NOTE: Payment can also be made from the Bank with RRR Code ${dataForPayment?.rrr} if you do not intend to make payment via Debit/Credit Card.`}</div>
               </form></div>)}
         {/* <Button className={classes.dummyButton} onClick={() => history.replace("/mock-remitta-payment")}>Pay Now</Button> */}
      </div>
   )
}