import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASEURL,
  headers: {
    'Content-Type': 'application/json',
  },
})

axiosInstance.interceptors.request.use(function (config) {
  const userDetails = localStorage.getItem('userDetails');
  const user = JSON.parse(userDetails)

  config.headers.Authorization = user ? `Bearer ${user.access_token}` : null;
  return config;
});

export default axiosInstance;