
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import InfoIcon from "@material-ui/icons/Info";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import Swal from "sweetalert2";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { deepPurple, deepOrange } from "@material-ui/core/colors";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { AppConfig } from "app-config";
import { useHistory } from 'react-router-dom';
import EditTeam from "./ZmcDashboard/EditTeam"
import ViewTeam from "./ZmcDashboard/ViewTeam"

const useStyles = makeStyles((theme) => ({
    container: {
        margin: "2rem auto"
    },
    root: {
        width: "80%",
        maxWidth: "80vw",
        backgroundColor: theme.palette.background.paper,
        margin: "1rem auto 0"
    },
    spinner: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%"
    },
    mobileRoot: {
        width: "100%",
        maxWidth: "91vw",
        backgroundColor: theme.palette.background.paper,
        margin: "3rem auto 0"
    },
    inline: {
        display: "inline-block",
        marginTop: "2rem",
        paddingRight: "2rem",
        wordBreak: "break-word"
    },
    large: {
        width: theme.spacing(9),
        height: theme.spacing(9),
        marginRight: "1rem"
    },
    createBtn: {
        display: "flex",
        justifyContent: "flex-end",
        padding: "0 5rem",
        marginBottom: '2rem'
    },
    createBtnMobile: {
        display: "flex",
        justifyContent: "flex-end",
        padding: "0 1.5rem"
    },
    Thead: {
        fontWeight: 600,
        fontSize: "20px"
    }
}));

const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
};

export default function Teams() {
    const matches = useMediaQuery("(min-width:768px)");
    const classes = useStyles();
    const [loading, setLoading] = React.useState(true);
    const [modalType, setModalType] = React.useState("");

    const [alert, showAlert] = React.useState(false);
    const [team, setTeam] = React.useState({});
    const [teams, setTeams] = React.useState([]);
    const [createModal, showCreateModal] = React.useState(false);
    const [viewModal, showViewModal] = React.useState(false);
    const userDetails = localStorage.getItem('userDetails');
    const userData = JSON.parse(userDetails)
    const userPermission = localStorage.getItem("userPermission")
    const history = useHistory();

    const handleCreateModalClose = () => {
        showCreateModal(false);
    };
    const handleViewModalClose = () => {
        showViewModal(false);
    };

    const handleUpdateModalOpen = (selectedTeam) => {
        setModalType("edit");
        setTeam(selectedTeam);
        showCreateModal(true);
    };
    const handleViewModalOpen = (selectedTeam) => {
        setModalType("view");
        setTeam(selectedTeam);
        showViewModal(true);
    };

    React.useEffect(() => {
        getTeams();
    }, []);

    const getTeams = async () => {
        setLoading(true);

        try {
            const url = AppConfig.URL +
                "/zmc/team/list";
            const response = await fetch(url, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${userData.access_token}`
                }
            });
            const { data, message, status } = await response.json();
            if (status) {
                setTeams(data);
                setLoading(false);
            } else {
                setTeams([]);
                setLoading(false);
                Swal.fire('', message, 'info');
            }
        } catch (error) {
            setLoading(false);
            Swal.fire('Something went wrong', 'Unable to get users', 'error');
        }
    };

    const closeAlert = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        showAlert(false);
    };
    if (loading) {
        return (
            <div className={classes.spinner}>
                <CircularProgress />
            </div>
        );
    }

    return (
        <div className={classes.container}>
            <Snackbar
                open={alert}
                autoHideDuration={2000}
                onClose={closeAlert}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            >
            </Snackbar>
            <EditTeam
                handleClose={handleCreateModalClose}
                open={createModal}
                data={team}
                type={modalType}
                getTeams={getTeams}
                userData={team}
            />
            <ViewTeam
                handleClose={handleViewModalClose}
                open={viewModal}
                data={team}
                type={modalType}
                userData={team}
            />



            <div className={matches ? classes.createBtn : classes.createBtnMobile}>
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => history.push('/me/team/create')} >
                    Create Director
                    </Button>
            </div>

            {/* {
                userPermission === "company-admin" ? */}
            <Paper className="container">
                <Table>
                    <TableHead className={classes.Thead}>
                        <TableRow>
                            <TableCell style={{ fontWeight: "bold", fontSize: "18px" }}>S/N</TableCell>
                            <TableCell style={{ fontWeight: "bold", fontSize: "18px" }}>First Name</TableCell>
                            <TableCell style={{ fontWeight: "bold", fontSize: "18px" }}>Surname</TableCell>
                            <TableCell style={{ fontWeight: "bold", fontSize: "18px" }}>Nationality</TableCell>
                            <TableCell style={{ fontWeight: "bold", fontSize: "18px" }}>Year Appointed</TableCell>
                            <TableCell style={{ fontWeight: "bold", fontSize: "18px" }}>DOB</TableCell>
                            <TableCell style={{ fontWeight: "bold", fontSize: "18px" }}>passport</TableCell>
                            <TableCell style={{ fontWeight: "bold", fontSize: "18px" }}>[Action]</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {teams.map((team, index) => (
                            <TableRow key={team.id}>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>{team.firstname}</TableCell>
                                <TableCell>{team.surname}</TableCell>
                                <TableCell>{team.nationality}</TableCell>
                                <TableCell>{team.year_appointed}</TableCell>
                                <TableCell>{team.dob}</TableCell>
                                {team.passport_url ? (<TableCell><img src={team.passport_url} width="100px" /></TableCell>) : (<TableCell>No passport provided</TableCell>)
                                }
                                <TableCell>

                                    <IconButton
                                        style={{ color: "#48BB78" }}
                                        edge="end"
                                        aria-label="view"
                                        onClick={() => handleViewModalOpen(team)}
                                    >
                                        <InfoIcon />
                                    </IconButton>
                                    <IconButton
                                        style={{ color: "#3085d6" }}
                                        edge="end"
                                        aria-label="edit"
                                        onClick={() => handleUpdateModalOpen(team)}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                    {matches ? "\u00A0\u00A0\u00A0\u00A0\u00A0" : "\u00A0"}

                                    {matches ? "\u00A0\u00A0\u00A0\u00A0\u00A0" : "\u00A0"}

                                </TableCell>

                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Paper>
            {/* : null} */}
        </div>
    );
}



