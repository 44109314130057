import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import List from '@material-ui/core/List';
import Button from '@material-ui/core/Button';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MailIcon from '@material-ui/icons/Mail';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { Switch, Route, Link, NavLink, useHistory } from "react-router-dom";
import SignupPage from "pages/Signup.js";
import Homepage from 'pages/Homepage'
import routes, { zmcItems, partnerItems } from 'routes';
import logo from "images/Nepzaimage.png";
import { PowerSettingsNew } from '@material-ui/icons';
import CompanyRequestDetials from '../CompanyRequest/CompanyRequestDetails'
import ZmcActionSubmitted from './ZmcActionSubmitted'
// import AdminViewDetails from '../CompanyRequest/AdminViewDetails'




const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    boxShadow: `0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
  `,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    // backgroundColor: theme.palette.action.hover
  },
  title: {
    flexGrow: 1,
  },
  menuLink: {
    position: "relative",
    display: "block",
  },
  menuItem: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: theme.spacing(1) * 1.5,
    paddingBottom: theme.spacing(1) * 1.5,
  },
  menuIcon: {
    marginLeft: theme.spacing(1) * 2,
    marginRight: theme.spacing(1) * 2,
  },
  menuActive: {
    backgroundColor: theme.palette.action.hover,
  },
  logo: {
    maxWidth: "80%",
  },
}));

function ResponsiveDrawer(props) {
  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const history = useHistory();
  const [userRoutes, setUserRoutes] = useState([])

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const getUser = () => {
    const userDetails = localStorage.getItem("userDetails");
    if (userDetails) {
      return JSON.parse(userDetails);
    }
  };

  const getUserDetails = () => {
    return JSON.parse(localStorage.getItem('userDetails'));
  }


  const handleLogout = () => {
    localStorage.clear();
    history.push("/");
  };

  const drawer = () => (
    <div>
      {/* <div className={classes.toolbar} /> */}
      <img src={logo} className={classes.logo} alt="logo" />
      <Divider />
      {((getUserDetails()?.data?.company?.entity_type_id == 3) ? zmcItems : partnerItems).map((route, index) => route.hidden ? '' : (
        <NavLink
          to={route.path}
          exact
          className={classes.menuLink}
          activeClassName={classes.menuActive}
          key={index}
        >
          <ListItem button key={index} className={classes.menuItem}>
            <ListItemIcon>
              {<route.icon className={classes.menuIcon} />}
            </ListItemIcon>
            <ListItemText primary={route.title} />
          </ListItem>
        </NavLink>

      ))}
      <Divider />
      <List>
        <ListItem button onClick={handleLogout}>
          <ListItemIcon>
            <PowerSettingsNew />
          </ListItemIcon>
          <ListItemText primary={"Logout"} />
        </ListItem>
      </List>
    </div>
  );


  const container =
    window !== undefined ? () => window().document.body : undefined;




  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap className={classes.title}>
            {getUser()?.data?.company?.name}
          </Typography>
          <Typography variant="h6">{getUser()?.data?.fullname}</Typography>
          <IconButton
            edge="end"
            aria-label="account of current user"

            aria-haspopup="true"
            // onClick={handleMenu}
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">

        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            {drawer()}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer()}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />

        <Switch>
          {routes.items.map((route, index) => (
            <Route exact path={route.path} key={index}>
              {<route.component />}
            </Route>
          ))}
          <Route path="/me/company-request-details/:rowId">
            <CompanyRequestDetials />
          </Route>
          <Route path="/me/zmc-action-submitted">
            <ZmcActionSubmitted />
          </Route>
          {/* <Route path="/me/history-request-details/:rowId">
            <AdminViewDetails />
          </Route> */}
          {/* <Route path="/admin-account/history-request-details/:rowId">
            <AdminViewDetails />
          </Route> */}
        </Switch>
      </main>
    </div>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default ResponsiveDrawer;
