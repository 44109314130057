import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MailIcon from "@material-ui/icons/Mail";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Switch, Route, Link } from "react-router-dom";
import SignupPage from "pages/Signup.js";
import Homepage from "pages/Homepage";
import DataTable from "pages/Account/DataTable";
import AdminRequestAction from "pages/Account/AdminRequestAction";
import AdminRequestActionHistory from "pages/Account/AdminRequestActionHistory"
import ActionSubmitted from "pages/Account/ActionSubmitted";
import { useHistory } from "react-router-dom";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MoreIcon from "@material-ui/icons/MoreVert";
import NotificationsIcon from "@material-ui/icons/Notifications";
import Badge from "@material-ui/core/Badge";
import logo from "images/Nepzaimage.png";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import ViewListIcon from "@material-ui/icons/ViewList";
import SettingsMenu from "./SettingsMenu";
import Zones from "pages/Account/Zones";
import Services from "pages/Account/Services";
import ServiceTypes from "pages/Account/ServiceTypes";
import EditCreateServicesTypes from "pages/Account/EditCreateServicesTypes";
import Document from "pages/Account/Document";
import EditCreateDocument from "pages/Account/EditCreateDocument";
import EditCreateCategory from "pages/Account/EditCreateCategory";
import ExchangeRate from "pages/Account/ExchangeRate";
import AdminHistory from "pages/Account/History/AdminHistory";
import CreateUser from "pages/Account/UserManagement/CreateUser";


import EditCreateZones from "pages/Account/EditCreate";
import EditCreateServices from "pages/Account/EditCreateService";
import NepzaAdminDashboard from "pages/Account/NepzaAdminDashboard";
import AdminUsers from "pages/Account/UserManagement/AdminUsers";
import EditAdminUser from "pages/Account/UserManagement/EditAdminUser";
import ViewRoles from "pages/Account/RoleManagement/ViewRoles";
import CreateRole from "pages/Account/RoleManagement/CreateRole";
import EditRole from "pages/Account/RoleManagement/EditRole";
import AllCategories from "pages/Account/Categories/AllCategories";
import CategoryServices from "pages/Account/Categories/CategoryServices";
import ProcessModules from "pages/Account/ProcessModules/ViewProcessModules";
import CreateProcessModule from "pages/Account/ProcessModules/CreateProcessModule";
import EditProcessModule from "pages/Account/ProcessModules/EditProcessModule";
import ZmcApplications from 'pages/ZMCApplications/ZMCApplications'
import ZMCApplicationDetails from 'pages/ZMCApplications/ZMCApplicationDetails';
import PromoterDetails from 'pages/PromoterDetails'



import axios from "axios";
import axiosInstance from "../../Api/Api.js";
import ProcessFlow from "./ProcessFlow";
import EditCreateProcessFlow from "./UpdateProcessFlow";
import FinalApproval from '../../Approvals/FinalApproval';
import AdminViewDetails from '../CompanyRequest/AdminViewDetails'
import AdminZones from "pages/Account/AdminZones"
import AdminPromoters from "pages/Account/AdminPromoters"
import AdminEnterprises from "pages/Account/AdminEnterprises"
import ChangePassword from "pages/Account/ChangePassword"
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';



const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    boxShadow: `0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
  `,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  title: {
    flexGrow: 1,
  },
  logo: {
    maxHeight: "100px",
    width: "100%",
  },
  sidelink: {
    fontWeight: "bold",
  },
  listMenu: {
    overflow: "auto",
  },
}));

function ResponsiveDrawer(props) {
  const history = useHistory();
  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [state, setState] = useState("");
  const [allRequests, setAllRequests] = useState([]);

  const [loading, setLoading] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const getAdmin = () => {
    const userDetails = localStorage.getItem("userDetails");
    if (userDetails) {
      return JSON.parse(userDetails);
    }
  };

  const handleLogout = () => {
    localStorage.clear();
    history.push("/");
    handleClose()

  };
  const handleChangePassword = () => {
    history.push("/admin-account/change-password");
    handleClose()
  };

  const drawer = (
    <div>
      {/* <div className={classes.toolbar} /> */}
      <img src={logo} className={classes.logo} alt="logo" />
      <Divider />
      <List className={classes.listMenu}>
        <SettingsMenu />
      </List>
      <Divider />
      <List>
        <ListItem button onClick={handleLogout}>
          <ListItemIcon>
            <PowerSettingsNewIcon />
          </ListItemIcon>
          <ListItemText primary={"Logout"} />
        </ListItem>
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" elevation={2} className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap className={classes.title}>
            DASHBOARD
          </Typography>
          <Typography variant="h6">{getAdmin()?.data?.fullname}</Typography>
          <IconButton
            aria-label="account of current user"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            onClick={handleClick}
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >

            <MenuItem onClick={handleChangePassword}>Change Password</MenuItem>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>
          <IconButton aria-label="new notifications" color="inherit">
            <Badge badgeContent={0} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <IconButton
            aria-label="display more actions"
            edge="end"
            color="inherit"
          >
            <MoreIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />

        <Switch>
          <Route path="/admin-account/history-request-details/:rowId">
            <AdminViewDetails />
          </Route>
          <Route path="/admin-account/process-flow/:rowId">
            <EditCreateProcessFlow />
          </Route>
          <Route path="/admin-account/request-action/:rowId">
            <AdminRequestAction />
          </Route>
          <Route path="/admin-account/action-submitted">
            <ActionSubmitted />
          </Route>
          <Route path="/admin-account/zmc-applications">
            <ZmcApplications />
          </Route>
          <Route path="/admin-account/admin-zones">
            <AdminZones />
          </Route>
          <Route path="/admin-account/admin-promoters">
            <AdminPromoters />
          </Route>
          <Route path="/admin-account/admin-enterprises">
            <AdminEnterprises />
          </Route>
          <Route path="/admin-account/zmc-request-detail/:rowId">
            <ZMCApplicationDetails />
          </Route>
          <Route path="/admin-account/promoter-detail/:rowId">
            <PromoterDetails />
          </Route>
          <Route path="/admin-account/final-approval">
            <FinalApproval />
          </Route>
          <Route path="/admin-account/nepza-admin">
            <NepzaAdminDashboard />
          </Route>
          <Route path="/admin-account/change-password">
            <ChangePassword />
          </Route>
          <Route path="/admin-account/zones/edit-create/:rowId">
            <EditCreateZones />
          </Route>
          <Route path="/admin-account/services/edit-create/:rowId">
            <EditCreateServices />
          </Route>
          <Route path="/admin-account/services">
            <Services />
          </Route>
          <Route path="/admin-account/service_types">
            <ServiceTypes />
          </Route>
          <Route path="/admin-account/service-type/edit-create/:rowId">
            <EditCreateServicesTypes />
          </Route>
          <Route path="/admin-account/document/edit-create/:rowId">
            <EditCreateDocument />
          </Route>
          <Route path="/admin-account/document">
            <Document />
          </Route>
          <Route path="/admin-account/exchange-rate">
            <ExchangeRate />
          </Route>
          <Route path="/admin-account/view-users">
            <AdminUsers />
          </Route>
          <Route path="/admin-account/view-categories">
            <AllCategories />
          </Route>
          <Route path="/admin-account/category/edit-create/:rowId">
            <EditCreateCategory />
          </Route>
          <Route path="/admin-account/category-services">
            <CategoryServices />
          </Route>
          <Route path="/admin-account/history">
            <AdminHistory />
          </Route>
          <Route path="/admin-account/zones">
            <Zones />
          </Route>
          <Route path="/admin-account/create-user">
            <CreateUser />
          </Route>
          <Route path="/admin-account/view-roles">
            <ViewRoles />
          </Route>
          <Route path="/admin-account/create-role">
            <CreateRole />
          </Route>
          <Route path="/admin-account/view-process-modules">
            <ProcessModules />
          </Route>
          <Route path="/admin-account/create-process-module">
            <CreateProcessModule />
          </Route>
          <Route path="/admin-account/edit-process-module/:rowId">
            <EditProcessModule />
          </Route>
          <Route path="/admin-account/edit-admin/:rowId">
            <EditAdminUser />
          </Route>
          <Route path="/admin-account/edit-role/:rowId">
            <EditRole />
          </Route>
          <Route path="/admin-account/process-flow">
            <ProcessFlow />
          </Route>
          <Route path="/admin-account">
            <DataTable />
          </Route>
        </Switch>
      </main>
    </div>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default ResponsiveDrawer;
