import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useParams } from "react-router-dom";

import axiosInstance from "../../Api/Api.js";

function Alert(props) {
  return (
    <MuiAlert
      elevation={6}
      vertical="top"
      color="primary"
      variant="filled"
      {...props}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "50ch",
    },
  },
}));

export default function EditCreate(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    zone_name: "",
    short_name: "",
    address: "",
    company_id: "",
    zone_type_id: "",
  });

  const [open, setOpen] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  const [erorrMessage, setErrorMessage] = useState("");
  const [companies, setCompanies] = useState([]);
  const [zoneTypes, setZoneTypes] = useState([]);
  const [zoneDetails, setZoneDetails] = useState([]);

  const params = useParams();

  const handleChange = (prop) => (event) => {
    event.preventDefault();
    setValues({ ...values, [prop]: event.target.value });
  };

  const fetchCompanies = () => {
    setLoading(true);

    axiosInstance
      .get("/company/list")
      .then(function (response) {
        if (response.status === 200) {
          setCompanies(response.data.data.data);
          console.log(response.data.data.data);
          console.log(props.match.params);
        } else {
          console.log(response);
        }
      })
      .catch(function (error) {
        // console.log(error.response.data.message);
      })
      .finally(() => setLoading(false));
  };

  const fetchZoneTypes = () => {
    setLoading(true);

    axiosInstance
      .get("/zone_types/list")
      .then(function (response) {
        if (response.status === 200) {
          setZoneTypes(response.data.data.data);
          console.log(response.data.data.data);
          console.log(props.match.params);
        } else {
          console.log(response);
        }
      })
      .catch(function (error) {
        // console.log(error.response.data.message);
      })
      .finally(() => setLoading(false));
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const fetchZoneDetails = () => {
    setLoading(true);
    axiosInstance
      .get(`/zones/get/${params.rowId}`)
      .then(function (response) {
        if (response.status === 200) {
          console.log(response.data.data);
          const {
            zone_name,
            short_name,
            address,
            company_id,
            zone_type_id,
          } = response.data.data;

          setValues({
            ...values,
            zone_name,
            short_name,
            address,
            company_id,
            zone_type_id,
          });
          console.log(response.data.data);
        } else {
          console.log(response);
        }
      })
      .catch(function (error) {
        // console.log(error.response.data.message);
      })
      .finally(() => setLoading(false));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitLoading(true);
    const { zone_name, short_name, address, company_id, zone_type_id } = values;
    const endpoint = params.rowId === "create" ? "/zones/add" : "/zones/update";
    const body =
      params.rowId === "create"
        ? {
            zone_name,
            short_name,
            address,
            company_id,
            zone_type_id,
          }
        : {
            address,
            company_id,
            zone_id: zone_type_id,
          };
    const method = params.rowId === "create" ? "post" : "put";
    axiosInstance[method](endpoint, body)
      .then(function (response) {
        if (response.status === 200) {
          console.log(response);
          setErrorMessage(response.data.message);
          setOpen(true);
        } else {
          console.log(response);
        }
      })
      .catch(function (error) {
        console.log(error.response.data.message);
        const { message } = error.response.data;
        setErrorMessage(message);
        setOpen(true);
      })
      .finally(() => setSubmitLoading(false));
  };

  useEffect(() => {
    console.log(params.rowId);
    async function fetchAllData() {
      try {
        await fetchZoneTypes();
        await fetchCompanies();
      } catch (error) {
        console.log(error);
      }
    }
    fetchAllData();
  }, []);

  useEffect(() => {
    if (params.rowId !== "create" && zoneTypes.length && companies.length) {
      fetchZoneDetails();
    }
  }, [zoneTypes, companies]);

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <div style={{ fontWeight: "bold" }}>
        {params.rowId === "create" ? "Create New Zone" : "Edit Zone"}
      </div>
      <TextField
        type="text"
        InputLabelProps={{
          shrink: true,
        }}
        label="Zone Name"
        value={values.zone_name}
        onChange={handleChange("zone_name")}
      />
      <TextField
        InputLabelProps={{
          shrink: true,
        }}
        label="Short Name"
        value={values.short_name}
        onChange={handleChange("short_name")}
      />

      <TextField
        select
        InputLabelProps={{
          shrink: true,
        }}
        label="Company"
        value={values.company_id}
        onChange={handleChange("company_id")}
      >
        {companies.map((option, index) => (
          <MenuItem key={option.id} value={option.id}>
            {option.name}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        select
        InputLabelProps={{
          shrink: true,
        }}
        label="Zone Type"
        value={values.zone_type_id}
        onChange={handleChange("zone_type_id")}
      >
        {" "}
        {zoneTypes.map((option) => (
          <MenuItem key={option.zone_type_id} value={option.zone_type_id}>
            {option.zone_type}
          </MenuItem>
        ))}
      </TextField>

      <TextField
        multiline
        InputLabelProps={{
          shrink: true,
        }}
        label="Address"
        value={values.address}
        onChange={handleChange("address")}
      />

      <div>
        <Button
          variant="contained"
          onClick={handleSubmit}
          color="primary"
          disabled={
            !(
              values.zone_name &&
              values.short_name &&
              values.address &&
              values.company_id &&
              values.zone_type_id
            )
          }
        >
          {submitLoading ? (
            <CircularProgress color="secondary" />
          ) : params.rowId === "create" ? (
            "Create Zone"
          ) : (
            "Save"
          )}
        </Button>
      </div>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          {erorrMessage}
        </Alert>
      </Snackbar>
    </form>
  );
}
